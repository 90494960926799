/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UserSettingsAPIUpdate } from '../models/UserSettingsAPIUpdate';
import type { UserSettingsAPIUpdateV2 } from '../models/UserSettingsAPIUpdateV2';
import type { UserSettingsWithNotificationSettings } from '../models/UserSettingsWithNotificationSettings';
import type { UserSettingsWithNotificationSettingsV2 } from '../models/UserSettingsWithNotificationSettingsV2';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserSettingsService {

    /**
     * Read User Settings
     * Return the User Settings for the officer with id officer_id.
     * @returns UserSettingsWithNotificationSettings Successful Response
     * @throws ApiError
     */
    public static readUserSettingsApiV1UserSettingsGet(): CancelablePromise<UserSettingsWithNotificationSettings> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/user_settings',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update User Settings
     * Update the User Settings for the officer with id officer_id.
     * @param requestBody
     * @returns UserSettingsWithNotificationSettings Successful Response
     * @throws ApiError
     */
    public static updateUserSettingsApiV1UserSettingsPut(
        requestBody: UserSettingsAPIUpdate,
    ): CancelablePromise<UserSettingsWithNotificationSettings> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/user_settings',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read User Settings
     * Return the User Settings for the officer with id officer_id.
     * @returns UserSettingsWithNotificationSettingsV2 Successful Response
     * @throws ApiError
     */
    public static readUserSettingsApiV2UserSettingsGet(): CancelablePromise<UserSettingsWithNotificationSettingsV2> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v2/user_settings',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update User Settings
     * Update the User Settings for the officer with id officer_id.
     * @param requestBody
     * @returns UserSettingsWithNotificationSettingsV2 Successful Response
     * @throws ApiError
     */
    public static updateUserSettingsApiV2UserSettingsPut(
        requestBody: UserSettingsAPIUpdateV2,
    ): CancelablePromise<UserSettingsWithNotificationSettingsV2> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v2/user_settings',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
