/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AnvilWeldData } from './AnvilWeldData';
import type { DocumentSignInstance } from './DocumentSignInstance';
import type { OnboardingDocument } from './OnboardingDocument';

export type OnboardingI9ActionWithAnvilData = {
    status?: OnboardingI9ActionWithAnvilData.status;
    completed_ts?: string;
    last_update_ts?: string;
    form_type?: OnboardingI9ActionWithAnvilData.form_type;
    next_actor?: OnboardingI9ActionWithAnvilData.next_actor;
    is_manual?: boolean;
    id: string;
    document?: OnboardingDocument;
    sign_instance?: (DocumentSignInstance | null);
    weld_eid?: string;
    document_group_eid?: string;
    employer_i9_forge_eid?: string;
    employer_forge_complete_ts?: string;
    employer_signer_eid?: string;
    employer_signer_complete_ts?: string;
    employee_i9_forge_eid?: string;
    employee_forge_complete_ts?: string;
    employee_signer_eid?: string;
    employee_signer_complete_ts?: string;
    employee_everify_case_id?: string;
    officer_id?: string;
    weld_data: AnvilWeldData;
};

export namespace OnboardingI9ActionWithAnvilData {

    export enum status {
        NOT_STARTED = 'NOT_STARTED',
        IN_PROGRESS = 'IN_PROGRESS',
        SUBMITTED = 'SUBMITTED',
        COMPLETE = 'COMPLETE',
        CANCELLED = 'CANCELLED',
    }

    export enum form_type {
        I9 = 'I9',
        W4 = 'W4',
        WOTC = 'WOTC',
        PROFILE = 'PROFILE',
        AVAILABILITY = 'AVAILABILITY',
        EEO1 = 'EEO1',
        ESIGN = 'ESIGN',
    }

    export enum next_actor {
        EMPLOYEE = 'EMPLOYEE',
        EMPLOYER = 'EMPLOYER',
        EXTERNAL = 'EXTERNAL',
    }


}

