/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CursorPage_InboxNotification_ } from '../models/CursorPage_InboxNotification_';
import type { Notification } from '../models/Notification';
import type { NotificationCategory } from '../models/NotificationCategory';
import type { Page_InboxNotification_ } from '../models/Page_InboxNotification_';
import type { PushSubscription } from '../models/PushSubscription';
import type { PushSubscriptionCreate } from '../models/PushSubscriptionCreate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class NotificationService {

    /**
     * Add Push Subscription
     * Create a record of the push subscription token from the users device and
     * then attempt to sync it with magicbell.
     * @param requestBody
     * @returns PushSubscription Successful Response
     * @throws ApiError
     */
    public static addPushSubscriptionApiV1PushSubscriptionPut(
        requestBody: PushSubscriptionCreate,
    ): CancelablePromise<PushSubscription> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/push_subscription',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Push Subscription
     * @param deviceToken
     * @param userId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deletePushSubscriptionApiV1PushSubscriptionDelete(
        deviceToken: string,
        userId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/push_subscription',
            query: {
                'device_token': deviceToken,
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Send Notification
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static sendNotificationApiV1NotificationPost(
        requestBody: Notification,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/notification',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Mark As Resolved
     * @param id
     * @returns Notification Successful Response
     * @throws ApiError
     */
    public static markAsResolvedApiV1NotificationIdResolvePost(
        id: string,
    ): CancelablePromise<Notification> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/notification/{id}/resolve',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Mark As Read
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static markAsReadApiV1NotificationLogReadPut(
        requestBody?: (Array<string> | null),
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/notification_log/read',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Notifications Paginated
     * @param categories
     * @param page Page number
     * @param size Page size
     * @returns Page_InboxNotification_ Successful Response
     * @throws ApiError
     */
    public static getNotificationsPaginatedApiV1NotificationsPaginatedGet(
        categories?: (Array<NotificationCategory> | null),
        page: number = 1,
        size: number = 50,
    ): CancelablePromise<Page_InboxNotification_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/notifications/paginated',
            query: {
                'categories': categories,
                'page': page,
                'size': size,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Unread Count
     * @param categories
     * @returns number Successful Response
     * @throws ApiError
     */
    public static getUnreadCountApiV1NotificationsUnreadCountGet(
        categories?: (Array<NotificationCategory> | null),
    ): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/notifications/unread_count',
            query: {
                'categories': categories,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Notification Log
     * @param notificationLogId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteNotificationLogApiV1NotificationLogNotificationLogIdDelete(
        notificationLogId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/notification_log/{notification_log_id}',
            path: {
                'notification_log_id': notificationLogId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Notifications Paginated
     * @param categories
     * @param cursor Cursor for the next page
     * @param size Page size
     * @returns CursorPage_InboxNotification_ Successful Response
     * @throws ApiError
     */
    public static getNotificationsPaginatedApiV2NotificationsPaginatedGet(
        categories?: (Array<NotificationCategory> | null),
        cursor?: (string | null),
        size: number = 50,
    ): CancelablePromise<CursorPage_InboxNotification_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v2/notifications/paginated',
            query: {
                'categories': categories,
                'cursor': cursor,
                'size': size,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
