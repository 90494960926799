import {
    Popover,
    Typography,
    Stack,
    ListItem,
    List,
    Divider,
    Box,
    MenuItem,
    Menu,
    Badge,
    Tooltip,
} from '@mui/material'
import { useNotificationsWithUnreadCount } from 'src/services/notifications'
import { IconButton } from '@mui/material'
import {
    Cancel,
    CheckCircle,
    Circle,
    MoreHoriz,
    NotificationsOutlined,
    Settings,
} from '@mui/icons-material'
import { useEffect, useRef, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { CircularProgress } from '@mui/material'
import { InboxNotification } from 'src/generated'
import { Colors } from 'src/shared/styles/colors'
import {
    formatConciseDurationSinceDate,
    formatDateTimeForDisplay,
} from 'src/utils/dateUtils'
import { NotificationPreferences } from 'src/components/Notifications/NotificationPreferences'

export const NotificationInbox: React.FC = () => {
    const [open, setOpen] = useState(false)

    const iconRef = useRef<HTMLButtonElement>(null)

    const { unreadCount } = useNotificationsWithUnreadCount()

    return (
        <>
            <IconButton
                ref={iconRef}
                onClick={() => setOpen(true)}
                color="primary"
            >
                <Badge badgeContent={unreadCount} color="error">
                    <NotificationsOutlined />
                </Badge>
            </IconButton>
            <Popover
                slotProps={{
                    paper: {
                        sx: {
                            borderRadius: 5,
                        },
                    },
                }}
                open={open}
                onClose={() => setOpen(false)}
                anchorEl={iconRef.current}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <NotificationInfiniteContent />
            </Popover>
        </>
    )
}

const NotificationInfiniteContent: React.FC = () => {
    const {
        notifications,
        mutations: { markAsRead, deleteNotification },
        setSize,
        hasMore,
    } = useNotificationsWithUnreadCount()

    const [showNotificationPreferences, setShowNotificationPreferences] =
        useState(false)

    const scrollParentRef = useRef<HTMLDivElement>(null)

    // Set the size to 1 on unmount for page load accuracy and consistency.
    useEffect(() => {
        return () => {
            setSize(1)
        }
    }, [setSize])

    return (
        <Stack width="450px" height="450px" maxWidth="100%">
            <Stack
                pl={2}
                pr={1}
                py={1}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Typography
                    color="primary"
                    sx={{ fontSize: '15px', fontWeight: 400 }}
                >
                    {showNotificationPreferences
                        ? 'Notification Preferences'
                        : 'Notifications'}
                </Typography>
                <Stack direction="row" alignItems="center">
                    {showNotificationPreferences ? (
                        <IconButton
                            size="small"
                            color="primary"
                            onClick={() =>
                                setShowNotificationPreferences(false)
                            }
                        >
                            <Cancel />
                        </IconButton>
                    ) : (
                        <>
                            <Tooltip title="Mark all as read">
                                <IconButton
                                    size="small"
                                    color="primary"
                                    onClick={() => markAsRead()}
                                >
                                    <CheckCircle />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Notification Preferences">
                                <IconButton
                                    size="small"
                                    color="primary"
                                    onClick={() =>
                                        setShowNotificationPreferences(true)
                                    }
                                >
                                    <Settings />
                                </IconButton>
                            </Tooltip>
                        </>
                    )}
                </Stack>
            </Stack>
            <Divider />
            <Box ref={scrollParentRef} overflow="auto">
                {showNotificationPreferences ? (
                    <NotificationPreferences size="small" />
                ) : (
                    <InfiniteScroll
                        initialLoad={false}
                        pageStart={1}
                        loadMore={(page: number) => {
                            setSize(page)
                        }}
                        hasMore={hasMore}
                        loader={
                            <Stack
                                key="0"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <CircularProgress />
                            </Stack>
                        }
                        getScrollParent={() => scrollParentRef.current}
                        useWindow={false}
                    >
                        <List>
                            {notifications.map((notification) => (
                                <NotificationItem
                                    key={notification.id}
                                    notification={notification}
                                    markAsRead={() =>
                                        markAsRead([notification.id])
                                    }
                                    deleteNotification={() =>
                                        deleteNotification(notification.id)
                                    }
                                />
                            ))}
                        </List>
                    </InfiniteScroll>
                )}
            </Box>
        </Stack>
    )
}

const NotificationItem: React.FC<{
    notification: InboxNotification
    markAsRead: () => void
    deleteNotification: () => void
}> = ({ notification, markAsRead, deleteNotification }) => {
    const moreHorizRef = useRef<SVGSVGElement>(null)
    const [openMenu, setOpenMenu] = useState(false)

    const timeString = formatConciseDurationSinceDate(notification.sent_at)
    const timeStringTooltip = formatDateTimeForDisplay(notification.sent_at)
    const linkIsWebApp = notification.action_url?.includes(
        'app.belfrysoftware.com'
    )

    return (
        <ListItem
            disableGutters
            sx={{
                mx: '10px',
                width: 'calc(100% - 20px)',
                mb: '5px',
                '&:hover': {
                    backgroundColor: notification.is_read
                        ? 'inherit'
                        : Colors.notificationUnreadCellHoverColor,
                },
                backgroundColor: notification.is_read
                    ? 'inherit'
                    : Colors.notificationUnreadCellColor,
                borderRadius: '15px',
                cursor: linkIsWebApp ? 'pointer' : 'default',
            }}
            onClick={() => {
                if (linkIsWebApp && notification.action_url) {
                    window.open(notification.action_url, '_blank')
                }
            }}
        >
            <Stack
                direction="row"
                justifyContent="space-between"
                py="5px"
                pr="10px"
                flex={1}
            >
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    width="50px"
                >
                    {!notification.is_read && (
                        <Circle
                            sx={{ height: '7px', width: '7px' }}
                            color="primary"
                        />
                    )}
                </Box>
                <Stack gap="3px" flex={1}>
                    <Typography variant="dashboardBold">
                        {notification.title}
                    </Typography>
                    <Typography variant="dashboard">
                        {notification.content}
                    </Typography>
                </Stack>
                <Stack width="30px" alignItems="end" justifyContent="center">
                    <Tooltip title={timeStringTooltip}>
                        <Typography variant="dashboard" display="flex">
                            {timeString}
                        </Typography>
                    </Tooltip>
                    <MoreHoriz
                        ref={moreHorizRef}
                        onClick={(e) => {
                            e.stopPropagation()
                            setOpenMenu(true)
                        }}
                    />
                    <Menu
                        anchorEl={moreHorizRef.current}
                        open={openMenu}
                        onClose={() => setOpenMenu(false)}
                    >
                        <MenuItem
                            onClick={(e) => {
                                e.stopPropagation()
                                setOpenMenu(false)
                                markAsRead()
                            }}
                            sx={{ minHeight: '20px' }}
                        >
                            <Typography variant="dashboard">
                                Mark as Read
                            </Typography>
                        </MenuItem>
                        <MenuItem
                            onClick={(e) => {
                                e.stopPropagation()
                                setOpenMenu(false)
                                deleteNotification()
                            }}
                            sx={{ minHeight: '20px' }}
                        >
                            <Typography variant="dashboard">Delete</Typography>
                        </MenuItem>
                    </Menu>
                </Stack>
            </Stack>
        </ListItem>
    )
}
