/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { EmployeeProfile } from './EmployeeProfile';
import type { EverifyCaseInDB } from './EverifyCaseInDB';
import type { JobTitle } from './JobTitle';
import type { OfficerBannedSite } from './OfficerBannedSite';
import type { OfficerCategory } from './OfficerCategory';
import type { OfficerStatus } from './OfficerStatus';
import type { OfficerType } from './OfficerType';
import type { OnboardingStatus } from './OnboardingStatus';
import type { PostalAddress } from './PostalAddress';
import type { PreferredSchedule } from './PreferredSchedule';
import type { Region } from './Region';
import type { SupervisorSite } from './SupervisorSite';

export type Officer = {
    default_pay_rate?: number;
    pay_data_obscured?: boolean;
    name: string;
    id: string;
    given_name?: string;
    middle_name?: string;
    family_name?: string;
    nickname?: string;
    address?: string;
    postal_address?: PostalAddress;
    image_url?: string;
    phone_number?: string;
    hire_date?: string;
    date_of_birth?: string;
    email?: string;
    emergency_contact_name?: string;
    emergency_contact_phone_number?: string;
    employee_id?: string;
    employee_type?: Officer.employee_type;
    is_terminated?: boolean;
    termination_date?: string;
    termination_reason?: string;
    status?: OfficerStatus;
    category: OfficerCategory;
    type?: OfficerType;
    bill_rate?: number;
    sms_delivery_failure?: boolean;
    sms_invalid_phone_number?: boolean;
    role_id?: string;
    always_respect_officer_pay_rate?: boolean;
    exempt_employee?: boolean;
    salaried?: boolean;
    payroll_primary_workplace_customer_id?: (string | null);
    payroll_primary_workplace_non_customer_id?: (string | null);
    checkhq_id?: string;
    checkhq_contractor_id?: string;
    is_payroll_disabled?: boolean;
    job_title_id?: (string | null);
    workers_comp_code?: string;
    salary_weekly_hours?: number;
    clasp_member_id?: string;
    clasp_subclasses?: Array<string>;
    firm_id: string;
    preferredschedule: Array<PreferredSchedule>;
    banned_customers: Array<OfficerBannedSite>;
    regions: Array<Region>;
    supervisor_sites?: Array<SupervisorSite>;
    has_primary_workplace: boolean;
    checkhq_onboard_status?: string;
    checkhq_ssn_val?: string;
    onboarding_status?: OnboardingStatus;
    everify_cases?: Array<EverifyCaseInDB>;
    job_title?: JobTitle;
    profile?: EmployeeProfile;
    onboarding_template_id?: string;
    is_belfry_payroll_blocking: boolean;
};

export namespace Officer {

    export enum employee_type {
        SCHEDULABLE = 'Schedulable',
        NOT_SCHEDULABLE = 'Not Schedulable',
    }


}

