/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CheckHqWorkerResponse } from '../models/CheckHqWorkerResponse';
import type { EmployeeNote } from '../models/EmployeeNote';
import type { EmployeeNoteCreate } from '../models/EmployeeNoteCreate';
import type { EmployeeNoteUpdate } from '../models/EmployeeNoteUpdate';
import type { File } from '../models/File';
import type { FileCreate } from '../models/FileCreate';
import type { FileUpdate } from '../models/FileUpdate';
import type { HighLevelOfficer } from '../models/HighLevelOfficer';
import type { HighLevelOfficerForEmployeePortal } from '../models/HighLevelOfficerForEmployeePortal';
import type { HighLevelOfficerWithBansAndRegions } from '../models/HighLevelOfficerWithBansAndRegions';
import type { HighLevelOfficerWithQualifications } from '../models/HighLevelOfficerWithQualifications';
import type { IssuedCertification } from '../models/IssuedCertification';
import type { IssuedCertificationCreate } from '../models/IssuedCertificationCreate';
import type { IssuedCertificationUpdate } from '../models/IssuedCertificationUpdate';
import type { IssuedLicense } from '../models/IssuedLicense';
import type { IssuedLicenseCreate } from '../models/IssuedLicenseCreate';
import type { IssuedLicenseUpdate } from '../models/IssuedLicenseUpdate';
import type { Officer } from '../models/Officer';
import type { OfficerCategory } from '../models/OfficerCategory';
import type { OfficerCreate } from '../models/OfficerCreate';
import type { OfficerEarliestTerminationDate } from '../models/OfficerEarliestTerminationDate';
import type { OfficerForBirthdaysAndAnniversaries } from '../models/OfficerForBirthdaysAndAnniversaries';
import type { OfficerOnPatrolForDispatch } from '../models/OfficerOnPatrolForDispatch';
import type { OfficersWithNewHires } from '../models/OfficersWithNewHires';
import type { OfficerUpdate } from '../models/OfficerUpdate';
import type { OfficerWithPrimaryWorkplace } from '../models/OfficerWithPrimaryWorkplace';
import type { OfficerWorkHistory } from '../models/OfficerWorkHistory';
import type { OnboardingDocumentWithEmployeeAction } from '../models/OnboardingDocumentWithEmployeeAction';
import type { ReimbursementPolicy } from '../models/ReimbursementPolicy';
import type { ReimbursementPolicyUpdateForOfficer } from '../models/ReimbursementPolicyUpdateForOfficer';
import type { RestoreOfficerRequest } from '../models/RestoreOfficerRequest';
import type { RootModel_List_HighLevelOfficer__ } from '../models/RootModel_List_HighLevelOfficer__';
import type { TerminateOfficer } from '../models/TerminateOfficer';
import type { TerminateOfficerOffCycleFinalPayroll } from '../models/TerminateOfficerOffCycleFinalPayroll';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OfficerService {

    /**
     * Get Officer Export
     * @param onlyTerminated
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getOfficerExportApiV1OfficerExportGet(
        onlyTerminated: boolean = false,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/officer/export',
            query: {
                'only_terminated': onlyTerminated,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Officers
     * Return non-terminated officers for a users firm.
     * @param onlyTerminated
     * @param allOfficers
     * @returns RootModel_List_HighLevelOfficer__ Successful Response
     * @throws ApiError
     */
    public static readOfficersApiV1OfficerGet(
        onlyTerminated: boolean = false,
        allOfficers: boolean = false,
    ): CancelablePromise<RootModel_List_HighLevelOfficer__> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/officer',
            query: {
                'only_terminated': onlyTerminated,
                'all_officers': allOfficers,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Officer
     * @param requestBody
     * @returns Officer Successful Response
     * @throws ApiError
     */
    public static createOfficerApiV1OfficerPost(
        requestBody: OfficerCreate,
    ): CancelablePromise<Officer> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/officer',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Mineral Username For Back Office User
     * @returns any Successful Response
     * @throws ApiError
     */
    public static readMineralUsernameForBackOfficeUserApiV1OfficerMineralUsernameGet(): CancelablePromise<(string | boolean)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/officer/mineral_username',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read High Level Employees With Onboarding
     * Return officers with incomplete self-onboarding status and all officers.
     * @param onlyTerminated
     * @returns OfficersWithNewHires Successful Response
     * @throws ApiError
     */
    public static readHighLevelEmployeesWithOnboardingApiV1OfficerAllWithOnboardingGet(
        onlyTerminated: boolean = false,
    ): CancelablePromise<OfficersWithNewHires> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/officer/all_with_onboarding',
            query: {
                'only_terminated': onlyTerminated,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Employee For Employee Portal
     * @returns HighLevelOfficerForEmployeePortal Successful Response
     * @throws ApiError
     */
    public static readEmployeeForEmployeePortalApiV1OfficerOnboardingGet(): CancelablePromise<HighLevelOfficerForEmployeePortal> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/officer/onboarding',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Officers With Qualifications
     * Return a list of officers with their qualification attributes.
     * @param shiftInstanceId
     * @param includeNoRegionOfficers
     * @param evaluateAsShiftGroup
     * @returns HighLevelOfficerWithQualifications Successful Response
     * @throws ApiError
     */
    public static readOfficersWithQualificationsApiV1OfficerWithQualificationsShiftInstanceIdGet(
        shiftInstanceId: string,
        includeNoRegionOfficers: boolean = false,
        evaluateAsShiftGroup: boolean = false,
    ): CancelablePromise<Array<HighLevelOfficerWithQualifications>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/officer-with-qualifications/{shift_instance_id}',
            path: {
                'shift_instance_id': shiftInstanceId,
            },
            query: {
                'include_no_region_officers': includeNoRegionOfficers,
                'evaluate_as_shift_group': evaluateAsShiftGroup,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Officers With Qualifications For Customer Site
     * Return a list of officers with their qualification attributes that are on patrol,
     * to respond to a customer site.
     * @param customerSiteId
     * @returns OfficerOnPatrolForDispatch Successful Response
     * @throws ApiError
     */
    public static readOfficersWithQualificationsForCustomerSiteApiV1OfficersOnPatrolCustomerSiteIdGet(
        customerSiteId: string,
    ): CancelablePromise<Array<OfficerOnPatrolForDispatch>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/officers-on-patrol/{customer_site_id}',
            path: {
                'customer_site_id': customerSiteId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Officers Inc Bans
     * Return officers for a users firm, including the customer ids of banned_customers
     * @param onlyTerminated
     * @returns HighLevelOfficerWithBansAndRegions Successful Response
     * @throws ApiError
     */
    public static readOfficersIncBansApiV1OfficerWithBansGet(
        onlyTerminated: boolean = false,
    ): CancelablePromise<Array<HighLevelOfficerWithBansAndRegions>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/officer_with_bans',
            query: {
                'only_terminated': onlyTerminated,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Officers Inc Primary Workplace
     * Return slimmed-down officers schema for a users firm with primary workplace ID
     * @returns OfficerWithPrimaryWorkplace Successful Response
     * @throws ApiError
     */
    public static readOfficersIncPrimaryWorkplaceApiV1OfficerWithPrimaryWorkplaceGet(): CancelablePromise<Array<OfficerWithPrimaryWorkplace>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/officer_with_primary_workplace',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Supervisors
     * Return non-terminated supervisors for a users firm.
     * @param customerRegionId
     * @returns HighLevelOfficerWithBansAndRegions Successful Response
     * @throws ApiError
     */
    public static readSupervisorsApiV1SupervisorsGet(
        customerRegionId?: (string | null),
    ): CancelablePromise<Array<HighLevelOfficerWithBansAndRegions>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/supervisors',
            query: {
                'customer_region_id': customerRegionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Officer
     * @param id
     * @returns Officer Successful Response
     * @throws ApiError
     */
    public static readOfficerApiV1OfficerIdGet(
        id: string,
    ): CancelablePromise<Officer> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/officer/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Officer
     * @param id
     * @param requestBody
     * @returns Officer Successful Response
     * @throws ApiError
     */
    public static updateOfficerApiV1OfficerIdPut(
        id: string,
        requestBody: OfficerUpdate,
    ): CancelablePromise<Officer> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/officer/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Patch Officer
     * @param id
     * @param requestBody
     * @returns Officer Successful Response
     * @throws ApiError
     */
    public static patchOfficerApiV1OfficerIdPatch(
        id: string,
        requestBody: OfficerUpdate,
    ): CancelablePromise<Officer> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/officer/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Active Officer
     * @returns HighLevelOfficer Successful Response
     * @throws ApiError
     */
    public static readActiveOfficerApiV1ActiveOfficerGet(): CancelablePromise<HighLevelOfficer> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/active_officer',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Active Officer Payroll Status
     * @returns CheckHqWorkerResponse Successful Response
     * @throws ApiError
     */
    public static readActiveOfficerPayrollStatusApiV1ActiveOfficerPayrollStatusGet(): CancelablePromise<CheckHqWorkerResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/active_officer_payroll_status',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Officer Image
     * @param id
     * @param imageUrl
     * @returns string Successful Response
     * @throws ApiError
     */
    public static updateOfficerImageApiV1OfficerImageIdPut(
        id: string,
        imageUrl?: (string | null),
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/officer/image/{id}',
            path: {
                'id': id,
            },
            query: {
                'image_url': imageUrl,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Officer Category
     * @param id
     * @param officerCategoryUpdate
     * @returns Officer Successful Response
     * @throws ApiError
     */
    public static updateOfficerCategoryApiV1OfficerCategoryIdPut(
        id: string,
        officerCategoryUpdate: OfficerCategory,
    ): CancelablePromise<Officer> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/officer/category/{id}',
            path: {
                'id': id,
            },
            query: {
                'officer_category_update': officerCategoryUpdate,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Officer Certifications
     * @param id
     * @returns IssuedCertification Successful Response
     * @throws ApiError
     */
    public static getOfficerCertificationsApiV1OfficerIdCertificationsGet(
        id: string,
    ): CancelablePromise<Array<IssuedCertification>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/officer/{id}/certifications',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Officer Certification
     * @param id
     * @param requestBody
     * @returns IssuedCertification Successful Response
     * @throws ApiError
     */
    public static addOfficerCertificationApiV1OfficerIdCertificationsPut(
        id: string,
        requestBody: IssuedCertificationCreate,
    ): CancelablePromise<Array<IssuedCertification>> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/officer/{id}/certifications',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit Officer Certification
     * @param id
     * @param certificationId
     * @param requestBody
     * @returns IssuedCertification Successful Response
     * @throws ApiError
     */
    public static editOfficerCertificationApiV1OfficerIdCertificationsCertificationIdPost(
        id: string,
        certificationId: string,
        requestBody: IssuedCertificationUpdate,
    ): CancelablePromise<Array<IssuedCertification>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/officer/{id}/certifications/{certification_id}',
            path: {
                'id': id,
                'certification_id': certificationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Officer Certification
     * @param id
     * @param certificationId
     * @returns IssuedCertification Successful Response
     * @throws ApiError
     */
    public static deleteOfficerCertificationApiV1OfficerIdCertificationsCertificationIdDelete(
        id: string,
        certificationId: string,
    ): CancelablePromise<Array<IssuedCertification>> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/officer/{id}/certifications/{certification_id}',
            path: {
                'id': id,
                'certification_id': certificationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Restore Officer
     * @param id
     * @param payrollPrimaryWorkplaceCustomerId Deprecated. Use body instead.
     * @param payrollPrimaryWorkplaceNonCustomerId Deprecated. Use body instead.
     * @param requestBody
     * @returns Officer Successful Response
     * @throws ApiError
     */
    public static restoreOfficerApiV1OfficerRestoreIdPut(
        id: string,
        payrollPrimaryWorkplaceCustomerId?: (string | null),
        payrollPrimaryWorkplaceNonCustomerId?: (string | null),
        requestBody?: (RestoreOfficerRequest | null),
    ): CancelablePromise<Officer> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/officer/restore/{id}',
            path: {
                'id': id,
            },
            query: {
                'payroll_primary_workplace_customer_id': payrollPrimaryWorkplaceCustomerId,
                'payroll_primary_workplace_non_customer_id': payrollPrimaryWorkplaceNonCustomerId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Enable Officer Payroll
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    public static enableOfficerPayrollApiV1OfficerEnablePayrollIdPost(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/officer/enable_payroll/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Disable Officer Payroll
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    public static disableOfficerPayrollApiV1OfficerDisablePayrollIdPost(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/officer/disable_payroll/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Terminate Officer
     * @param id
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static terminateOfficerApiV1OfficerTerminateIdDelete(
        id: string,
        requestBody: TerminateOfficer,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/officer/terminate/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Hard Delete Officer
     * @param id
     * @returns string Successful Response
     * @throws ApiError
     */
    public static hardDeleteOfficerApiV1OfficerIdHardDeleteDelete(
        id: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/officer/{id}/hard-delete',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Officer Licenses
     * @param id
     * @returns IssuedLicense Successful Response
     * @throws ApiError
     */
    public static getOfficerLicensesApiV1OfficerIdLicensesGet(
        id: string,
    ): CancelablePromise<Array<IssuedLicense>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/officer/{id}/licenses',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Officer License
     * @param id
     * @param requestBody
     * @returns IssuedLicense Successful Response
     * @throws ApiError
     */
    public static addOfficerLicenseApiV1OfficerIdLicensesPut(
        id: string,
        requestBody: IssuedLicenseCreate,
    ): CancelablePromise<Array<IssuedLicense>> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/officer/{id}/licenses',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit Officer License
     * @param id
     * @param issuedLicenseId
     * @param requestBody
     * @returns IssuedLicense Successful Response
     * @throws ApiError
     */
    public static editOfficerLicenseApiV1OfficerIdLicensesIssuedLicenseIdPost(
        id: string,
        issuedLicenseId: string,
        requestBody: IssuedLicenseUpdate,
    ): CancelablePromise<Array<IssuedLicense>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/officer/{id}/licenses/{issued_license_id}',
            path: {
                'id': id,
                'issued_license_id': issuedLicenseId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Officer License
     * @param id
     * @param licenseId
     * @returns IssuedLicense Successful Response
     * @throws ApiError
     */
    public static deleteOfficerLicenseApiV1OfficerIdLicensesLicenseIdDelete(
        id: string,
        licenseId: string,
    ): CancelablePromise<Array<IssuedLicense>> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/officer/{id}/licenses/{license_id}',
            path: {
                'id': id,
                'license_id': licenseId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Officer Files
     * @param id
     * @returns File Successful Response
     * @throws ApiError
     */
    public static getOfficerFilesApiV1OfficerIdFilesGet(
        id: string,
    ): CancelablePromise<Array<File>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/officer/{id}/files',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Officer File
     * @param id
     * @param requestBody
     * @returns File Successful Response
     * @throws ApiError
     */
    public static addOfficerFileApiV1OfficerIdFilesPost(
        id: string,
        requestBody: FileCreate,
    ): CancelablePromise<Array<File>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/officer/{id}/files',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit Officer Files
     * @param id
     * @param fileId
     * @param requestBody
     * @returns File Successful Response
     * @throws ApiError
     */
    public static editOfficerFilesApiV1OfficerIdFilesFileIdPut(
        id: string,
        fileId: string,
        requestBody: FileUpdate,
    ): CancelablePromise<Array<File>> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/officer/{id}/files/{file_id}',
            path: {
                'id': id,
                'file_id': fileId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Officer File
     * @param id
     * @param fileId
     * @returns File Successful Response
     * @throws ApiError
     */
    public static deleteOfficerFileApiV1OfficerIdFilesFileIdDelete(
        id: string,
        fileId: string,
    ): CancelablePromise<Array<File>> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/officer/{id}/files/{file_id}',
            path: {
                'id': id,
                'file_id': fileId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Officer Documents
     * @param id
     * @returns OnboardingDocumentWithEmployeeAction Successful Response
     * @throws ApiError
     */
    public static getOfficerDocumentsApiV1OfficerIdDocumentsGet(
        id: string,
    ): CancelablePromise<Array<OnboardingDocumentWithEmployeeAction>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/officer/{id}/documents',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Officer Notes
     * @param id
     * @returns EmployeeNote Successful Response
     * @throws ApiError
     */
    public static getOfficerNotesApiV1OfficerIdNotesGet(
        id: string,
    ): CancelablePromise<Array<EmployeeNote>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/officer/{id}/notes',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Officer Note
     * @param id
     * @param requestBody
     * @returns EmployeeNote Successful Response
     * @throws ApiError
     */
    public static addOfficerNoteApiV1OfficerIdNotesPost(
        id: string,
        requestBody: EmployeeNoteCreate,
    ): CancelablePromise<EmployeeNote> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/officer/{id}/notes',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit Officer Employee Note
     * @param id
     * @param noteId
     * @param requestBody
     * @returns EmployeeNote Successful Response
     * @throws ApiError
     */
    public static editOfficerEmployeeNoteApiV1OfficerIdNotesNoteIdPut(
        id: string,
        noteId: string,
        requestBody: EmployeeNoteUpdate,
    ): CancelablePromise<EmployeeNote> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/officer/{id}/notes/{note_id}',
            path: {
                'id': id,
                'note_id': noteId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Officer Note
     * @param id
     * @param noteId
     * @returns EmployeeNote Successful Response
     * @throws ApiError
     */
    public static deleteOfficerNoteApiV1OfficerIdNotesNoteIdDelete(
        id: string,
        noteId: string,
    ): CancelablePromise<EmployeeNote> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/officer/{id}/notes/{note_id}',
            path: {
                'id': id,
                'note_id': noteId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Pdf For Employee Summary
     * Return the employee summary pdf for an employee
     * @param officerId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static createPdfForEmployeeSummaryApiV1OfficerOfficerIdPdfPost(
        officerId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/officer/{officer_id}/pdf',
            path: {
                'officer_id': officerId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Officer Work History
     * @param id
     * @returns OfficerWorkHistory Successful Response
     * @throws ApiError
     */
    public static getOfficerWorkHistoryApiV1OfficerIdWorkHistoryGet(
        id: string,
    ): CancelablePromise<OfficerWorkHistory> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/officer/{id}/work_history',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * List Officer Reimbursements
     * @param id
     * @returns ReimbursementPolicy Successful Response
     * @throws ApiError
     */
    public static listOfficerReimbursementsApiV1OfficerIdReimbursementsGet(
        id: string,
    ): CancelablePromise<Array<ReimbursementPolicy>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/officer/{id}/reimbursements',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Officer Reimbursements
     * @param id
     * @param requestBody
     * @returns ReimbursementPolicy Successful Response
     * @throws ApiError
     */
    public static updateOfficerReimbursementsApiV1OfficerIdReimbursementsPut(
        id: string,
        requestBody: ReimbursementPolicyUpdateForOfficer,
    ): CancelablePromise<Array<ReimbursementPolicy>> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/officer/{id}/reimbursements',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Officer Earliest Term Date
     * @param id
     * @returns OfficerEarliestTerminationDate Successful Response
     * @throws ApiError
     */
    public static getOfficerEarliestTermDateApiV1OfficerIdEarliestTermDateGet(
        id: string,
    ): CancelablePromise<OfficerEarliestTerminationDate> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/officer/{id}/earliest_term_date',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Celebration Officers
     * @returns OfficerForBirthdaysAndAnniversaries Successful Response
     * @throws ApiError
     */
    public static readCelebrationOfficersApiV1OfficerCelebrationsGet(): CancelablePromise<Array<OfficerForBirthdaysAndAnniversaries>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/officer_celebrations',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * After Terminate Officer Create Off Cycle Payrun
     * @param id
     * @param requestBody
     * @returns string Successful Response
     * @throws ApiError
     */
    public static afterTerminateOfficerCreateOffCyclePayrunApiV2OfficerTerminateIdOffCyclePayPost(
        id: string,
        requestBody: TerminateOfficerOffCycleFinalPayroll,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v2/officer/terminate/{id}/off_cycle_pay',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
