import {
    parse,
    isValid,
    format,
    endOfWeek,
    subWeeks,
    addWeeks,
    addMilliseconds,
    lastDayOfMonth,
    setDate,
    parseISO,
    formatDistance,
    differenceInWeeks,
    startOfWeek,
} from 'date-fns'
import { AVERAGE_NUMBER_OF_DAYS_IN_MONTH } from '../constants/dateConstants'
import {
    ShiftInstanceForSchedule,
    ScheduleShiftInstance,
    ShiftInstanceForClientBelltower,
    SimpleShiftInstanceForSchedule,
} from '../generated'
import { TKSettingsPayPeriodKindEnum } from '../generated/models/TKSettingsPayPeriodKindEnum'
import { DateTime } from 'luxon'
import { ShiftInstanceForBelltower } from '../generated/models/ShiftInstanceForBelltower'
import { GroupedShiftInstanceForSchedule } from '../components/ShiftOffer/types'

export const MAX_SCHEDULE_DATE = new Date(2026, 1, 1)

export const formatDateWithTimeDisplaySetting = (
    date: Date,
    format12Hour: string,
    format24Hour: string,
    use24HourTime: boolean
) => {
    return use24HourTime
        ? format(date, format24Hour)
        : format(date, format12Hour)
}

export const formatDateTimeWithTimeDisplaySetting = (
    date: DateTime,
    format12Hour: string,
    format24Hour: string,
    use24HourTime: boolean
) => {
    return use24HourTime
        ? date.toFormat(format24Hour)
        : date.toFormat(format12Hour)
}

export const parseAndFormatWithTZAndTimeDisplaySetting = (
    isoDateString: string,
    iana_timezone: string,
    format12Hour: string,
    format24Hour: string,
    use24HourTime: boolean
) => {
    const d = DateTime.fromISO(isoDateString as string, { zone: iana_timezone })
    return use24HourTime ? d.toFormat(format24Hour) : d.toFormat(format12Hour)
}

export const parseAndFormatWithTimeDisplaySetting = (
    isoDateString: string,
    format12Hour: string,
    format24Hour: string,
    use24HourTime: boolean
) => {
    const d = DateTime.fromISO(isoDateString as string)
    return use24HourTime ? d.toFormat(format24Hour) : d.toFormat(format12Hour)
}

export const getLocalDateFromUTCDateAndLocalTime = (
    isoDateString: string,
    ianaTimezone: string,
    timeString: string
): string => {
    // Parse the date in the target timezone
    const dateInTz = DateTime.fromISO(isoDateString, { zone: ianaTimezone })

    // Parse the time components
    const [hours, minutes] = timeString.split(':').map(Number)

    // Set the time components while preserving the date and timezone
    const result = dateInTz.set({
        hour: hours,
        minute: minutes,
        second: 0,
        millisecond: 0,
    })

    // Return as ISO string
    return result.toISODate()
}

// Function to get the timezone offset in the format "+HH:mm" or "-HH:mm"
export const getTimezoneOffsetString = (date: Date | null) => {
    if (!date) return ''
    const offset = date.getTimezoneOffset()
    const absoluteOffset = Math.abs(offset)
    const hours = String(Math.floor(absoluteOffset / 60)).padStart(2, '0')
    const minutes = String(absoluteOffset % 60).padStart(2, '0')
    const sign = offset > 0 ? '-' : '+'
    return `${sign}${hours}:${minutes}`
}

export const formatDateString = (date_or_string: Date | string | undefined) => {
    if (date_or_string instanceof Date) {
        return format(date_or_string, 'yyyy-MM-dd')
    }
    return date_or_string
}

// will attempt to parse a date from multiple provided formats. Used when
// server format does not match client format
export const parseMultiple = (
    dateString: string,
    formatStrings: string[]
): Date => {
    let result
    for (const formatString of formatStrings) {
        result = parse(dateString, formatString, new Date())
        if (isValid(result)) return result
    }
    return new Date()
}

export const calculateShiftInstanceEndTime = (
    instance: Pick<
        ShiftInstanceForSchedule,
        'shift_end_date_time' | 'shift' | 'id'
    >,
    format: string = 'HH:mm'
) => {
    if (instance.shift_end_date_time) {
        return parseAndFormatWithTZ(
            instance.shift_end_date_time,
            instance.shift.iana_timezone,
            format
        )
    } else {
        throw new Error(
            `No shift_end_date_time found for shift_instance ${instance.id}`
        )
    }
}

export const getTimeOffsetForIanaTimezone = (iana_timezone: string): string => {
    const dateTime = DateTime.now().setZone(iana_timezone)
    return dateTime.toFormat('ZZ')
}

// Given a start time and a end time, returns a length
export const calculateTimeDelta = (
    shiftStart: string,
    shiftEnd: string
): number => {
    const shiftIn = parseMultiple(shiftStart, ['HH:mm', 'HH:mm:ss'])
    const shiftOut = parseMultiple(shiftEnd, ['HH:mm', 'HH:mm:ss'])

    return (shiftOut.getTime() - shiftIn.getTime()) / 1000
}

type DisplayTimeRangeParams = { dateAtTime: Date; use24HourClock: boolean }
export const formatShiftBoundary = ({
    dateAtTime,
    use24HourClock,
}: DisplayTimeRangeParams) => {
    if (
        !(dateAtTime instanceof Date) ||
        dateAtTime.toString() === 'Invalid Date'
    ) {
        return ''
    }
    return use24HourClock
        ? format(dateAtTime, 'HH:mm')
        : format(dateAtTime, 'h:mm a')
}

export const getDisplayableShiftTimeRange = (
    shift: { shift_start: string; shift_length: number; shift_end?: string },
    use24HourClock: boolean
) => {
    const shiftIn = parseMultiple(shift.shift_start, ['HH:mm', 'HH:mm:ss'])
    const shiftOut = shift?.shift_end
        ? parseMultiple(shift.shift_end, ['HH:mm', 'HH:mm:ss'])
        : new Date(shiftIn.getTime() + shift.shift_length * 1000)

    return `${formatShiftBoundary({
        dateAtTime: shiftIn,
        use24HourClock,
    })} - ${formatShiftBoundary({ dateAtTime: shiftOut, use24HourClock })}`
}

export const getDisplayableTimeRange = (
    shift: { shift_start?: string; shift_end?: string },
    use24HourClock: boolean
): string => {
    return [shift.shift_start, shift.shift_end]
        .filter((x) => !!x)
        .map((timeStr) => parseMultiple(timeStr!, ['HH:mm', 'HH:mm:ss']))
        .map((date) =>
            formatShiftBoundary({ dateAtTime: date, use24HourClock })
        )
        .join(' - ')
}

type DayOfWeek = 0 | 1 | 2 | 3 | 4 | 5 | 6
export const isoToJSDayOfWeek = (isoDayOfWeek: number): DayOfWeek => {
    return ((isoDayOfWeek + 1) % 7) as DayOfWeek
}

export const jsDayToISODayOfWeek = (jsDayOfWeek: number): DayOfWeek => {
    return ((jsDayOfWeek + 6) % 7) as DayOfWeek
}

export const daysToMonths = (days: number): number => {
    return Math.ceil(days / AVERAGE_NUMBER_OF_DAYS_IN_MONTH)
}

export const monthsToDays = (months: number): number => {
    return Math.floor(months * AVERAGE_NUMBER_OF_DAYS_IN_MONTH)
}

export const datetimeStringToTime = (
    isoDateString: string,
    iana_timezone: string
) => {
    return DateTime.fromISO(isoDateString as string, {
        zone: iana_timezone,
    }).toISOTime()
}

export const formatShiftInstanceForScheduleTimes = (
    instance: ShiftInstanceForSchedule | GroupedShiftInstanceForSchedule,
    use24HourClock: boolean
) => {
    const start = DateTime.fromISO(instance.shift_start_date_time as string, {
        zone: instance?.shift.iana_timezone,
    })
    const end = DateTime.fromISO(instance.shift_end_date_time as string, {
        zone: instance?.shift.iana_timezone,
    })
    const result =
        formatDateTimeWithTimeDisplaySetting(
            start,
            'h:mma',
            'HH:mm',
            use24HourClock
        ) +
        '-' +
        formatDateTimeWithTimeDisplaySetting(
            end,
            'h:mma',
            'HH:mm',
            use24HourClock
        )
    return (
        result.toLowerCase() +
        maybeAbbrevTZIfDiffers(instance?.shift.iana_timezone)
    )
}

export const formatStartAndEndForScheduleTimes = (
    startTime: string,
    endTime: string,
    iana_timezone: string,
    use24HourClock: boolean
) => {
    const start = DateTime.fromISO(startTime as string, {
        zone: iana_timezone,
    })
    const end = DateTime.fromISO(endTime as string, {
        zone: iana_timezone,
    })
    const result =
        formatDateTimeWithTimeDisplaySetting(
            start,
            'h:mma',
            'HH:mm',
            use24HourClock
        ) +
        '-' +
        formatDateTimeWithTimeDisplaySetting(
            end,
            'h:mma',
            'HH:mm',
            use24HourClock
        )
    return result.toLowerCase() + maybeAbbrevTZIfDiffers(iana_timezone)
}

export const formatRelativeTime = (
    instance: SimpleShiftInstanceForSchedule | ShiftInstanceForSchedule
) => {
    const now = new Date()
    const start = parseISO(instance.shift_start_date_time as string)
    const prefix = now > start ? 'Started' : 'Starts'
    return `${prefix} ${formatDistance(start, now, { addSuffix: true })}`
}

export const formatShiftInstanceTimes = (
    instance:
        | ScheduleShiftInstance
        | ShiftInstanceForBelltower
        | ShiftInstanceForClientBelltower,
    use24HourClock: boolean
) => {
    const start = DateTime.fromISO(instance.shift_start_date_time as string, {
        zone: instance?.iana_timezone,
    })
    const end = DateTime.fromISO(instance.shift_end_date_time as string, {
        zone: instance?.iana_timezone,
    })
    const result =
        formatDateTimeWithTimeDisplaySetting(
            start,
            'h:mma',
            'HH:mm',
            use24HourClock
        ) +
        '-' +
        formatDateTimeWithTimeDisplaySetting(
            end,
            'h:mma',
            'HH:mm',
            use24HourClock
        )
    return (
        result.toLowerCase() + maybeAbbrevTZIfDiffers(instance?.iana_timezone)
    )
}

export const formatShiftInstanceTimesForBelltower = (
    instance: ShiftInstanceForBelltower | ShiftInstanceForClientBelltower,
    use24HourClock: boolean
) => {
    const start = DateTime.fromISO(instance.shift_start_date_time as string, {
        zone: instance.iana_timezone,
    })
    const end = DateTime.fromISO(instance.shift_end_date_time as string, {
        zone: instance.iana_timezone,
    })
    const result =
        formatDateTimeWithTimeDisplaySetting(
            start,
            'ccc MMM dd, h:mma',
            'ccc MMM dd, HH:mm',
            use24HourClock
        ) +
        '-' +
        formatDateTimeWithTimeDisplaySetting(
            end,
            start.day != end.day ? 'ccc MMM dd, h:mma' : 'h:mma',
            start.day != end.day ? 'ccc MMM dd, HH:mm' : 'HH:mm',
            use24HourClock
        )
    return result + maybeAbbrevTZIfDiffers(instance.iana_timezone)
}

export const tzAdjustedISO = (isoDateString: string, iana_timezone: string) => {
    return DateTime.fromISO(isoDateString as string, {
        zone: iana_timezone,
    }).toISO()
}

export const parseAndFormatWithTZ = (
    isoDateString: string,
    iana_timezone: string,
    formatString: string
) => {
    const d = DateTime.fromISO(isoDateString as string, { zone: iana_timezone })
    return d.toFormat(formatString)
}

export function isMidnight(isoDateString: string, iana_timezone: string) {
    const d = DateTime.fromISO(isoDateString as string, { zone: iana_timezone })
    return d.get('hour') === 0 && d.get('minute') === 0
}

export const formatTime = (timeString: string) => {
    const timeValue = parse(timeString, 'HH:mm:ss', new Date())
    const formattedTime = format(timeValue, 'h:mma')
    return formattedTime.replace(':00', '')
}

export const getCorrectShiftLength = (shift_length: number): number => {
    return shift_length < 0 ? shift_length + 86400 : shift_length
}

// there is a backend version of this which must match in pay_period_helpers.py
const START_DAY_OF_WEEK_TO_REFERENCE_DATE: { [key: string]: Date } = {
    // monday
    '0': new Date(2022, 0, 3),
    // tues
    '1': new Date(2022, 0, 4),
    // weds
    '2': new Date(2022, 0, 5),
    // thurs
    '3': new Date(2022, 0, 6),
    // fri
    '4': new Date(2022, 0, 7),
    // sat
    '5': new Date(2022, 0, 8),
    // sun
    '6': new Date(2022, 0, 9),
}

function weeksSinceFirstWeekOf2022(
    targetDate: Date,
    start_day_of_week: number
) {
    const refDate =
        START_DAY_OF_WEEK_TO_REFERENCE_DATE[start_day_of_week.toString()]
    const diff = differenceInWeeks(targetDate, refDate)
    return diff
}

// the added *millisecond*  is because otherwise it'll start at 11:59:59.999pm on the day before
// the week number even/odd is to make the bi-weekly chunking deterministic
// backend equivalent is pay_period_helpers.get_pay_period_start_and_end_helper
export function getPayPeriodStartAndEnd(
    targetDate: Date,
    start_day_of_week: number,
    tk_pay_period_kind: TKSettingsPayPeriodKindEnum,
    tk_biweek_start_odd: number
) {
    const weekStartsOn = isoToJSDayOfWeek(start_day_of_week)
    if (tk_pay_period_kind === TKSettingsPayPeriodKindEnum.WEEKLY) {
        const wEnd: Date = endOfWeek(targetDate, { weekStartsOn })
        return [addMilliseconds(subWeeks(wEnd, 1), 1), wEnd]
    } else if (tk_pay_period_kind === TKSettingsPayPeriodKindEnum.BIWEEKLY) {
        const wEnd: Date = endOfWeek(targetDate, { weekStartsOn })
        const weekNumber = weeksSinceFirstWeekOf2022(
            targetDate,
            start_day_of_week
        )
        return weekNumber % 2 === tk_biweek_start_odd
            ? [addMilliseconds(subWeeks(wEnd, 2), 1), wEnd]
            : [addMilliseconds(subWeeks(wEnd, 1), 1), addWeeks(wEnd, 1)]
    } else {
        // finally handle TKSettingsPayPeriodKindEnum.SEMIMONTHLY
        const isFirstHalf = targetDate.getDate() <= 15
        return isFirstHalf
            ? [setDate(targetDate, 1), setDate(targetDate, 15)]
            : [setDate(targetDate, 16), lastDayOfMonth(targetDate)]
    }
}

export function getClientIanaTZ() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone
}
export function fromIanaToAbbrev(iana_timezone: string | undefined) {
    if (!iana_timezone) {
        return ''
    }
    // the actual date here doesn't matter, we only care about the timezone to get the short version
    return DateTime.fromObject({}, { zone: iana_timezone }).toFormat('ZZZZ')
}

export function maybeAbbrevTZIfDiffers(iana_timezone: string | undefined) {
    if (!iana_timezone) {
        return ''
    }
    return getClientIanaTZ() !== iana_timezone
        ? ` (${fromIanaToAbbrev(iana_timezone)})`
        : ''
}

// the type="datetime-local" input can't handle timezones, so we simply drop that part of the string, which is in our local timezone
// it's also possible to create an invalid datetime with the input, hence the try catch
export function formatNoTimezone(dt: string) {
    try {
        return dt.slice(0, 16)
    } catch (error) {
        return ''
    }
}

// the type="datetime-local" input can't handle timezones, so we simply drop that part of the string, which is in our local timezone
// it's also possible to create an invalid datetime with the input, hence the try catch
export function formatDateOnlyNoTimezone(dt: string) {
    try {
        return dt.slice(0, 10)
    } catch (error) {
        return ''
    }
}

export function formtDateTimeMonthDayYear(dt: string | undefined) {
    return formatDateTimeForDisplay(dt, {
        includeYear: true,
        includeTime: false,
        includeSeconds: false,
    })
}

export function formatDateTime(
    value: string | undefined,
    fullValue: boolean = false,
    use24HourClock: boolean = false
) {
    return formatDateTimeForDisplay(
        value,
        {
            includeYear: fullValue,
            includeTime: fullValue,
            includeSeconds: fullValue,
        },
        use24HourClock
    )
}

export function formatDateTimeForDisplay(
    value: string | undefined,
    options = { includeYear: false, includeTime: true, includeSeconds: false },
    use24HourTime: boolean = false
) {
    if (!value) return ''

    let formatStr = 'MM/dd'

    if (options.includeYear) {
        formatStr += '/yyyy'
    }

    if (options.includeTime) {
        formatStr += use24HourTime ? " 'at' HH:mm" : " 'at' hh:mm"
        if (options.includeSeconds) {
            formatStr += ':ss'
        }
        if (!use24HourTime) {
            formatStr += ' a'
        }
    }

    return format(parseISO(value), formatStr)
}

export function getTodayWeekStart(settings_start_day_of_week: number) {
    const today = new Date()
    const todayWeekStart: Date = startOfWeek(today, {
        weekStartsOn: isoToJSDayOfWeek(settings_start_day_of_week),
    })
    return todayWeekStart
}

export function formatDurationInHoursAndMinutes(duration: number) {
    const durationHours = Math.floor(duration / 60)
    const durationMinutes = duration % 60
    return durationHours > 0
        ? `${durationHours} hr ${durationMinutes} min`
        : `${durationMinutes} min`
}

export const IS_SAFARI =
    typeof window !== 'undefined'
        ? /^((?!chrome|android).)*safari/i.test(window.navigator.userAgent)
        : false

export const formatDurationBetweenDates = (
    start: string | undefined,
    end: string | undefined
): string => {
    if (!start || !end) return '-'

    const startDate = parseISO(start)
    const endDate = parseISO(end)
    const durationInMilliseconds = endDate.getTime() - startDate.getTime()

    // Convert milliseconds to minutes
    const durationInMinutes = Math.floor(durationInMilliseconds / 60000)

    return formatDurationInHoursAndMinutes(durationInMinutes)
}

export const formatConciseDurationSinceDate = (date: string): string => {
    const time = new Date(date)
    const currentTime = new Date()
    const timeDiff = currentTime.getTime() - time.getTime()
    const timeDiffInSeconds = Math.round(timeDiff / 1000)
    const timeDiffInMinutes = Math.round(timeDiff / (1000 * 60))
    const timeDiffInHours = Math.round(timeDiff / (1000 * 60 * 60))
    const timeDiffInDays = Math.round(timeDiff / (1000 * 60 * 60 * 24))

    let timeString = ''
    if (timeDiffInDays > 0) {
        timeString = `${timeDiffInDays}d`
    } else if (timeDiffInHours > 0) {
        timeString = `${timeDiffInHours}h`
    } else if (timeDiffInMinutes > 0) {
        timeString = `${timeDiffInMinutes}m`
    } else {
        timeString = `${timeDiffInSeconds}s`
    }
    return timeString
}

export const isSameDayWithTZ = (
    date1: string,
    date2: string,
    iana_timezone: string
) => {
    const date1WithTZ = DateTime.fromISO(date1, { zone: iana_timezone })
    const date2WithTZ = DateTime.fromISO(date2, { zone: iana_timezone })
    return date1WithTZ.hasSame(date2WithTZ, 'day')
}
