/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DocumentSignInstance } from './DocumentSignInstance';
import type { OnboardingDocument } from './OnboardingDocument';
import type { WOTCApplicant } from './WOTCApplicant';

export type OnboardingWOTCAction = {
    status?: OnboardingWOTCAction.status;
    completed_ts?: string;
    last_update_ts?: string;
    form_type?: OnboardingWOTCAction.form_type;
    next_actor?: OnboardingWOTCAction.next_actor;
    is_manual?: boolean;
    id: string;
    document?: OnboardingDocument;
    sign_instance?: (DocumentSignInstance | null);
    wotc_applicant?: WOTCApplicant;
    questionnaire_link?: string;
};

export namespace OnboardingWOTCAction {

    export enum status {
        NOT_STARTED = 'NOT_STARTED',
        IN_PROGRESS = 'IN_PROGRESS',
        SUBMITTED = 'SUBMITTED',
        COMPLETE = 'COMPLETE',
        CANCELLED = 'CANCELLED',
    }

    export enum form_type {
        I9 = 'I9',
        W4 = 'W4',
        WOTC = 'WOTC',
        PROFILE = 'PROFILE',
        AVAILABILITY = 'AVAILABILITY',
        EEO1 = 'EEO1',
        ESIGN = 'ESIGN',
    }

    export enum next_actor {
        EMPLOYEE = 'EMPLOYEE',
        EMPLOYER = 'EMPLOYER',
        EXTERNAL = 'EXTERNAL',
    }


}

