export interface OfficerLabelProps {
    userImageUrl?: string
    avatarSize?: number
    styledName?: JSX.Element
    name: string
    children?: JSX.Element
    enableImageUpload?: boolean
    officerId?: string
    linkToPage?: boolean
    phoneNumber?: string
    withOfficerName?: boolean
    onLocationIconClick?: () => void
    lowerImageQuality?: boolean
    jobTitle?: string
}

export type SignedUserImageType = {
    userImageUrl?: string
    avatarSize?: number
    name: string
    focusOfficer?: boolean
}

export type UnsignedUserImageType = {
    userImageUrl?: string
    avatarSize?: number
    name: string
    lowerImageQuality?: boolean
}

export const defaultAvatarSize = 32
