/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DocumentSignTemplate } from './DocumentSignTemplate';
import type { File } from './File';
import type { OfficerProfile } from './OfficerProfile';

export type OnboardingDocument = {
    firm_id?: string;
    requires_employee_acknowledgement?: boolean;
    share_group?: OnboardingDocument.share_group;
    share_group_role_ids?: Array<string>;
    id: string;
    files?: Array<File>;
    sign_template?: DocumentSignTemplate;
    employee_access_list?: Array<OfficerProfile>;
};

export namespace OnboardingDocument {

    export enum share_group {
        ALL = 'ALL',
        ROLE = 'ROLE',
        INDIVIDUAL = 'INDIVIDUAL',
        NONE = 'NONE',
    }


}

