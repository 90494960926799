/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Assignment } from '../models/Assignment';
import type { Body_clock_out_api_v2_assignment_clock_out__shift_id___date___index__put } from '../models/Body_clock_out_api_v2_assignment_clock_out__shift_id___date___index__put';
import type { Body_update_shift_attestation_api_v1_assignment_shift_attestation__shift_id___date___index__put } from '../models/Body_update_shift_attestation_api_v1_assignment_shift_attestation__shift_id___date___index__put';
import type { OfficerLocationCreate } from '../models/OfficerLocationCreate';
import type { OfficerPermissions } from '../models/OfficerPermissions';
import type { PostOrderInstance } from '../models/PostOrderInstance';
import type { ShiftAttestation } from '../models/ShiftAttestation';
import type { ShiftInstanceForAssignment } from '../models/ShiftInstanceForAssignment';
import type { SiteVisitOperationGrouped } from '../models/SiteVisitOperationGrouped';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AssignmentService {

    /**
     * Get Current Assignment
     * Returns the shift active at the current time or the next shift if none are active.
     * @returns Assignment Successful Response
     * @throws ApiError
     */
    public static getCurrentAssignmentApiV1AssignmentNextGet(): CancelablePromise<Assignment> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/assignment/next',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Clock In
     * @param shiftId
     * @param date
     * @param index
     * @param ackPostOrder
     * @param requestBody
     * @returns ShiftInstanceForAssignment Successful Response
     * @throws ApiError
     */
    public static clockInApiV1AssignmentClockInShiftIdDateIndexPut(
        shiftId: string,
        date: string,
        index: number,
        ackPostOrder?: (boolean | null),
        requestBody?: (OfficerLocationCreate | null),
    ): CancelablePromise<ShiftInstanceForAssignment> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/assignment/clock_in/{shift_id}/{date}/{index}',
            path: {
                'shift_id': shiftId,
                'date': date,
                'index': index,
            },
            query: {
                'ack_post_order': ackPostOrder,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Ack Post Order
     * @param shiftId
     * @param date
     * @param index
     * @param customerId
     * @param taskId
     * @param requestBody
     * @returns PostOrderInstance Successful Response
     * @throws ApiError
     */
    public static ackPostOrderApiV1AssignmentAckPostOrderShiftIdDateIndexPut(
        shiftId: string,
        date: string,
        index: number,
        customerId?: (string | null),
        taskId?: (string | null),
        requestBody?: (OfficerLocationCreate | null),
    ): CancelablePromise<PostOrderInstance> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/assignment/ack_post_order/{shift_id}/{date}/{index}',
            path: {
                'shift_id': shiftId,
                'date': date,
                'index': index,
            },
            query: {
                'customer_id': customerId,
                'task_id': taskId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Clock Out
     * @param shiftId
     * @param date
     * @param index
     * @param officerNotes
     * @param requestBody
     * @returns ShiftInstanceForAssignment Successful Response
     * @throws ApiError
     */
    public static clockOutApiV1AssignmentClockOutShiftIdDateIndexPut(
        shiftId: string,
        date: string,
        index: number,
        officerNotes: (string | null),
        requestBody?: (OfficerLocationCreate | null),
    ): CancelablePromise<ShiftInstanceForAssignment> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/assignment/clock_out/{shift_id}/{date}/{index}',
            path: {
                'shift_id': shiftId,
                'date': date,
                'index': index,
            },
            query: {
                'officer_notes': officerNotes,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Confirm Assignment
     * @param id
     * @returns ShiftInstanceForAssignment Successful Response
     * @throws ApiError
     */
    public static confirmAssignmentApiV1AssignmentConfirmationIdPost(
        id: string,
    ): CancelablePromise<ShiftInstanceForAssignment> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/assignment/confirmation/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Officer Seen Permissions
     * @returns OfficerPermissions Successful Response
     * @throws ApiError
     */
    public static getOfficerSeenPermissionsApiV1OfficerPermissionsGet(): CancelablePromise<OfficerPermissions> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/officer_permissions',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Set Officer Seen Permissions
     * @returns OfficerPermissions Successful Response
     * @throws ApiError
     */
    public static setOfficerSeenPermissionsApiV1OfficerPermissionsPut(): CancelablePromise<OfficerPermissions> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/officer_permissions',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Site Visit Operation For Assignment Customer Id And Task Id
     * @param customerId
     * @param id
     * @param taskId
     * @returns SiteVisitOperationGrouped Successful Response
     * @throws ApiError
     */
    public static readSiteVisitOperationForAssignmentCustomerIdAndTaskIdApiV1EmployeeAssignmentIdSiteCustomerIdGet(
        customerId: string,
        id: string,
        taskId: string,
    ): CancelablePromise<SiteVisitOperationGrouped> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/employee/assignment/{id}/site/{customer_id}',
            path: {
                'customer_id': customerId,
                'id': id,
            },
            query: {
                'task_id': taskId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Shift Attestation
     * @param shiftId
     * @param date
     * @param index
     * @param requestBody
     * @returns ShiftAttestation Successful Response
     * @throws ApiError
     */
    public static updateShiftAttestationApiV1AssignmentShiftAttestationShiftIdDateIndexPut(
        shiftId: string,
        date: string,
        index: number,
        requestBody?: Body_update_shift_attestation_api_v1_assignment_shift_attestation__shift_id___date___index__put,
    ): CancelablePromise<ShiftAttestation> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/assignment/shift_attestation/{shift_id}/{date}/{index}',
            path: {
                'shift_id': shiftId,
                'date': date,
                'index': index,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Clock Out
     * @param shiftId
     * @param date
     * @param index
     * @param officerNotes
     * @param requestBody
     * @returns ShiftInstanceForAssignment Successful Response
     * @throws ApiError
     */
    public static clockOutApiV2AssignmentClockOutShiftIdDateIndexPut(
        shiftId: string,
        date: string,
        index: number,
        officerNotes?: (string | null),
        requestBody?: Body_clock_out_api_v2_assignment_clock_out__shift_id___date___index__put,
    ): CancelablePromise<ShiftInstanceForAssignment> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v2/assignment/clock_out/{shift_id}/{date}/{index}',
            path: {
                'shift_id': shiftId,
                'date': date,
                'index': index,
            },
            query: {
                'officer_notes': officerNotes,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
