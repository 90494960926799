import React, { useCallback, useContext } from 'react'
import { RegionContext } from '../contexts/RegionContext'
import { useCurrentUserRegions } from '../../services/region'

import {
    MenuItem,
    FormControl,
    Select,
    SelectChangeEvent,
    OutlinedInput,
    Divider,
} from '@mui/material'
import { useMatchMutate } from '../../hooks/useMatchMutate'

const ALL_REGIONS = 'ALL_REGIONS'

export const RegionSelect = () => {
    const { regions: currentRegions } = useCurrentUserRegions()
    const matchMutate = useMatchMutate()
    // @ts-ignore
    const { selectedRegionIds, setSelectedRegionIds } =
        useContext(RegionContext)

    const handleRegionChange = useCallback(
        (event: SelectChangeEvent<typeof selectedRegionIds>) => {
            const value = event.target.value.includes(ALL_REGIONS)
                ? []
                : event.target.value
            setSelectedRegionIds(value)
            matchMutate(
                /officer|time_off_requests|time_off|marketplace|schedule|customers|timecards|invoice|belltower|all-activities/,
                undefined,
                true
            )
        },
        [setSelectedRegionIds, matchMutate]
    )

    return (
        <FormControl
            sx={{
                flexBasis: { xs: 'none', sm: 500 },
                ml: { xs: 1, sm: 0 },
            }}
        >
            <Select
                labelId="app-bar-region-label"
                multiple
                displayEmpty
                input={<OutlinedInput size="small" />}
                value={selectedRegionIds}
                onChange={handleRegionChange}
                renderValue={(selected) => {
                    if (
                        selected.length === currentRegions.length ||
                        selected.length === 0
                    ) {
                        return 'All Regions'
                    }

                    return currentRegions
                        .filter(({ id }) => selected.includes(id))
                        .map(({ name }) => name)
                        .join(', ')
                }}
            >
                <MenuItem key={ALL_REGIONS} value={ALL_REGIONS}>
                    All Regions
                </MenuItem>
                <Divider />
                {currentRegions.map((region) => {
                    return (
                        <MenuItem key={region.id} value={region.id}>
                            {region.name}
                        </MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    )
}
