/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { EmployeeProfileUpdate } from './EmployeeProfileUpdate';

export type EmployeeActionEEO1Update = {
    status?: EmployeeActionEEO1Update.status;
    completed_ts?: string;
    last_update_ts?: string;
    form_type?: EmployeeActionEEO1Update.form_type;
    next_actor?: EmployeeActionEEO1Update.next_actor;
    profile: EmployeeProfileUpdate;
};

export namespace EmployeeActionEEO1Update {

    export enum status {
        NOT_STARTED = 'NOT_STARTED',
        IN_PROGRESS = 'IN_PROGRESS',
        SUBMITTED = 'SUBMITTED',
        COMPLETE = 'COMPLETE',
        CANCELLED = 'CANCELLED',
    }

    export enum form_type {
        I9 = 'I9',
        W4 = 'W4',
        WOTC = 'WOTC',
        PROFILE = 'PROFILE',
        AVAILABILITY = 'AVAILABILITY',
        EEO1 = 'EEO1',
        ESIGN = 'ESIGN',
    }

    export enum next_actor {
        EMPLOYEE = 'EMPLOYEE',
        EMPLOYER = 'EMPLOYER',
        EXTERNAL = 'EXTERNAL',
    }


}

