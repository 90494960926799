/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type EmployeeActionUpdate = {
    status?: EmployeeActionUpdate.status;
    completed_ts?: string;
    last_update_ts?: string;
    form_type?: EmployeeActionUpdate.form_type;
    next_actor?: EmployeeActionUpdate.next_actor;
};

export namespace EmployeeActionUpdate {

    export enum status {
        NOT_STARTED = 'NOT_STARTED',
        IN_PROGRESS = 'IN_PROGRESS',
        SUBMITTED = 'SUBMITTED',
        COMPLETE = 'COMPLETE',
        CANCELLED = 'CANCELLED',
    }

    export enum form_type {
        I9 = 'I9',
        W4 = 'W4',
        WOTC = 'WOTC',
        PROFILE = 'PROFILE',
        AVAILABILITY = 'AVAILABILITY',
        EEO1 = 'EEO1',
        ESIGN = 'ESIGN',
    }

    export enum next_actor {
        EMPLOYEE = 'EMPLOYEE',
        EMPLOYER = 'EMPLOYER',
        EXTERNAL = 'EXTERNAL',
    }


}

