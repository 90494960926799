import {
    Stack,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material'
import { useUserSettings } from 'src/services/user_settings'
import { Loading } from '../Loading'
import { NotificationCategory } from 'src/generated'
import { UserNotificationDeliveryType } from 'src/generated'
import { useCallback, useMemo } from 'react'
import { NotInterested } from '@mui/icons-material'

const EMAIL_CATEGORIES: Set<NotificationCategory> = new Set([
    NotificationCategory.ABANDONED_POST,
    NotificationCategory.CLOCKED_OUT_EARLY,
    NotificationCategory.EXPIRED_CERTIFICATIONS,
    NotificationCategory.EXPIRED_LICENSES,
    NotificationCategory.EXPIRING_CERTIFICATIONS,
    NotificationCategory.NEW_INCIDENT,
    NotificationCategory.SHIFT_OFFER_ADMIN,
    NotificationCategory.TIME_OFF_REQUEST_REJECTED,
    NotificationCategory.TIME_OFF_REQUEST,
])

const CATEGORY_DISPLAY_NAME: Record<NotificationCategory, string> = {
    [NotificationCategory.ABANDONED_POST]: 'Abandoned Post',
    [NotificationCategory.CLOCKED_OUT_EARLY]: 'Clocked Out Early',
    [NotificationCategory.CONTRACT_MANAGEMENT]: 'Contract Management',
    [NotificationCategory.CURRENT_SHIFT]: 'Current Shift',
    [NotificationCategory.DISPATCH]: 'Dispatch',
    [NotificationCategory.EXPIRED_CERTIFICATIONS]: 'Expired Certifications',
    [NotificationCategory.EXPIRED_LICENSES]: 'Expired Licenses',
    [NotificationCategory.EXPIRING_CERTIFICATIONS]: 'Expiring Certifications',
    [NotificationCategory.EXPIRING_LICENSES]: 'Expiring Licenses',
    [NotificationCategory.MASS_ANNOUNCEMENT]: 'Mass Announcement',
    [NotificationCategory.MISSED_TOUR]: 'Missed Tour',
    [NotificationCategory.MISSING_CLOCK_IN]: 'Missing Clock In',
    [NotificationCategory.MISSING_OR_MISSED_TOUR]: 'Missing or Missed Tour',
    [NotificationCategory.NEW_INCIDENT]: 'New Incident',
    [NotificationCategory.NEW_NOTICE]: 'New Notice',
    [NotificationCategory.NO_RECENT_LOCATION_ADMIN]:
        'No Recent Location (Supervisor)',
    [NotificationCategory.NO_RECENT_LOCATION]: 'No Recent Location',
    [NotificationCategory.SCHEDULING_OFFICERS]: 'Scheduling Officers',
    [NotificationCategory.SHIFT_OFFER_ADMIN]: 'Shift Offer (Supervisor)',
    [NotificationCategory.SHIFT_OFFER_INTEREST]: 'Shift Offer Interest',
    [NotificationCategory.SHIFT_OFFER]: 'Shift Offer',
    [NotificationCategory.TIME_OFF_REQUEST_REJECTED]:
        'Time Off Request Rejected',
    [NotificationCategory.TIME_OFF_REQUEST]: 'Time Off Request',
}

const DeliveryPreferenceCell: React.FC<{
    enabled: boolean
    updateSetting: (enabled: boolean) => void
    size: NotificationPreferenceSize
}> = ({ enabled, updateSetting, size }) => {
    return (
        <TableCell align="center">
            <Switch
                size={size}
                color="primary"
                checked={enabled}
                onChange={(event) => {
                    updateSetting(event.target.checked)
                }}
            />
        </TableCell>
    )
}

type NotificationPreferenceSize = 'small' | 'medium'

const NotificationPreferenceRow: React.FC<{
    name: NotificationCategory
    deliveryPreferences: Record<UserNotificationDeliveryType, boolean>
    updateDeliverySetting: (
        deliveryType: UserNotificationDeliveryType,
        enabled: boolean
    ) => void
    size: NotificationPreferenceSize
}> = ({ name, deliveryPreferences, updateDeliverySetting, size }) => {
    let emailCell: React.ReactNode = null
    if (EMAIL_CATEGORIES.has(name)) {
        emailCell = (
            <DeliveryPreferenceCell
                enabled={
                    deliveryPreferences[UserNotificationDeliveryType.EMAIL]
                }
                size={size}
                updateSetting={(enabled) =>
                    updateDeliverySetting(
                        UserNotificationDeliveryType.EMAIL,
                        enabled
                    )
                }
            />
        )
    } else {
        emailCell = (
            <TableCell align="center">
                <Stack alignItems="center">
                    <NotInterested
                        sx={{ fontSize: size === 'small' ? 16 : 20 }}
                        color="primary"
                    />
                </Stack>
            </TableCell>
        )
    }

    const variantName = size === 'small' ? 'dashboard' : 'body1'

    return (
        <TableRow>
            <TableCell align="left">
                <Typography variant={variantName}>
                    {CATEGORY_DISPLAY_NAME[name]}
                </Typography>
            </TableCell>
            <DeliveryPreferenceCell
                enabled={
                    deliveryPreferences[UserNotificationDeliveryType.IN_APP]
                }
                size={size}
                updateSetting={(enabled) =>
                    updateDeliverySetting(
                        UserNotificationDeliveryType.IN_APP,
                        enabled
                    )
                }
            />
            <DeliveryPreferenceCell
                enabled={
                    deliveryPreferences[
                        UserNotificationDeliveryType.MOBILE_PUSH
                    ]
                }
                size={size}
                updateSetting={(enabled) =>
                    updateDeliverySetting(
                        UserNotificationDeliveryType.MOBILE_PUSH,
                        enabled
                    )
                }
            />
            {emailCell}
        </TableRow>
    )
}

export const NotificationPreferences: React.FC<{
    size: NotificationPreferenceSize
}> = ({ size }) => {
    const {
        settings,
        isLoading,
        mutations: { updateSettings },
    } = useUserSettings()

    const updateNotificationSettingInCategory = useCallback(
        (
            notificationType: NotificationCategory,
            deliveryType: UserNotificationDeliveryType,
            enabled: boolean
        ) => {
            updateSettings({
                notification_settings_updates: [
                    {
                        notification_type: notificationType,
                        delivery_method: deliveryType,
                        enabled: enabled,
                    },
                ],
            })
        },
        [updateSettings]
    )

    const sortedNotificationSettings = useMemo(() => {
        return settings?.notification_settings.sort((a, b) =>
            a.notification_type.localeCompare(b.notification_type)
        )
    }, [settings])

    // 2D Record of notification category -> delivery type -> enabled
    const notificationSettingsByCategoryAndDeliveryType = useMemo(() => {
        return sortedNotificationSettings?.reduce((acc, setting) => {
            if (!acc[setting.notification_type]) {
                acc[setting.notification_type] = {
                    [UserNotificationDeliveryType.EMAIL]: false,
                    [UserNotificationDeliveryType.IN_APP]: false,
                    [UserNotificationDeliveryType.MOBILE_PUSH]: false,
                    [UserNotificationDeliveryType.WEB_PUSH]: false,
                }
            }
            acc[setting.notification_type][setting.delivery_method] =
                setting.enabled ?? false
            return acc
        }, {} as Record<NotificationCategory, Record<UserNotificationDeliveryType, boolean>>)
    }, [sortedNotificationSettings])

    if (isLoading) {
        return <Loading />
    }

    const headerFontSize = size === 'small' ? '12px' : '16px'

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell align="left">
                        <Typography fontSize={headerFontSize}>
                            Category
                        </Typography>
                    </TableCell>
                    <TableCell align="center" sx={{ minWidth: '75px' }}>
                        <Typography fontSize={headerFontSize}>
                            In App
                        </Typography>
                    </TableCell>
                    <TableCell align="center" sx={{ minWidth: '115px' }}>
                        <Typography fontSize={headerFontSize}>
                            Mobile Push
                        </Typography>
                    </TableCell>
                    <TableCell align="center" sx={{ minWidth: '60px' }}>
                        <Typography fontSize={headerFontSize}>Email</Typography>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {Object.entries(
                    notificationSettingsByCategoryAndDeliveryType ?? {}
                ).map(([notificationType, deliveryPreferences]) => (
                    <NotificationPreferenceRow
                        key={notificationType}
                        name={notificationType as NotificationCategory}
                        deliveryPreferences={deliveryPreferences}
                        updateDeliverySetting={(deliveryType, enabled) =>
                            updateNotificationSettingInCategory(
                                notificationType as NotificationCategory,
                                deliveryType,
                                enabled
                            )
                        }
                        size={size}
                    />
                ))}
            </TableBody>
        </Table>
    )
}
