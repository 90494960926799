import {
    ActionForEmployeeTaskTable,
    EmployeeActionForEmployeePortal,
    File,
    OnboardingAction,
    OnboardingDocumentWithEmployeeAction,
} from 'src/generated'
import { FormTypeType } from 'src/services/onboarding'

// Using union type of both generated status enums
type StatusType =
    | OnboardingAction.status
    | ActionForEmployeeTaskTable.status
    | EmployeeActionForEmployeePortal.status

export const actionStatusToString: Record<StatusType, string> = {
    NOT_STARTED: 'Not Started',
    IN_PROGRESS: 'In Progress',
    SUBMITTED: 'Submitted',
    COMPLETE: 'Complete',
    CANCELLED: 'Cancelled',
}

interface IOnboardingDocumentSignInstance {
    id: string
    employer_signer_complete_ts?: string | null
    employee_signer_complete_ts?: string | null
}

interface IOnboardingDocument {
    files?: Array<File>
    sign_instance?: IOnboardingDocumentSignInstance | null
}

export interface IAction {
    id: string
    form_type?: FormTypeType
    document?: IOnboardingDocument | null
    status?: StatusType
    completed_ts?: string
}
export const getActionName = (action: IAction): string => {
    if (action.form_type === 'I9') return 'I-9 Forms'
    if (action.form_type === 'W4') return 'Tax and Payroll Forms'
    if (action.form_type === 'WOTC') return 'WOTC'
    if (action.form_type === 'EEO1') return 'EEO1'
    if (action.form_type === 'PROFILE') return 'Complete Profile'
    if (action.form_type === 'AVAILABILITY') return 'Availability'

    // Action must be document related
    const documentName = action.document?.files?.[0]?.name || 'Document'
    return `${
        action.form_type === 'ESIGN' ? 'Sign' : 'Acknowledge'
    } ${documentName}`
}

export const getActionStatusString = (action: IAction): string => {
    if (!action.status) return 'Unknown'
    return (action?.status as unknown as StatusType) == 'COMPLETE'
        ? action.document
            ? action.document.sign_instance
                ? 'Signed'
                : 'Acknowledged'
            : actionStatusToString[action.status]
        : actionStatusToString[action.status]
}

export const getShareMethodString = (
    method?: OnboardingDocumentWithEmployeeAction.share_method
) =>
    method === OnboardingDocumentWithEmployeeAction.share_method.ONBOARDING
        ? 'Onboarding'
        : 'Post-Onboarding'
