import { PartialRecord } from 'src/types/utilityTypes'

export const TEST_ORG_DEVO = '01411827-bfe8-4c41-ab44-ec594c4670a8'
export const TEST_ORG_PROD = '01411827-bfe8-4c41-ab44-ec594c4670a8'
export const TRAINING_FIRM_PROD = '2ddf29c6-064f-4d6e-8aad-8f04f0353466'
export const DEMO_ONE_ORG = '87411c28-6965-4a25-bb59-9bd15958850c'
export const DEMO_TWO_ORG = '4e1e458e-155f-450b-aaae-2ebcf613f48e'

const _24_7_SECURITY_PROD = '2240e3ff-d2d0-4152-889a-2bb454de8d29'
const A_AND_A_PROD = '037cf4e7-3815-4c75-99de-1c15c7dc68ad'
const ACE_PRIVATE_SECURITY_PROD = '3fcbce15-ecfa-4a80-8515-3de467ea1633'
const APOLLO_SECURITY_GROUP = 'd4537950-831d-475c-bed7-add517f2407a'
const ARUX_PROD = 'b4c2648d-f8ed-4659-ab88-409b71790958'
const BLUELINE_SECURITY_SERVICES = 'daa13e2a-dded-43d4-a1e5-0c0ed94e1100'
const CENTURION_PROD = '6f55992d-74a4-4ece-a13b-988906750e45'
const COMMAND_PROD = 'deb9c026-7a8d-4644-8730-6acef907ed21'
const CONVOY_GROUP_PROD = '87cfc320-d8df-4bbf-bfdc-8394983504a1'
const COVEY_PROD = '4cc8308b-d375-4569-a6e5-423d563e617c'
const EAGLES_POINT_PROD = 'e1504037-0e93-49df-a248-69863e7ae323'
const ENC_PROTECTION_PROD = '3f89a1f0-409b-4de5-b058-ea658cbf7a4e'
const ENHANCED_PATROL_PROD = 'b11a36e5-81a3-4e12-8841-48e83324f874'
const ETERNAL_SECURITY_PROD = 'c61bb625-4ab5-4325-97de-2130401614a3'
const EXECUTIVE_SENTINELS = 'bd32aefd-5c89-41f9-8e72-e883e9573be5'
const FORTIFIED_INTERNATIONAL = '3b52c8a3-153a-4bb5-b685-25faa23c9f7f'
const FORTIS_SECURITY_PROD = '0979cdc4-eec7-4993-ae8e-7a8127d7e10f'
const FULL_ARMOR_PROTECTION_GROUP = '3ce97afc-72e1-4fff-a552-ef51a385bc84'
const GIS_PROD = 'f1f574a0-c9cc-44fb-978b-27c07cd17d9b'
const GREENLIGHT_PROD = '9729122e-cc3a-4c7f-9e0d-2c9fa81f6d1d'
const GREY_MAN_GROUP = 'c6fbb217-9dca-4090-88f9-c0108bd958b3'
const GUARDIEX_PROD = '2b2553b4-015c-4fe4-add5-5ce283004063'
const HOWE_PROD = '151e893e-218d-43ce-bcfe-6b2b1c77ed25'
const INDUSTRY_PROD = '88b054fa-461a-497c-a087-9194f50a50a6'
const JOHNSON_PROD = '12b30997-7b41-460c-a2c1-b32e5544fdf6'
const KRE_PROD = '0a7cff41-44cc-4df6-a44a-d78818fce83e'
const LINC_RO_PROD = '796f3551-0b02-4223-9eb5-d3ce61b84cfa'
const MARSHALL_SECURITY_LLC_PROD = '211b1ca7-f39d-46c7-9038-30389dc3d287'
const MERCHANTS_PROD = '49b85de6-97fc-4f4a-91da-e608e47d3401'
const MIDDLE_TENNESSEE_TRAFFIC_PROD = 'f8b622b0-2781-47f9-8a88-058ddf94d22c'
const MILLER_PROD = '3435c83c-ef62-4f60-b63d-ce4102cc0ed6'
const MPI_PROTECTIVE_PROD = '7fe2c175-cafb-459e-acad-b5f27cdf8d5d'
const N_TACT_PROD = '01cee0f0-d2a1-427d-bf44-0bffe25a314e'
const NAIAS_PROD = '9f5c6b0d-2d6f-44a0-bd61-7cf08c07f51c'
const NEXT_LEVEL_PROD = '56ae1e15-76af-4f9b-8786-805a9440d9c2'
const NOLA_PROTECTION_PROD = '0c1f5a08-d7e2-4019-a94c-b26b1f1d43a8'
const OMNIUM_PROD = '7d292ee3-194e-4648-8bed-fff67108d179'
const OVERWATCH_PROD = '350b0536-a659-4f8c-8c4c-6b31f804b97c'
const PHOENIX_PROD = 'a3ba24d7-14ad-4386-b76e-381a87366336'
const PM_SECURITY_PROD = 'd6c80629-1cb8-4a50-bf8d-43caabd6b534'
const PRICE_PROTECTIVE_PROD = '1c2708bf-e8ba-4cb8-8425-b44393f324a4'
const PRIME_PROTECTION_PROD = '275e8a06-cc1f-4c43-b017-255bc6bbac2c'
const PRINCIPAL_INTEREST_PROD = '32b91a99-963d-4e4f-89ce-2a5ab2c3843d'
const PRO_SECURITY_PROD = 'f32a0b56-e8ed-4420-8376-677df129bdc9'
const PROFESSIONAL_PROTECTION_PROD = '5201b7f2-ae93-4601-b46b-72d8344bbb0d'
const PUBLIC_SAFETY_SERVICES_LLC = '2ad2c809-a7d6-48b8-a6e1-17e903b62ddd'
const RESTORATION_PROD = '46a7b886-0991-46c6-a7cd-7b72c36e537e'
const RIGHT_ON_TARGET_PROD = '4d3e071b-eab8-4a26-aa5f-4a6584737cb0'
const RMS_PROTECTIVE_PROD = '5cd1186f-fdb1-40e8-a63c-0f74d8b48cbd'
const RS_DEFENSE_PROD = 'ebc87115-2bcd-4ef3-a950-ece44c296a09'
const SALIENT_DEFENSE_PROD = 'd70be0e8-2e9b-493b-9f26-1ee30d6ce69c'
const SALIENT_OPERATIONS_PROD = 'e15341c7-23be-4253-8ca3-f7b722422273'
const SCOUT_SECURITY = 'c2b67761-b722-4728-91ef-fb3a1a29b1aa'
const SEASIDE_PROD = '3f5f6819-c80b-428d-9675-cc23fe861622'
const SECURITY_PRO_INTEL_PROD = '6eba008a-257c-4949-9b04-7ad9b7fe6e5b'
const SEMPER_SECURE_PROD = '078c5ac0-3812-4cd4-a64d-98ce1ba74503'
const SHAW_MANAGEMENT_PROD = '6f2121a9-a4fb-4a97-ae17-0b8c4d28a5d2'
const SHEEPDOG_PROD = 'd708f312-734a-4a78-9e8d-d4f77f24587d'
const SK_SECURITY_PROD = 'ca02e6d0-2ac2-4b82-a8fe-975b29cf9b07'
const SMR_PROD = '456fea8e-7902-4e17-b80d-1b58c5a024f8'
const SOUND_SECURITY_PROD = 'e65dc163-5660-4b4e-97ec-d60fbb2a53ae'
const SOUTHWEST_SECURITY = '9621f1ac-a2eb-4e0a-967b-6204bd2b5b19'
const STARK_SECURITY_PROD = '49801258-bd3e-4aca-99d5-790d03317e75'
const STATEWIDE_PROD = '57dd316f-c1f4-49ea-9497-6bfc662339cd'
const STRATTON_SECURITY = 'fe8d09b5-1521-40db-9576-cc874f393478'
const SUN_CITY_PROD = '6f12a585-dff4-4b3e-8878-b2904eb437db'
const TALON_SECURITY = '30d9f8d6-7232-433e-a326-b10dac01fa8b'
const TASK_FORCE_PROD = '685d87bc-b155-40d4-9203-83fff42bdf26'
const TEXAS_VETERAN_PROD = '3d2645e3-1fae-4b03-b075-98a3ffb6d296'
const TOWER_SECURITY = '5bf9b368-7beb-48b2-b9b3-af73d1bb5484'
const TRESTON_PROD = '4793f28e-8290-4bf7-b1f1-73f3644cdaae'
const TRIAD_PROD = '5f41f619-385d-4a46-be29-c4215e1dbf76'
const TRISKELION_PROD = 'a808ff17-3be1-4fb8-9e89-9c3287a586f2'
const TRT_PROD = '4c3fb0e0-62c8-4f88-b83a-4d7aabb279e2'
const TURNKEY_SECURITY_GROUP = 'f3a2eb90-e3fb-4050-8815-579b1492488e'
const TX_PSI = '72cc3145-bc31-470d-9a50-4f8496492b1e'
const UCE_PROD = 'fb533c8e-9f9c-4034-b6bf-8d6b4bc997a3'
const VARA_GUARD = '98b7e8e4-4c6e-42ec-886f-40b90c772f9f'
const VETERANS_SECURITY_CORP = '5380253f-8b39-4c33-8617-28e86029270a'
const VIPER_SECURITY = 'e42553f2-05ee-4920-b373-44b9af418553'
const VOK = 'e5291db9-0e92-439e-ae74-1255d3501d1d'
const XPRESS_GUARDS = 'f5274693-5434-4420-b593-22e265ab6713'
const ZAHAV_PROD = '47766047-87b8-45c8-b9ef-bb3941c66358'

const BELFRY_ORGS: string[] = [TEST_ORG_DEVO, TEST_ORG_PROD]
const PAYROLL_V2_ORGS: string[] = [DEMO_ONE_ORG]

// Derived from
// select firm.id from firm where id not in (select distinct firm_id from timeoffpolicymapping);
// run on 3/25/2025
// New firms should be added to this list until rollout is complete
const FIRMS_WITH_NO_TIMEOFF_V1_USAGE = [
    '6f91290c-b906-4e48-8072-e01aceb6abfc', // 1984
    '2240e3ff-d2d0-4152-889a-2bb454de8d29', // 24 & 7 Security & Investigations, Inc
    '037cf4e7-3815-4c75-99de-1c15c7dc68ad', // A&A Security Services
    '12eb9a99-210d-4517-93bc-2b7bf17b2118', // American Alliance Security Agency
    '1cb5cc04-f4cc-4f0a-af1d-4944845d88ee', // American Paratus Security Agency (FL)
    'f718faa2-1c13-4d88-9503-99b1583c96b4', // Arc One Protective Services LLC
    '6d070ae9-5dd7-43b0-bcb5-a2994dc808c2', // Arc One Security
    '291dbb05-4f6d-4f9c-99d2-31130952d49d', // Arri Security
    '2a4fac69-1134-489e-b23e-d9713d33ba42', // Axis Defense Group, LLC
    '2ddf29c6-064f-4d6e-8aad-8f04f0353466', // Belfry Training
    '946efb68-4602-47af-8c38-f3d4057959b6', // Black Belt Security & Investigations, LLC
    'fd5ca0a1-a572-4be1-a4ff-9cd495442e4b', // Black Flag Protection Group
    'f866bd81-f8dd-4f57-9dd5-d79cdaa003e1', // Blackwater Public Safety
    '6f8d4476-f5dd-4f44-a229-2befd71ec0ab', // Blindside Protection Agency
    'e32cbe39-8c54-4b3d-bf0f-902b191a5d59', // Blueline Management Services
    '724dfe22-c276-4891-bcad-59467cc58df6', // Bomar Security & Investigation
    '66072840-e5b7-41b9-bd31-421669d26e4b', // CM Security Solutions
    '3b309e5a-e7fe-495a-a6d0-e983ce5da545', // Code 3 Security
    '27636c83-e036-4c42-9fe1-c8215be7638e', // Cornerstone Security
    'b5358852-1c83-4069-9766-a10ae8459584', // David Shield Security
    '87411c28-6965-4a25-bb59-9bd15958850c', // Demo One
    '747d957b-5229-44d9-8261-3c8b9cdfb27b', // Desert Wolf Security
    'd8c4c0da-4d90-48cc-8971-2afbf0e0b5a3', // Dogfood
    '0267602d-a712-491c-aeca-a0f1d7961940', // [Do Not Use] Integrous Risk Solutions LLC
    '4af0a844-22ed-466f-9838-35a8f1a30160', // Edge Security and Investigations
    'b11a36e5-81a3-4e12-8841-48e83324f874', // Enhanced Patrol
    '358c3c98-3e38-4609-baa4-44525aef006c', // Executive Protection Group
    'b38a30e9-c087-414f-b77f-978a6d6bcfcd', // Expro Security
    '53e52327-bbb0-4869-bac7-22816a9e71bb', // FBS Protection Group
    '3e64df0b-737d-4d13-a993-7f78a500deea', // First Protection Services
    '3b52c8a3-153a-4bb5-b685-25faa23c9f7f', // Fortified International
    '0979cdc4-eec7-4993-ae8e-7a8127d7e10f', // Fortis Security Inc.
    '3ce97afc-72e1-4fff-a552-ef51a385bc84', // Full Armor Protection Group
    '4369b0e2-94db-4d1d-a07d-f046a6dc26b3', // Georgetown Protective Services
    'cbecded0-ba08-4beb-8283-f5602906370c', // Global Security Concepts, Inc. - DO NOT USE
    '764bdeef-7001-4429-af7f-98aeacc494b9', // Hammer Head Security
    '724be58b-068d-4da2-b963-f94bcbd4ef59', // iGlobal Security Group
    '12b30997-7b41-460c-a2c1-b32e5544fdf6', // Johnson Security Bureau
    '5b9e9dec-2b70-424c-a40d-d26c69a38b14', // Johnson Security Bureau OLD
    'c1f60556-86e1-40ad-92fb-f88943e6872b', // KellyCorps
    '1a79156d-da4f-4b4b-93d9-fc399827842c', // Kodiak
    '672b16f3-2ec4-4931-b198-6e216d8243f4', // Law Security & Investigations, Inc
    '7883ef4e-a679-4f07-b304-c0e7f91f7aa3', // Leftwich Global Protective Services
    'd56c306d-a121-439e-9307-7767895269dc', // Lone Star Tactical Services
    '007cbfff-5930-4ecb-ad27-28771d4f2433', // Midwest Protection
    'a9fbc603-0ec1-4f9e-92dc-b6343430954c', // Myles Davis Protective Services
    '01cee0f0-d2a1-427d-bf44-0bffe25a314e', // N-Tact
    '350b0536-a659-4f8c-8c4c-6b31f804b97c', // Overwatch Security
    'd8f2c4ca-db66-4699-bbab-8c71faa1a7dc', // Philadelphia Protection
    '1c2708bf-e8ba-4cb8-8425-b44393f324a4', // Price Protective Services
    'bba51b99-4b0d-49d7-bb2c-89b3c4ad0ce3', // Progressive Development Resources
    '2f320586-1525-47dc-85f0-191d28690f90', // Proguard Patrol Services, Inc.
    'c7ac73ad-e907-4206-8548-f3267bfbb299', // Regional Protection
    '0a717136-632c-4cb6-8e3e-882c6492ad9e', // restoration
    '49d8a059-d835-47e1-b9a5-484f48011c57', // Royal Protection & Security
    'ebc87115-2bcd-4ef3-a950-ece44c296a09', // RS Defense Group
    '0a67166f-6429-4a33-a085-7b4a19252b9f', // Rubicon Protection
    '04501958-383f-48a8-bfd9-f6b9f5183403', // SafePro Services
    'e15341c7-23be-4253-8ca3-f7b722422273', // Salient Operations Group
    'c6f7a922-318a-441c-a6ef-748b072eb96b', // seaside
    '3f5f6819-c80b-428d-9675-cc23fe861622', // Seaside Community Development Corporation
    '1e4f1917-15ce-4a32-89e7-911bd4233f52', // Security Corporation of Virginia
    '3a20c5a4-0659-40f7-be70-f78c9d145d9d', // SFI Risk Services
    'e3fc0bc7-c04d-44a8-a23e-1e55421486bc', // Six Point Security
    'fc3b5ef9-3c8e-4f80-a743-2076d3f5392e', // SJ Solutions
    '86edafa0-617c-4fca-8a96-e3297ef46ca3', // SOF Protection Security Agency LLC
    '29d54614-00e2-43c5-a40d-8461b8790504', // Soteria Security Solutions
    '12703349-9202-4788-807b-4d23d7c7879d', // Southern Defense & Security
    'fe8d09b5-1521-40db-9576-cc874f393478', // Stratton Security Group Inc
    '30d9f8d6-7232-433e-a326-b10dac01fa8b', // Talon Security Inc.
    '3d555de5-795e-4ba6-bd8a-b99378b4af35', // TBL Security Services LLC
    'ffedfc43-162b-48da-b116-1efdd321b1c0', // Triton_DO_NOT_USE
    'f3a2eb90-e3fb-4050-8815-579b1492488e', // Turnkey Security Group
    'fb533c8e-9f9c-4034-b6bf-8d6b4bc997a3', // UCE Protection Group
    '92009963-c3b5-4eab-8d65-8253fb326d63', // Veterans Covert Protection Group
    '3adea83b-1237-4e09-a0f9-249073eab9dc', // Washington Professional Security Service
    'a0f25c7e-f569-48dc-b6c7-166a5ecf160d', // W & W Security
]
const FRIENDLY_ORGS: string[] = [
    COMMAND_PROD,
    COVEY_PROD,
    CENTURION_PROD,
    MPI_PROTECTIVE_PROD,
    NEXT_LEVEL_PROD,
    PROFESSIONAL_PROTECTION_PROD,
    SMR_PROD,

    OVERWATCH_PROD,
    GUARDIEX_PROD,
    ETERNAL_SECURITY_PROD,
    CONVOY_GROUP_PROD,
    MERCHANTS_PROD,
    MIDDLE_TENNESSEE_TRAFFIC_PROD,

    ENC_PROTECTION_PROD,
    SEMPER_SECURE_PROD,
    SOUND_SECURITY_PROD,
    RESTORATION_PROD,

    TEXAS_VETERAN_PROD,
    PRIME_PROTECTION_PROD,
    LINC_RO_PROD,
    SEASIDE_PROD,
    TRIAD_PROD,
    ENHANCED_PATROL_PROD,
]

const TIMEKEEPING_V2_FIRMS: string[] = [
    COMMAND_PROD,
    CENTURION_PROD,
    MERCHANTS_PROD,
    COVEY_PROD,
    MPI_PROTECTIVE_PROD,
    PRINCIPAL_INTEREST_PROD,
    ZAHAV_PROD,
    BLUELINE_SECURITY_SERVICES,
    PUBLIC_SAFETY_SERVICES_LLC,
    A_AND_A_PROD,
    HOWE_PROD,
    SUN_CITY_PROD,
    PHOENIX_PROD,
    RMS_PROTECTIVE_PROD,
    _24_7_SECURITY_PROD,
    TASK_FORCE_PROD,
    VETERANS_SECURITY_CORP,
    SMR_PROD,
    STARK_SECURITY_PROD,
    TRT_PROD,
    TRISKELION_PROD,
    NEXT_LEVEL_PROD,
    PROFESSIONAL_PROTECTION_PROD,
    OVERWATCH_PROD,
    GUARDIEX_PROD,
    ETERNAL_SECURITY_PROD,
    CONVOY_GROUP_PROD,
    MIDDLE_TENNESSEE_TRAFFIC_PROD,
    ENC_PROTECTION_PROD,
    SEMPER_SECURE_PROD,
    SOUND_SECURITY_PROD,
    RESTORATION_PROD,
    TEXAS_VETERAN_PROD,
    PRIME_PROTECTION_PROD,
    LINC_RO_PROD,
    SEASIDE_PROD,
    TRIAD_PROD,
    ENHANCED_PATROL_PROD,
    SALIENT_OPERATIONS_PROD,
    SALIENT_DEFENSE_PROD,
    PM_SECURITY_PROD,
    ACE_PRIVATE_SECURITY_PROD,
    APOLLO_SECURITY_GROUP,
    ARUX_PROD,
    EAGLES_POINT_PROD,
    EXECUTIVE_SENTINELS,
    FORTIS_SECURITY_PROD,
    FULL_ARMOR_PROTECTION_GROUP,
    GIS_PROD,
    GREENLIGHT_PROD,
    GREY_MAN_GROUP,
    INDUSTRY_PROD,
    JOHNSON_PROD,
    KRE_PROD,
    MARSHALL_SECURITY_LLC_PROD,
    MILLER_PROD,
    N_TACT_PROD,
    NAIAS_PROD,
    NOLA_PROTECTION_PROD,
    OMNIUM_PROD,
    PRICE_PROTECTIVE_PROD,
    PRO_SECURITY_PROD,
    RIGHT_ON_TARGET_PROD,
    RS_DEFENSE_PROD,
    SCOUT_SECURITY,
    SECURITY_PRO_INTEL_PROD,
    SHAW_MANAGEMENT_PROD,
    SHEEPDOG_PROD,
    SK_SECURITY_PROD,
    SOUTHWEST_SECURITY,
    STATEWIDE_PROD,
    STRATTON_SECURITY,
    TALON_SECURITY,
    TOWER_SECURITY,
    TRESTON_PROD,
    TURNKEY_SECURITY_GROUP,
    TX_PSI,
    UCE_PROD,
    VARA_GUARD,
    VIPER_SECURITY,
    VOK,
    XPRESS_GUARDS,
]

const DEMO_ORGS: string[] = [DEMO_ONE_ORG, DEMO_TWO_ORG]

const IN_HOUSE_NOTIFICATION_FIRMS_BATCH_1: string[] = [
    _24_7_SECURITY_PROD,
    ACE_PRIVATE_SECURITY_PROD,
    A_AND_A_PROD,
    BLUELINE_SECURITY_SERVICES,
    CENTURION_PROD,
    COMMAND_PROD,
    ENC_PROTECTION_PROD,
    ENHANCED_PATROL_PROD,
    ETERNAL_SECURITY_PROD,
    EXECUTIVE_SENTINELS,
    GIS_PROD,
    GUARDIEX_PROD,
    INDUSTRY_PROD,
    JOHNSON_PROD,
    LINC_RO_PROD,
    MIDDLE_TENNESSEE_TRAFFIC_PROD,
    N_TACT_PROD,
    PHOENIX_PROD,
    PRICE_PROTECTIVE_PROD,
    RESTORATION_PROD,
]

const IN_HOUSE_NOTIFICATION_FIRMS_BATCH_2: string[] = [
    APOLLO_SECURITY_GROUP,
    ARUX_PROD,
    CONVOY_GROUP_PROD,
    COVEY_PROD,
    GREENLIGHT_PROD,
    GREY_MAN_GROUP,
    HOWE_PROD,
    KRE_PROD,
    MERCHANTS_PROD,
    MILLER_PROD,
    MPI_PROTECTIVE_PROD,
    NAIAS_PROD,
    NEXT_LEVEL_PROD,
    NOLA_PROTECTION_PROD,
    OMNIUM_PROD,
    OVERWATCH_PROD,
    PM_SECURITY_PROD,
    PRIME_PROTECTION_PROD,
    PRO_SECURITY_PROD,
    PROFESSIONAL_PROTECTION_PROD,
    PUBLIC_SAFETY_SERVICES_LLC,
    RMS_PROTECTIVE_PROD,
    RS_DEFENSE_PROD,
    SALIENT_DEFENSE_PROD,
    SALIENT_OPERATIONS_PROD,
    SCOUT_SECURITY,
    SEASIDE_PROD,
    SECURITY_PRO_INTEL_PROD,
    SEMPER_SECURE_PROD,
    SHAW_MANAGEMENT_PROD,
    SHEEPDOG_PROD,
    SMR_PROD,
    SOUND_SECURITY_PROD,
    SOUTHWEST_SECURITY,
    STARK_SECURITY_PROD,
    STATEWIDE_PROD,
    STRATTON_SECURITY,
    SUN_CITY_PROD,
    TALON_SECURITY,
    TASK_FORCE_PROD,
    TEXAS_VETERAN_PROD,
    TOWER_SECURITY,
    TRIAD_PROD,
    TRISKELION_PROD,
    TRT_PROD,
    TURNKEY_SECURITY_GROUP,
    TX_PSI,
    UCE_PROD,
    VARA_GUARD,
    VETERANS_SECURITY_CORP,
    VIPER_SECURITY,
    VOK,
    XPRESS_GUARDS,
    ZAHAV_PROD,
]

export enum Features {
    WOTC = 'WOTC',
    MASS_ANNOUNCEMENTS = 'MASS_ANNOUNCEMENTS',
    AD_HOC_INV_START_BYPASS = 'AD_HOC_INV_START_BYPASS',
    TIME_OFF_SPBS = 'TIME_OFF_SPBS',
    TIMEKEEPING_V2 = 'TIMEKEEPING_V2',
    WAGE_BASED_TIMEKEEPING = 'WAGE_BASED_TIMEKEEPING',
    PAYROLL_V2 = 'PAYROLL_V2',
    PAYROLL_V2_SIDE_BY_SIDE = 'PAYROLL_V2_SIDE_BY_SIDE',
    CONTRACTOR_DEDUCTIONS = 'CONTRACTOR_DEDUCTIONS',
    ACCOUNTS_PAYABLE = 'ACCOUNTS_PAYABLE',
    ACCOUNTS_RECEIVABLE = 'ACCOUNTS_RECEIVABLE',
    ACCOUNTS_RECEIVABLE_EDITABLE_PAYMENTS = 'ACCOUNTS_RECEIVABLE_EDITABLE_PAYMENTS',
    ACCOUNTS_RECEIVABLE_FIRST_CLASS_LINE_ITEMS = 'ACCOUNTS_RECEIVABLE_FIRST_CLASS_LINE_ITEMS',
    TIME_OFF_JOURNAL = 'TIME_OFF_JOURNAL',
    TIME_OFF_POLICY_PREVIEW = 'TIME_OFF_POLICY_PREVIEW',
    INVENTORY = 'INVENTORY',
    DISPATCH = 'DISPATCH',
    // TODO(rdylan): Remove after soak time of ~2 weeks (2024-07-14)
    POSTORDER_API_V2 = 'POSTORDER_API_V2',
    MFA = 'MFA',
    STRICT_INVOICE_STATUSES = 'STRICT_INVOICE_STATUSES',
    UPLOAD_OPTIMIZATION = 'UPLOAD_OPTIMIZATION',
    SHIFT_ATTESTATION = 'SHIFT_ATTESTATION',
    ESIGN_DOCUMENTS = 'ESIGN_DOCUMENTS',
    SHIFT_CONFIRMATION = 'SHIFT_CONFIRMATION',
}
export type FeatureGate = PartialRecord<Features, string[]>
export const FEATURE_GATE: Record<Features, string[]> = {
    [Features.WOTC]: [...BELFRY_ORGS, STARK_SECURITY_PROD, ...DEMO_ORGS],
    [Features.MASS_ANNOUNCEMENTS]: [...BELFRY_ORGS, ...DEMO_ORGS],
    [Features.AD_HOC_INV_START_BYPASS]: [
        ...BELFRY_ORGS,
        SUN_CITY_PROD,
        A_AND_A_PROD,
        ...DEMO_ORGS,
    ],
    [Features.TIME_OFF_SPBS]: [...BELFRY_ORGS, ...DEMO_ORGS],
    [Features.TIMEKEEPING_V2]: [...BELFRY_ORGS, ...TIMEKEEPING_V2_FIRMS],
    [Features.TIME_OFF_JOURNAL]: [
        ...BELFRY_ORGS,
        ...FIRMS_WITH_NO_TIMEOFF_V1_USAGE,
        TRESTON_PROD,
        STARK_SECURITY_PROD,
        TRT_PROD,
        TRISKELION_PROD,
    ],
    [Features.TIME_OFF_POLICY_PREVIEW]: [
        ...BELFRY_ORGS,
        ...FIRMS_WITH_NO_TIMEOFF_V1_USAGE,
        TRESTON_PROD,
        STARK_SECURITY_PROD,
        TRT_PROD,
        TRISKELION_PROD,
    ],
    [Features.WAGE_BASED_TIMEKEEPING]: [] as string[],
    // Organization in this flag get full Payroll V2 experience without any
    // references to V1.
    [Features.PAYROLL_V2]: [...PAYROLL_V2_ORGS],
    // Similar to SHADOW option, with once exception that client is
    // able to see both version of payroll. This is meant for TEST orgs only,
    // to be able to compare payrolls side-by-side on the screen. PLEASE
    // YELL IF ANY ORG OTHER THAN TEST FIRMS IS ADDED!!!!!!!!
    [Features.PAYROLL_V2_SIDE_BY_SIDE]: [...PAYROLL_V2_ORGS, TEST_ORG_PROD],
    [Features.CONTRACTOR_DEDUCTIONS]: [...PAYROLL_V2_ORGS, TEST_ORG_PROD],
    [Features.ACCOUNTS_PAYABLE]: [...BELFRY_ORGS, ...DEMO_ORGS],
    [Features.ACCOUNTS_RECEIVABLE]: [...BELFRY_ORGS],
    // This is being rolled out to all firms. Remove this when all is good and ready to remediate.
    [Features.ACCOUNTS_RECEIVABLE_EDITABLE_PAYMENTS]: [
        ...BELFRY_ORGS,
        ...FRIENDLY_ORGS,
    ],
    [Features.ACCOUNTS_RECEIVABLE_FIRST_CLASS_LINE_ITEMS]: [],
    [Features.INVENTORY]: [
        ...BELFRY_ORGS,
        ...DEMO_ORGS,
        RS_DEFENSE_PROD,
        RIGHT_ON_TARGET_PROD,
        TEXAS_VETERAN_PROD,
        COVEY_PROD,
        EAGLES_POINT_PROD,
    ],
    [Features.DISPATCH]: [
        ...BELFRY_ORGS,
        ...DEMO_ORGS,
        APOLLO_SECURITY_GROUP,
        EXECUTIVE_SENTINELS,
        FORTIFIED_INTERNATIONAL,
        FULL_ARMOR_PROTECTION_GROUP,
        MPI_PROTECTIVE_PROD,
        SK_SECURITY_PROD,
        TASK_FORCE_PROD,
    ],
    [Features.POSTORDER_API_V2]: [...BELFRY_ORGS, ...DEMO_ORGS],
    [Features.MFA]: [...BELFRY_ORGS, ...DEMO_ORGS],
    [Features.STRICT_INVOICE_STATUSES]: [
        ...BELFRY_ORGS,
        ...FRIENDLY_ORGS,
        A_AND_A_PROD,
        HOWE_PROD,
        SUN_CITY_PROD,
        PHOENIX_PROD,
        RMS_PROTECTIVE_PROD,
        _24_7_SECURITY_PROD,
        TASK_FORCE_PROD,
        VETERANS_SECURITY_CORP,
        GIS_PROD,
    ],
    [Features.UPLOAD_OPTIMIZATION]: [] as string[],
    [Features.SHIFT_ATTESTATION]: [...BELFRY_ORGS],
    [Features.ESIGN_DOCUMENTS]: [...BELFRY_ORGS],
    [Features.SHIFT_CONFIRMATION]: [
        ...BELFRY_ORGS,
        PROFESSIONAL_PROTECTION_PROD,
    ],
}

export const FEATURED_ROLLOUT_TO_ALL_FIRMS: PartialRecord<Features, boolean> = {
    [Features.UPLOAD_OPTIMIZATION]: true,
}

export const INTERNAL_USERS = [
    // 'auth0|62cb212df2c735713aef0f35', // Alex
    'auth0|62eab5b3216efec017dea581', // hello@
    'auth0|6532aed6e475da095b945e65', // hello1@
    'auth0|6573a359bf8a286ca6ff8b3d', // hello2@
    'auth0|65b41d4375425212b4ea1597', // hello3@
    'auth0|661554a7aa70f7f377dc7905', // hello4
    'auth0|669a9029ceb2563e0da12754', // hello5
    'auth0|66db5ab675b7a20957387178', // hello6
]

export const INTERNAL_FIRMS = [
    '01411827-bfe8-4c41-ab44-ec594c4670a8', // BelfryTest
    'a0d9a84e-2c83-4450-8e06-a399f5716099', // Dev
    '87411c28-6965-4a25-bb59-9bd15958850c', // Demo One
    '4e1e458e-155f-450b-aaae-2ebcf613f48e', // Demo Two
    '2ddf29c6-064f-4d6e-8aad-8f04f0353466', // Belfry Training
]
