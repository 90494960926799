/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ShiftAttestationResponse = {
    question_type: ShiftAttestationResponse.question_type;
    confirmed: boolean;
    explanation?: string;
};

export namespace ShiftAttestationResponse {

    export enum question_type {
        BREAKS_PROVIDED = 'BREAKS_PROVIDED',
        BREAKS_TAKEN = 'BREAKS_TAKEN',
        TIMESHEET_ACCURATE = 'TIMESHEET_ACCURATE',
    }


}

