/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_clock_out_api_v2_assignment_clock_out__shift_id___date___index__put } from '../models/Body_clock_out_api_v2_assignment_clock_out__shift_id___date___index__put';
import type { ReliefOfficer } from '../models/ReliefOfficer';
import type { ShiftInstanceForAssignment } from '../models/ShiftInstanceForAssignment';
import type { ShiftInstanceForOfficerSchedule } from '../models/ShiftInstanceForOfficerSchedule';
import type { ShiftOfferInterestCreate } from '../models/ShiftOfferInterestCreate';
import type { ShiftOfferInterestUpdate } from '../models/ShiftOfferInterestUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AssignmentsService {

    /**
     * Get Upcoming Assignments
     * Returns the published shift instances assigned to the officer to the provided date range.
     * @returns ShiftInstanceForOfficerSchedule Successful Response
     * @throws ApiError
     */
    public static getUpcomingAssignmentsApiV1AssignmentGet(): CancelablePromise<Array<ShiftInstanceForOfficerSchedule>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/assignment',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Open Assignments
     * @returns ShiftInstanceForOfficerSchedule Successful Response
     * @throws ApiError
     */
    public static getOpenAssignmentsApiV1AssignmentsOpenGet(): CancelablePromise<Array<ShiftInstanceForOfficerSchedule>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/assignments/open',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Officers To Relieve For Assignment
     * @param id
     * @returns ReliefOfficer Successful Response
     * @throws ApiError
     */
    public static getOfficersToRelieveForAssignmentApiV1AssignmentIdOfficersToRelieveGet(
        id: string,
    ): CancelablePromise<Array<ReliefOfficer>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/assignment/{id}/officers-to-relieve',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Officers Relieving The Assignment
     * @param id
     * @returns ReliefOfficer Successful Response
     * @throws ApiError
     */
    public static getOfficersRelievingTheAssignmentApiV1AssignmentIdRelievingOfficersGet(
        id: string,
    ): CancelablePromise<Array<ReliefOfficer>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/assignment/{id}/relieving-officers',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Show Interest In Assignment
     * @param id
     * @param requestBody
     * @returns string Successful Response
     * @throws ApiError
     */
    public static showInterestInAssignmentApiV1AssignmentShowInterestIdPost(
        id: string,
        requestBody: (ShiftOfferInterestCreate | ShiftOfferInterestUpdate),
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/assignment/show-interest/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Claim Assignment
     * @param id
     * @returns string Successful Response
     * @throws ApiError
     */
    public static claimAssignmentApiV1AssignmentClaimIdPost(
        id: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/assignment/claim/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Confirm Assignment
     * @param id
     * @returns ShiftInstanceForAssignment Successful Response
     * @throws ApiError
     */
    public static confirmAssignmentApiV1AssignmentConfirmationIdPost(
        id: string,
    ): CancelablePromise<ShiftInstanceForAssignment> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/assignment/confirmation/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Clock Out
     * @param shiftId
     * @param date
     * @param index
     * @param officerNotes
     * @param requestBody
     * @returns ShiftInstanceForAssignment Successful Response
     * @throws ApiError
     */
    public static clockOutApiV2AssignmentClockOutShiftIdDateIndexPut(
        shiftId: string,
        date: string,
        index: number,
        officerNotes?: (string | null),
        requestBody?: Body_clock_out_api_v2_assignment_clock_out__shift_id___date___index__put,
    ): CancelablePromise<ShiftInstanceForAssignment> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v2/assignment/clock_out/{shift_id}/{date}/{index}',
            path: {
                'shift_id': shiftId,
                'date': date,
                'index': index,
            },
            query: {
                'officer_notes': officerNotes,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
