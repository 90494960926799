import React from 'react'
import { Officer } from '../../generated'
import {
    Button,
    Grid,
    Typography,
    ButtonGroup,
    Box,
    Alert,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    Select,
    MenuItem,
    InputLabel,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
} from '@mui/material'
import { restoreOfficer } from '../../services/officer'
import { Loading } from '../Loading'
import { useSWRConfig } from 'swr'
import { ApiError } from '../../generated'
import { toast } from 'react-toastify'
import { ContentModal } from '../Modal'
import { PrimaryWorkplacePicker } from '../Officer/Payroll/PrimaryWorkplacePicker'
import { useLoadOnboardingTemplates } from 'src/services/onboarding'
import { Assignment as TaskIcon } from '@mui/icons-material'
import { getActionName } from 'src/utils/onboarding'

type RestoreModalProps = {
    open: boolean
    handleClose: () => void
    officer: Officer
    officerId: string
}

type OnboardingOption = 'restore' | 'create' | 'none'

export const RestoreModal = ({
    open,
    handleClose,
    officer,
    officerId,
}: RestoreModalProps) => {
    const hasPrimaryWorkplace = officer.has_primary_workplace
    const { mutate } = useSWRConfig()
    const [submitting, setIsSubmitting] = React.useState<boolean>(false)
    const [primaryCustomerWorkplace, setPrimaryCustomerWorkplace] =
        React.useState<string | undefined>(undefined)
    const [primaryNonCustomerWorkplace, setPrimaryNonCustomerWorkplace] =
        React.useState<string | undefined>(undefined)
    const [restoreOnboardingTasks, setRestoreOnboardingTasks] =
        React.useState<boolean>(false)
    const [newOnboardingTemplateId, setNewOnboardingTemplateId] =
        React.useState<string | null>(null)
    const [onboardingOption, setOnboardingOption] =
        React.useState<OnboardingOption>('none')

    const { templates } = useLoadOnboardingTemplates()

    const restoreOfficerCallback = React.useCallback(async () => {
        setIsSubmitting(true)
        return restoreOfficer(
            officerId,
            primaryCustomerWorkplace,
            primaryNonCustomerWorkplace,
            {
                restore_existing_onboarding_tasks: restoreOnboardingTasks,
                onboarding_template_id: newOnboardingTemplateId,
            },
            mutate
        )
            .then((value: any) => {
                setIsSubmitting(false)
                handleClose()
            })
            .catch((error: ApiError) => {
                toast.error(error?.message)
                setIsSubmitting(false)
            })
    }, [
        primaryCustomerWorkplace,
        primaryNonCustomerWorkplace,
        handleClose,
        mutate,
        officerId,
        restoreOnboardingTasks,
        newOnboardingTemplateId,
    ])

    const onPrimaryWorkplaceSaveClick = (update: Officer) => {
        setPrimaryCustomerWorkplace(
            update.payroll_primary_workplace_customer_id ?? undefined
        )
        setPrimaryNonCustomerWorkplace(
            update.payroll_primary_workplace_non_customer_id ?? undefined
        )
    }

    const handleOnboardingOptionChange = (value: OnboardingOption) => {
        setOnboardingOption(value)
        switch (value) {
            case 'restore':
                setRestoreOnboardingTasks(true)
                setNewOnboardingTemplateId(null)
                break
            case 'create':
                setRestoreOnboardingTasks(false)
                setNewOnboardingTemplateId(templates?.[0]?.id ?? null)
                break
            case 'none':
                setRestoreOnboardingTasks(false)
                setNewOnboardingTemplateId(null)
                break
        }
    }

    const renderExistingTasks = React.useCallback(() => {
        if (!officer.onboarding_status?.actions?.length) {
            return (
                <Typography color="text.secondary" sx={{ mt: 1 }}>
                    No existing onboarding tasks found
                </Typography>
            )
        }

        return (
            <Box border={1} borderColor="divider" borderRadius={1} p={1} m={1}>
                <div
                    style={{
                        maxHeight: 300,
                        overflow: 'auto',
                        paddingRight: 2,
                    }}
                >
                    <List dense sx={{ p: 0 }}>
                        {officer.onboarding_status.actions.map((action) => (
                            <ListItem key={action.id}>
                                <ListItemIcon>
                                    <TaskIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText
                                    primary={getActionName(action)}
                                    secondary={
                                        action.status === 'COMPLETE'
                                            ? 'Completed'
                                            : 'Will be restored'
                                    }
                                />
                            </ListItem>
                        ))}
                    </List>
                </div>
            </Box>
        )
    }, [officer.onboarding_status?.actions])

    return (
        <ContentModal isOpen={open} handleClose={handleClose}>
            {submitting && <Loading style={{ minHeight: undefined }} />}
            {!submitting && (
                <Grid container direction="column" spacing={3}>
                    <Grid item>
                        <Typography variant="h2">Restore Worker</Typography>
                        <Typography variant="body1">
                            This will restore {officer.name} to no longer be
                            terminated.
                        </Typography>
                    </Grid>
                    {!hasPrimaryWorkplace && (
                        <Box p={1} px={3}>
                            {!primaryCustomerWorkplace &&
                                !primaryNonCustomerWorkplace && (
                                    <Alert severity="error">
                                        Sorry, you cannot restore {officer.name}{' '}
                                        without first choosing a primary
                                        workplace.
                                    </Alert>
                                )}
                            <PrimaryWorkplacePicker
                                officer={officer}
                                saveOfficer={onPrimaryWorkplaceSaveClick}
                            />
                        </Box>
                    )}

                    <Grid item>
                        <FormControl component="fieldset">
                            <Typography variant="subtitle1" gutterBottom>
                                Onboarding Options
                            </Typography>
                            <RadioGroup
                                value={onboardingOption}
                                onChange={(e) =>
                                    handleOnboardingOptionChange(
                                        e.target.value as OnboardingOption
                                    )
                                }
                            >
                                {!['COMPLETE', 'SUBMITTED'].includes(
                                    officer.onboarding_status?.status ?? ''
                                ) && (
                                    <FormControlLabel
                                        value="restore"
                                        control={<Radio />}
                                        label="Restore existing officer onboarding"
                                    />
                                )}
                                {onboardingOption === 'restore' &&
                                    renderExistingTasks()}

                                <FormControlLabel
                                    value="create"
                                    control={<Radio />}
                                    label="Create a new officer onboarding"
                                />
                                {onboardingOption === 'create' && templates && (
                                    <FormControl fullWidth margin="normal">
                                        <InputLabel>
                                            Onboarding Template
                                        </InputLabel>
                                        <Select
                                            value={
                                                newOnboardingTemplateId || ''
                                            }
                                            onChange={(e) =>
                                                setNewOnboardingTemplateId(
                                                    e.target.value
                                                )
                                            }
                                            label="Onboarding Template"
                                            size="small"
                                        >
                                            {templates.map((template) => (
                                                <MenuItem
                                                    key={template.id}
                                                    value={template.id}
                                                >
                                                    {template.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                )}

                                <FormControlLabel
                                    value="none"
                                    control={<Radio />}
                                    label="Do nothing"
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>

                    <Grid item marginTop={3}>
                        <ButtonGroup fullWidth>
                            <Button onClick={handleClose}>Cancel</Button>
                            <Button
                                variant="contained"
                                color="success"
                                disabled={
                                    !hasPrimaryWorkplace &&
                                    !primaryCustomerWorkplace &&
                                    !primaryNonCustomerWorkplace
                                }
                                onClick={() => restoreOfficerCallback()}
                            >
                                Restore worker
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
            )}
        </ContentModal>
    )
}

type OfficerRestoreButtonProps = {
    officer: Officer | undefined
    id: string
    isTerminationAndPayrollPrivileged: boolean
}
export function OfficerRestoreButton({
    officer,
    id,
    isTerminationAndPayrollPrivileged,
}: OfficerRestoreButtonProps) {
    const [open, setOpen] = React.useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)
    return (
        <>
            {officer && (
                <RestoreModal
                    open={open}
                    handleClose={handleClose}
                    officer={officer}
                    officerId={id}
                />
            )}
            <Button
                variant="text"
                color="primary"
                onClick={handleOpen}
                disabled={!isTerminationAndPayrollPrivileged || !officer}
                fullWidth
            >
                Restore Worker
            </Button>
        </>
    )
}
