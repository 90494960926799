/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ReceivableLineItem } from '../models/ReceivableLineItem';
import type { ReceivableLineItemCreate } from '../models/ReceivableLineItemCreate';
import type { ReceivableLineItemUpdate } from '../models/ReceivableLineItemUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ReceivableLineItemService {

    /**
     * Create Receivable Line Item Endpoint
     * Create a new receivable line item.
     *
     * Args:
     * line_item_data (schemas.ReceivableLineItemCreate): Line item details to create.
     * db (AsyncSession): Database session.
     *
     * Returns:
     * schemas.ReceivableLineItem: The created line item object.
     * @param requestBody
     * @returns ReceivableLineItem Successful Response
     * @throws ApiError
     */
    public static createReceivableLineItemEndpointApiV1AccountsreceivableLineItemPost(
        requestBody: ReceivableLineItemCreate,
    ): CancelablePromise<ReceivableLineItem> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/accountsreceivable/line_item',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Receivable Line Item Current Version
     * Get the current version of a receivable line item by ID.
     *
     * Args:
     * line_item_id (UUID): Unique identifier of the line item.
     * db (AsyncSession): Database session.
     *
     * Returns:
     * schemas.ReceivableLineItem | None: The line item object, or None if not found.
     * @param lineItemId
     * @returns ReceivableLineItem Successful Response
     * @throws ApiError
     */
    public static readReceivableLineItemCurrentVersionApiV1AccountsreceivableLineItemLineItemIdGet(
        lineItemId: string,
    ): CancelablePromise<ReceivableLineItem> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/accountsreceivable/line_item/{line_item_id}',
            path: {
                'line_item_id': lineItemId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Receivable Line Item Endpoint
     * Update receivable line item by ID.
     *
     * Args:
     * line_item_id (UUID): Unique identifier of the line item.
     * line_item_update (schemas.ReceivableLineItemUpdate): Updated line item details.
     * db (AsyncSession): Database session.
     *
     * Returns:
     * schemas.ReceivableLineItem: The updated line item object.
     * @param lineItemId
     * @param requestBody
     * @returns ReceivableLineItem Successful Response
     * @throws ApiError
     */
    public static updateReceivableLineItemEndpointApiV1AccountsreceivableLineItemLineItemIdPatch(
        lineItemId: string,
        requestBody: ReceivableLineItemUpdate,
    ): CancelablePromise<ReceivableLineItem> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/accountsreceivable/line_item/{line_item_id}',
            path: {
                'line_item_id': lineItemId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Receivable Line Items
     * List all receivable line items.
     *
     * Args:
     * db (AsyncSession): Database session.
     *
     * Returns:
     * Sequence[ReceivableLineItem]: A sequence of line item objects.
     * @returns ReceivableLineItem Successful Response
     * @throws ApiError
     */
    public static readReceivableLineItemsApiV1AccountsreceivableLineItemsGet(): CancelablePromise<Array<ReceivableLineItem>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/accountsreceivable/line_items',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Receivable Line Items For Invoice
     * List all receivable line items for an invoice.
     * @param invoiceId
     * @returns ReceivableLineItem Successful Response
     * @throws ApiError
     */
    public static readReceivableLineItemsForInvoiceApiV1AccountsreceivableLineItemsInvoiceInvoiceIdGet(
        invoiceId: string,
    ): CancelablePromise<Array<ReceivableLineItem>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/accountsreceivable/line_items/invoice/{invoice_id}',
            path: {
                'invoice_id': invoiceId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Receivable Line Item Version History
     * List all versions of a receivable line item by ID.
     * @param lineItemId
     * @returns ReceivableLineItem Successful Response
     * @throws ApiError
     */
    public static readReceivableLineItemVersionHistoryApiV1AccountsreceivableLineItemsVersionHistoryLineItemIdGet(
        lineItemId: string,
    ): CancelablePromise<Array<ReceivableLineItem>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/accountsreceivable/line_items/version-history/{line_item_id}',
            path: {
                'line_item_id': lineItemId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
