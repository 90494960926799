import {
    UserSettingsWithNotificationSettingsV2,
    UserSettingsService,
    UserSettingsAPIUpdateV2,
} from 'src/generated'
import { useCallback } from 'react'
import useSWRImmutable from 'swr/immutable'

type UserSettingsType = {
    settings?: UserSettingsWithNotificationSettingsV2
    isLoading: boolean
    isError: boolean
    mutations: {
        updateSettings: UpdateSettingsFunc
    }
}

type UpdateSettingsFunc = (settings: UserSettingsAPIUpdateV2) => Promise<void>

type GetUserSettingsFunc = () => UserSettingsType
export const useUserSettings: GetUserSettingsFunc = () => {
    const { data, error, mutate, isLoading } = useSWRImmutable(
        `/user_settings`,
        UserSettingsService.readUserSettingsApiV2UserSettingsGet,
        {
            // refresh every 1 min
            refreshInterval: 1000 * 60,
        }
    )

    const updateSettings = useCallback(
        async (settings: UserSettingsAPIUpdateV2) => {
            const optimisticData = (
                current: UserSettingsWithNotificationSettingsV2
            ) => {
                for (const update of settings.notification_settings_updates) {
                    const existing = current.notification_settings.find(
                        (setting) =>
                            setting.notification_type ===
                                update.notification_type &&
                            setting.delivery_method === update.delivery_method
                    )
                    if (existing) {
                        existing.enabled = update.enabled
                    }
                }
                return current
            }

            mutate(
                UserSettingsService.updateUserSettingsApiV2UserSettingsPut(
                    settings
                ),
                {
                    optimisticData: data ? optimisticData(data) : undefined,
                    revalidate: false,
                }
            )
        },
        [mutate, data]
    )

    return {
        settings: data,
        isLoading: isLoading,
        isError: error,
        mutations: {
            updateSettings: updateSettings,
        },
    }
}
