/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_update_shift_attestation_api_v1_assignment_shift_attestation__shift_id___date___index__put } from '../models/Body_update_shift_attestation_api_v1_assignment_shift_attestation__shift_id___date___index__put';
import type { ShiftAttestation } from '../models/ShiftAttestation';
import type { ShiftAttestationSettings } from '../models/ShiftAttestationSettings';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ShiftAttestationService {

    /**
     * Update Shift Attestation
     * @param shiftId
     * @param date
     * @param index
     * @param requestBody
     * @returns ShiftAttestation Successful Response
     * @throws ApiError
     */
    public static updateShiftAttestationApiV1AssignmentShiftAttestationShiftIdDateIndexPut(
        shiftId: string,
        date: string,
        index: number,
        requestBody?: Body_update_shift_attestation_api_v1_assignment_shift_attestation__shift_id___date___index__put,
    ): CancelablePromise<ShiftAttestation> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/assignment/shift_attestation/{shift_id}/{date}/{index}',
            path: {
                'shift_id': shiftId,
                'date': date,
                'index': index,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Shift Attestation Settings
     * @returns ShiftAttestationSettings Successful Response
     * @throws ApiError
     */
    public static getShiftAttestationSettingsApiV1ShiftAttestationSettingsGet(): CancelablePromise<ShiftAttestationSettings> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/shift-attestation/settings',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Shift Attestation Settings
     * @param requestBody
     * @returns ShiftAttestationSettings Successful Response
     * @throws ApiError
     */
    public static updateShiftAttestationSettingsApiV1ShiftAttestationSettingsPut(
        requestBody: ShiftAttestationSettings,
    ): CancelablePromise<ShiftAttestationSettings> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/shift-attestation/settings',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Export Shift Attestation
     * @param startDate
     * @param endDate
     * @param customerIds
     * @param officerIds
     * @param omitContactInfo
     * @returns any Successful Response
     * @throws ApiError
     */
    public static exportShiftAttestationApiV1ShiftAttestationExportGet(
        startDate: string,
        endDate: string,
        customerIds?: (Array<string> | null),
        officerIds?: (Array<string> | null),
        omitContactInfo?: (boolean | null),
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/shift-attestation/export',
            query: {
                'start_date': startDate,
                'end_date': endDate,
                'customer_ids': customerIds,
                'officer_ids': officerIds,
                'omit_contact_info': omitContactInfo,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
