import React, { useState } from 'react'

import {
    Box,
    Stack,
    styled,
    Tab,
    Tabs,
    Typography,
    Menu,
    MenuItem,
    useTheme,
} from '@mui/material'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { DetailsTab } from '../../types/detailsTab'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'

const colorBlack = '#000000'

// Styled component for the tab text, with custom font weight and padding
const TabText = styled(Typography)({
    fontWeight: 600,
    paddingTop: '12px',
    fontSize: '16px',
})

// Styled component for the tab, with custom text transformation and hover effect
const StyledTab = styled(Tab)(({ theme }) => ({
    textTransform: 'capitalize',
    color: theme.palette.inactive.main,
    opacity: 1,
    paddingTop: '4px',
    whiteSpace: 'nowrap',
    '&:hover': {
        color: colorBlack,
    },
}))

// Styled component for dropdown menu items to match tab styling
const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    textTransform: 'capitalize',
    color: theme.palette.inactive.main,
    '&:hover': {
        color: colorBlack,
    },
}))

// Function to parse the active tab by removing the query string from the URL
function removeQueryString(url: string) {
    return url.split('?')[0]
}

// Style object for hiding elements on mobile devices, used for responsive design
const hideOnMobileSx = { display: { xs: 'none', sm: 'flex' } }

// Styled wrapper component to hold the tabs and optional content
export const BelfryTabContainer: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    const theme = useTheme()
    return (
        <Stack
            borderBottom={`1px solid ${theme.palette.inactive.main}`}
            flexDirection="row"
            justifyContent="space-between"
            width="100%"
            maxWidth="100vw"
            marginBottom={{ xs: 0, sm: 1 }}
        >
            {children}
        </Stack>
    )
}

// Type definition for the props used in the DetailTabs component
type DetailTabProps = {
    data: (DetailsTab | null)[] // List of tabs to render; determines tab labels, links, and optional extra content
    variant?: 'fullWidth' | 'standard' | 'scrollable' | undefined // Controls the visual layout and scroll behavior of the tabs
    extraTabEnd?: React.ReactNode // Optional element rendered at the end of the tab list (e.g., buttons or icons)
    manualTabIndex?: number // Forces a specific tab to be selected, overriding automatic URL-based detection
    maxVisibleTabs?: number // Optional limit on the number of tabs to display; excess tabs will be placed in a dropdown menu
}

/**
 * RenderedTabs Component
 *
 * Handles the rendering of both visible tabs and the dropdown menu for overflow tabs.
 * The component manages two main sections:
 * 1. Main tab bar: Shows the first N tabs (where N is maxVisibleTabs)
 * 2. Dropdown menu: Contains any additional tabs beyond maxVisibleTabs
 *
 * Features:
 * - Maintains consistent styling between main tabs and dropdown items
 * - Handles active state highlighting for both visible and dropdown tabs
 * - Supports optional extra content (icons, badges, etc.) in tab labels
 * - Manages dropdown menu state and interactions
 */
const RenderedTabs: React.FC<{
    data: (DetailsTab | null)[]
    activeTab: number
    maxVisibleTabs?: number
    variant?: 'fullWidth' | 'standard' | 'scrollable' | undefined
}> = ({ data, activeTab, maxVisibleTabs, variant = 'scrollable' }) => {
    const theme = useTheme()
    // State for managing the dropdown menu's anchor element
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    // Event handlers for dropdown menu interactions
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    // Determine which tabs to show in the main tab bar
    // If maxVisibleTabs is set, only show that many tabs; otherwise show all
    const tabsToRender = maxVisibleTabs ? data.slice(0, maxVisibleTabs) : data

    return (
        <Tabs value={activeTab} variant={variant}>
            {/* Main Tab Bar Section */}
            {tabsToRender.map((tab, index) => {
                if (!tab) return null
                // Apply active tab styling if this tab is currently selected
                const sx =
                    index === activeTab
                        ? { color: theme.palette.primary.main }
                        : {}
                return (
                    <Link
                        href={tab.link}
                        passHref
                        key={tab.name}
                        legacyBehavior
                    >
                        <StyledTab
                            theme={theme}
                            wrapped
                            label={
                                <Box display="flex" flexDirection="row">
                                    <TabText sx={sx}>{tab.name}</TabText>
                                    {/* Optional extra content (icons, badges, etc.) */}
                                    {tab.extra ? (
                                        <Box
                                            paddingLeft={0.5}
                                            display="flex"
                                            alignItems="end"
                                        >
                                            {tab.extra}
                                        </Box>
                                    ) : null}
                                </Box>
                            }
                            value={index}
                            sx={tab.hideOnMobile ? hideOnMobileSx : null}
                        />
                    </Link>
                )
            })}

            {/* Dropdown Menu Section */}
            {maxVisibleTabs && data.length > maxVisibleTabs && (
                <>
                    {/* "More" Tab Button */}
                    <StyledTab
                        theme={theme}
                        onClick={handleClick}
                        label={
                            <Box
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                            >
                                <TabText
                                    sx={
                                        activeTab >= maxVisibleTabs
                                            ? {
                                                  color: theme.palette.primary
                                                      .main,
                                              }
                                            : {}
                                    }
                                >
                                    {data.length - maxVisibleTabs} More
                                </TabText>
                                <KeyboardArrowDown
                                    sx={{ ml: 0.5, mt: '12px' }}
                                />
                            </Box>
                        }
                        value={maxVisibleTabs}
                    />
                    {/* Dropdown Menu */}
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        {/* Render overflow tabs in dropdown */}
                        {data.slice(maxVisibleTabs).map((tab, index) => {
                            const actualIndex = index + maxVisibleTabs
                            const isActive = actualIndex === activeTab
                            return (
                                <StyledMenuItem
                                    key={tab?.name}
                                    onClick={handleClose}
                                    sx={{
                                        color: isActive
                                            ? theme.palette.primary.main
                                            : theme.palette.inactive.main,
                                    }}
                                >
                                    {tab?.link && (
                                        <Link href={tab.link} passHref>
                                            <TabText
                                                sx={{
                                                    paddingTop: 0,
                                                    fontSize: 'inherit',
                                                }}
                                            >
                                                {tab?.name}
                                            </TabText>
                                        </Link>
                                    )}
                                </StyledMenuItem>
                            )
                        })}
                    </Menu>
                </>
            )}
        </Tabs>
    )
}

/**
 * DetailTabs Component
 *
 * A flexible tab navigation component that supports:
 * - Dynamic tab rendering with optional overflow handling
 * - URL-based active tab detection
 * - Manual tab selection override
 * - Optional extra content (buttons, icons) in the tab bar
 * - Mobile-responsive design with configurable tab visibility
 *
 * The component automatically determines the active tab based on the current URL,
 * but allows manual override through the manualTabIndex prop. It handles overflow
 * by moving excess tabs into a dropdown menu when maxVisibleTabs is specified.
 */
export const DetailTabs = ({
    data,
    variant = 'scrollable',
    extraTabEnd,
    manualTabIndex,
    maxVisibleTabs,
}: DetailTabProps) => {
    // Get the current router instance for URL-based tab detection
    const router = useRouter()

    // URL-based active tab detection
    // Matches the current URL path (excluding query params) against each tab's linkRoot
    const activeTabIndex = data.findIndex((tab) =>
        tab ? removeQueryString(router.asPath).startsWith(tab.linkRoot) : false
    )

    // Handle cases where no matching tab is found
    // Default to first tab (index 0) to prevent rendering errors
    const activeTabResult = activeTabIndex === -1 ? 0 : activeTabIndex

    // Determine the final active tab index
    // Use manual override if provided, otherwise use URL-based detection
    const activeTab =
        manualTabIndex !== undefined ? manualTabIndex : activeTabResult

    return (
        <BelfryTabContainer>
            <RenderedTabs
                data={data}
                activeTab={activeTab}
                maxVisibleTabs={maxVisibleTabs}
                variant={variant}
            />

            {/* Optional Extra Content Section */}
            {/* Renders additional UI elements (buttons, icons, etc.) at the end of the tab bar */}
            {extraTabEnd && (
                <Box mt={1} display="flex">
                    {extraTabEnd}
                </Box>
            )}
        </BelfryTabContainer>
    )
}
