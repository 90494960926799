import { Button, Grid, Stack, Typography, styled } from '@mui/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import NextLink from 'next/link'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'

const TaskListElementContainer = styled(Grid)({
    width: '100%',
    border: '1px solid rgba(128, 128, 128, 0.2)',
    borderRadius: '6px',
    maxHeight: '5em',
    //justifyContent: 'space-around',
    padding: '1em',
})

const TaskListActionableElementContainer = styled(TaskListElementContainer)({
    '&:hover': {
        cursor: 'pointer',
    },
})

const TaskListElementItem = styled(Grid)({
    verticalAlign: 'middle',
    margin: 'auto',
})

export type TaskListElementProps = {
    iconEl: JSX.Element
    name: string
    href: string
    actionable?: boolean
    caption?: string
    onDownload?: () => void
}
export const TaskListElement = ({
    iconEl,
    name,
    href,
    actionable,
    caption,
    onDownload,
}: TaskListElementProps) => {
    const taskListElementInner = (
        <>
            <TaskListElementItem item xs={1}>
                <div style={{ margin: 'auto', width: '28px' }}>{iconEl}</div>
            </TaskListElementItem>

            <TaskListElementItem item xs={8}>
                <Stack gap={1}>
                    <Typography variant="body2" fontWeight={500}>
                        {name}
                    </Typography>
                    {caption ? (
                        <Typography
                            variant="caption"
                            style={{ fontStyle: 'italic' }}
                        >
                            {caption}
                        </Typography>
                    ) : null}
                </Stack>
            </TaskListElementItem>

            <TaskListElementItem item xs={2}>
                <div
                    style={{
                        marginLeft: 'auto',
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                >
                    {actionable ? (
                        <ArrowForwardIosIcon fontSize="small" />
                    ) : onDownload ? (
                        <Button
                            startIcon={
                                <CloudDownloadIcon
                                    style={{ transform: 'scale(1.3)' }}
                                />
                            }
                            onClick={() => onDownload()}
                        />
                    ) : (
                        <></>
                    )}
                </div>
            </TaskListElementItem>
        </>
    )

    return (
        <>
            {actionable ? (
                <NextLink href={href} legacyBehavior>
                    <TaskListActionableElementContainer
                        container
                        direction={'row'}
                    >
                        {taskListElementInner}
                    </TaskListActionableElementContainer>
                </NextLink>
            ) : (
                <TaskListElementContainer container direction={'row'}>
                    {taskListElementInner}
                </TaskListElementContainer>
            )}
        </>
    )
}
