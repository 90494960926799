import useSWR from 'swr'

import {
    BasicCustomer,
    ShiftInstance,
    ScheduleService,
    ShiftSplit,
    ShiftInstanceUpdate,
    ShiftInstanceStatus,
    ScheduleOfficer,
    ScheduleShiftInstance,
    ShiftInstanceForSchedule,
    TimekeepingShiftInstance,
    ShiftSchedule,
} from '../generated'
import { format } from 'date-fns'
import { handleFileResponse } from 'src/utils/downloads'
import {
    updateShiftInstance as updateShiftInstanceNew,
    splitShift as splitShiftNew,
    deleteShiftInstancesByIds as deleteShiftInstancesByIdsNew,
    refreshSchedule as refreshScheduleNew,
    assignOfficerToShift as assignOfficerToShiftNew,
    updateSchedule as updateScheduleNew,
    bulkEditSchedule as bulkEditScheduleNew,
} from 'src/services/schedule_v2'
import { ScopedMutator } from 'swr/dist/_internal'
type LoadScheduleFunc = (
    startDate?: string,
    endDate?: string,
    v4ScheduleFlag?: boolean,
    officerId?: string | null
) => {
    schedule: ShiftSchedule
    isScheduleLoading: boolean
    isScheduleError: boolean
}
export const useLoadSchedule: LoadScheduleFunc = (
    startDate,
    endDate,
    v4ScheduleFlag = false,
    officerId = null
) => {
    const { data, error } = useSWR(
        startDate && endDate && !v4ScheduleFlag
            ? officerId
                ? `/schedule?start_date=${startDate}&end_date=${endDate}&officer_id=${officerId}`
                : `/schedule?start_date=${startDate}&end_date=${endDate}`
            : null,
        async () => {
            return await ScheduleService.getScheduleForDateRangeApiV3ScheduleGet(
                startDate!,
                endDate!,
                true, // dynamically calculate if we need actual times.
                officerId
            )
        }
    )
    return {
        schedule: data ?? { shift_schedule: [] },
        isScheduleLoading: !error && !data,
        isScheduleError: error,
    }
}

type LoadScheduleWithOfficerFunc = (
    startDate?: string,
    endDate?: string,
    officerId?: string
) => {
    schedule: ShiftSchedule
    isScheduleLoading: boolean
    isScheduleError: boolean
}
export const useLoadScheduleWithOfficer: LoadScheduleWithOfficerFunc = (
    startDate,
    endDate,
    officerId
) => {
    const url = `/schedule?start_date=${startDate}&end_date=${endDate}&officer_id=${officerId}`
    const { data, error } = useSWR(url, async () => {
        const scheduleData =
            startDate && endDate && officerId
                ? await ScheduleService.getScheduleForDateRangeApiV3ScheduleGet(
                      startDate,
                      endDate,
                      true, // dynamically calculate if we need actual times.
                      officerId
                  )
                : { shift_schedule: [], called_off_shifts: [] }
        return { ...scheduleData, called_off_shifts: [] }
    })
    return {
        schedule: data ?? { shift_schedule: [] },
        isScheduleLoading: !error && !data,
        isScheduleError: error,
    }
}

type LoadScheduleShortDetailsFunc = (shiftInstanceId: string | undefined) => {
    shiftInstance: ShiftInstanceForSchedule | undefined
    isShiftInstanceLoading: boolean
    isShiftInstanceError: boolean
}
export const useLoadScheduleDetailShort: LoadScheduleShortDetailsFunc = (
    shiftInstanceId
) => {
    const { data, error } = useSWR(
        shiftInstanceId
            ? ['schedule-short-details', shiftInstanceId]
            : shiftInstanceId,
        async () => {
            return await ScheduleService.getShiftInstanceDetailsApiV3ScheduleDetailsShiftInstanceIdGet(
                shiftInstanceId!
            )
        }
    )
    return {
        shiftInstance: data,
        isShiftInstanceLoading: !error && !data,
        isShiftInstanceError: error,
    }
}

type RefreshShiftInstanceDetails = (
    mutate: ScopedMutator,
    shiftInstanceId: string | undefined
) => void
export const refreshShiftInstanceDetails: RefreshShiftInstanceDetails = (
    mutate,
    shiftInstanceId
) => {
    return mutate(['schedule-short-details', shiftInstanceId], () =>
        ScheduleService.getShiftInstanceDetailsApiV3ScheduleDetailsShiftInstanceIdGet(
            shiftInstanceId!
        )
    )
}

type LoadCustomersForScheduleFunc = (
    startDate?: string,
    endDate?: string
) => {
    customers: BasicCustomer[]
    isLoading: boolean
    isError: boolean
}
export const useLoadCustomersForSchedule: LoadCustomersForScheduleFunc = (
    startDate,
    endDate
) => {
    const { data, error } = useSWR(
        startDate && endDate
            ? ['/schedule/customers', startDate, endDate]
            : null,
        async () => {
            return await ScheduleService.getScheduleCustomersForDateRangeApiV3ScheduleCustomersGet(
                startDate!,
                endDate!
            )
        }
    )
    return {
        customers: data ?? [],
        isLoading: !error && !data,
        isError: error,
    }
}

type RefreshScheduleFunc = (
    startDate: string,
    endDate: string,
    mutate: ScopedMutator,
    v4ScheduleFlag: boolean
) =>
    | Promise<ShiftSchedule | undefined>
    | Promise<TimekeepingShiftInstance[] | undefined>
export const refreshSchedule: RefreshScheduleFunc = (
    startDate,
    endDate,
    mutate,
    v4ScheduleFlag
) => {
    if (v4ScheduleFlag) {
        return refreshScheduleNew(startDate, endDate, mutate)
    } else {
        return mutate(
            `/schedule?start_date=${startDate}&end_date=${endDate}`,
            async () => {
                const updatedSchedule =
                    await ScheduleService.getScheduleForDateRangeApiV3ScheduleGet(
                        startDate,
                        endDate,
                        true // dynamically calculate if we need actual times.
                    )
                return { ...updatedSchedule, called_off_shifts: [] }
            },
            true
        )
    }
}

type AssignOfficerToShiftFunc = (
    officer: Pick<ScheduleOfficer, 'id'> | undefined,
    shiftInstance: ShiftInstance,
    dateRangeStart: Date | undefined,
    dateRangeEnd: Date | undefined,
    mutate: ScopedMutator,
    v4ScheduleFlag: boolean,
    isShiftOfferAssignment?: boolean
) => Promise<any>
export const assignOfficerToShift: AssignOfficerToShiftFunc = (
    officer,
    shiftInstance,
    dateRangeStart,
    dateRangeEnd,
    mutate,
    v4ScheduleFlag,
    isShiftOfferAssignment = false
) => {
    if (v4ScheduleFlag) {
        return assignOfficerToShiftNew(
            officer,
            shiftInstance,
            dateRangeStart,
            dateRangeEnd,
            mutate,
            isShiftOfferAssignment
        )
    } else {
        if (dateRangeStart === undefined || dateRangeEnd === undefined) {
            return Promise.resolve()
        }
        const startDate = format(dateRangeStart!, 'yyyy-MM-dd')
        const endDate = format(dateRangeEnd!, 'yyyy-MM-dd')
        return mutate(
            `/schedule?start_date=${startDate}&end_date=${endDate}`,
            async (schedule?: ShiftSchedule) => {
                let status
                if (officer) {
                    // Publish the shift if it is being accepted from an offer
                    status = isShiftOfferAssignment
                        ? ShiftInstanceStatus.PUBLISHED
                        : ShiftInstanceStatus.UNPUBLISHED
                } else {
                    status = ShiftInstanceStatus.OPEN
                }
                const updatedSchedule =
                    await ScheduleService.setShiftInstanceApiV3ScheduleInstancePut(
                        startDate,
                        endDate,
                        {
                            ...shiftInstance,
                            officer_id: officer?.id,
                            status,
                        },
                        true // dynamically calculate if we need actual times.
                    )

                // if this is a shift offer assignment, we need to invalidate the shift marketplace cache
                if (isShiftOfferAssignment) {
                    mutate(
                        [
                            '/marketplace/shift-offer',
                            format(dateRangeStart, 'yyyy-MM-dd'),
                            format(dateRangeEnd, 'yyyy-MM-dd'),
                        ],
                        undefined,
                        true
                    )
                    mutate(
                        [
                            '/marketplace/pending-assignment-count',
                            format(dateRangeStart, 'yyyy-MM-dd'),
                            format(dateRangeEnd, 'yyyy-MM-dd'),
                        ],
                        undefined,
                        true
                    )
                }
                // XXX hacky escape hatch for if we haven't loaded the schedule- all of this should be refactored
                if (!schedule) {
                    return {
                        shift_schedule: [],
                    }
                }
                const updatedScheduleIds = updatedSchedule.shift_schedule.map(
                    (shiftInstance) => shiftInstance.id
                )
                const filteredSchedule = schedule.shift_schedule.filter(
                    (shiftInstance: ScheduleShiftInstance) =>
                        !updatedScheduleIds.includes(shiftInstance.id)
                )
                return {
                    shift_schedule: [
                        ...updatedSchedule.shift_schedule,
                        ...filteredSchedule,
                    ],
                }
            }
        ).then(() => refreshShiftInstanceDetails(mutate, shiftInstance.id))
    }
}

type UpdateShiftInstanceFunc = (
    shiftInstance: ShiftInstanceUpdate,
    shiftInstanceId: string,
    dateRangeStart: Date | undefined,
    dateRangeEnd: Date | undefined,
    mutate: any,
    v4ScheduleFlag: boolean
) => Promise<any>
export const updateShiftInstance: UpdateShiftInstanceFunc = (
    shiftInstance,
    shiftInstanceId,
    dateRangeStart,
    dateRangeEnd,
    mutate,
    v4ScheduleFlag
) => {
    if (v4ScheduleFlag) {
        return updateShiftInstanceNew(
            shiftInstance,
            dateRangeStart,
            dateRangeEnd,
            mutate
        )
    } else {
        if (dateRangeStart === undefined || dateRangeEnd === undefined) {
            return Promise.resolve()
        }
        const startDate = format(dateRangeStart!, 'yyyy-MM-dd')
        const endDate = format(dateRangeEnd!, 'yyyy-MM-dd')
        return mutate(
            `/schedule?start_date=${startDate}&end_date=${endDate}`,
            async (schedule?: ShiftSchedule) => {
                const updatedSchedule =
                    await ScheduleService.setShiftInstanceApiV3ScheduleInstancePut(
                        startDate,
                        endDate,
                        shiftInstance,
                        true // dynamically calculate if we need actual times.
                    )

                // invalidate the tk period view asset where this is also used
                mutate(
                    [`/timecards/`, startDate, endDate, startDate, endDate],
                    undefined,
                    true
                )

                // XXX hacky escape hatch for if we haven't loaded the schedule- all of this should be refactored
                if (!schedule) {
                    return {
                        shift_schedule: [],
                    }
                }
                const updatedScheduleIds = updatedSchedule.shift_schedule.map(
                    (shiftInstance) => shiftInstance.id
                )
                const filteredSchedule = schedule.shift_schedule.filter(
                    (shiftInstance: ScheduleShiftInstance) =>
                        !updatedScheduleIds.includes(shiftInstance.id)
                )
                return {
                    shift_schedule: [
                        ...updatedSchedule.shift_schedule,
                        ...filteredSchedule,
                    ],
                    call_offs: [],
                }
            }
        ).then(() => refreshShiftInstanceDetails(mutate, shiftInstanceId))
    }
}

type DeleteShiftInstanceFunc = (
    shiftInstanceIds: string[],
    dateRangeStart: Date | undefined,
    dateRangeEnd: Date | undefined,
    mutate: ScopedMutator,
    v4ScheduleFlag: boolean
) => Promise<ShiftSchedule | TimekeepingShiftInstance[] | undefined | void>
export const deleteShiftInstancesByIds: DeleteShiftInstanceFunc = (
    shiftInstanceIds,
    dateRangeStart,
    dateRangeEnd,
    mutate,
    v4ScheduleFlag
) => {
    if (v4ScheduleFlag) {
        return deleteShiftInstancesByIdsNew(
            shiftInstanceIds,
            dateRangeStart,
            dateRangeEnd,
            mutate
        )
    } else {
        if (dateRangeStart === undefined || dateRangeEnd === undefined) {
            return Promise.resolve()
        }
        const startDate = format(dateRangeStart!, 'yyyy-MM-dd')
        const endDate = format(dateRangeEnd!, 'yyyy-MM-dd')
        // actually delete and mutate the schedule asset
        return mutate(
            `/schedule?start_date=${startDate}&end_date=${endDate}`,
            async (schedule?: ShiftSchedule) => {
                await ScheduleService.deleteShiftInstanceIdsApiV3ScheduleInstancesDelete(
                    startDate,
                    endDate,
                    shiftInstanceIds,
                    true
                )
                // invalidate the tk period view asset where this is also used
                mutate(
                    [`/timecards/`, startDate, endDate, startDate, endDate],
                    undefined,
                    true
                )
                // XXX hacky escape hatch for if we haven't loaded the schedule- all of this should be refactored
                if (!schedule) {
                    return {
                        shift_schedule: [],
                    }
                }
                const filteredSchedule = schedule.shift_schedule.filter(
                    (shiftInstance: ScheduleShiftInstance) =>
                        !shiftInstanceIds.includes(shiftInstance.id)
                )
                return {
                    shift_schedule: filteredSchedule,
                }
            }
        )
    }
}

type UpdateScheduleFunc = (
    shiftInstances: ScheduleShiftInstance[],
    startDate: string,
    endDate: string,
    mutate: any,
    v4ScheduleFlag: boolean
) => Promise<ShiftSchedule | TimekeepingShiftInstance[] | undefined>
export const updateSchedule: UpdateScheduleFunc = (
    shiftInstances,
    startDate,
    endDate,
    mutate,
    v4ScheduleFlag
):
    | Promise<ShiftSchedule | undefined>
    | Promise<TimekeepingShiftInstance[] | undefined> => {
    if (v4ScheduleFlag) {
        return updateScheduleNew(shiftInstances, startDate, endDate, mutate)
    } else {
        const shiftInstanceUpdates = shiftInstances.map((shiftInstance) => {
            return {
                ...shiftInstance,
                officer_id: shiftInstance.officer
                    ? shiftInstance.officer.id
                    : undefined,
            }
        })
        return mutate(
            `/schedule?start_date=${startDate}&end_date=${endDate}`,
            async (schedule?: ShiftSchedule) => {
                const updatedSchedule =
                    await ScheduleService.setScheduledOfficersApiV3SchedulePut(
                        startDate,
                        endDate,
                        shiftInstanceUpdates,
                        true
                    )

                // XXX hacky escape hatch for if we haven't loaded the schedule- all of this should be refactored
                if (!schedule) {
                    return {
                        shift_schedule: [],
                    }
                }
                const updatedScheduleIds = updatedSchedule.shift_schedule.map(
                    (shiftInstance) => shiftInstance.id
                )
                const filteredSchedule = schedule.shift_schedule.filter(
                    (shiftInstance: ScheduleShiftInstance) =>
                        !updatedScheduleIds.includes(shiftInstance.id)
                )
                return {
                    shift_schedule: [
                        ...updatedSchedule.shift_schedule,
                        ...filteredSchedule,
                    ],
                }
            }
        )
    }
}

type BulkEditTypeFunc = (
    shiftInstances: ShiftInstanceUpdate[],
    startDate: string,
    endDate: string,
    mutate: ScopedMutator,
    v4ScheduleFlag: boolean
) => Promise<ShiftSchedule | TimekeepingShiftInstance[] | undefined>
export const bulkEditSchedule: BulkEditTypeFunc = (
    shiftInstances,
    startDate,
    endDate,
    mutate,
    v4ScheduleFlag
):
    | Promise<ShiftSchedule | undefined>
    | Promise<TimekeepingShiftInstance[] | undefined> => {
    if (v4ScheduleFlag) {
        return bulkEditScheduleNew(shiftInstances, startDate, endDate, mutate)
    } else {
        return mutate(
            `/schedule?start_date=${startDate}&end_date=${endDate}`,
            async (schedule?: ShiftSchedule) => {
                const updatedSchedule =
                    await ScheduleService.bulkSetInstanceDetailsApiV3BulkSchedulePut(
                        startDate,
                        endDate,
                        shiftInstances,
                        true
                    )
                const updatedScheduleIds = updatedSchedule.shift_schedule.map(
                    (shiftInstance) => shiftInstance.id
                )
                const filteredSchedule =
                    schedule?.shift_schedule?.filter(
                        (shiftInstance: ScheduleShiftInstance) =>
                            !updatedScheduleIds.includes(shiftInstance.id)
                    ) ?? []
                mutate(
                    ['/marketplace/shift-offer', startDate, endDate],
                    undefined,
                    true
                )
                return {
                    shift_schedule: [
                        ...updatedSchedule.shift_schedule,
                        ...filteredSchedule,
                    ],
                }
            }
        )
    }
}

type DownloadScheduleParams = {
    startDate: string
    endDate: string
    format: 'csv' | 'pdf'
    omitContactInfo?: boolean
    omitStatus?: boolean
    customerIds?: string[]
    officerIds?: string[]
}
type DownloadScheduleFunc = (params: DownloadScheduleParams) => Promise<void>
export const downloadSchedule: DownloadScheduleFunc = ({
    startDate,
    endDate,
    format,
    omitContactInfo,
    omitStatus,
    customerIds,
    officerIds,
}) => {
    return ScheduleService.getScheduleExportApiV3ScheduleExportGet(
        startDate,
        endDate,
        format,
        customerIds,
        officerIds,
        omitContactInfo,
        omitStatus
    ).then(
        handleFileResponse({
            fileName: `Belfry_schedule_export_${startDate}_to_${endDate}.${format}`,
            mimeType: format === 'csv' ? 'text/csv' : 'application/pdf',
        })
    )
}

type SplitShiftFunc = (
    shiftInstance: ShiftInstance,
    dateRangeStart: Date | undefined,
    dateRangeEnd: Date | undefined,
    shiftSplits: ShiftSplit[],
    mutate: any,
    v4ScheduleFlag: boolean
) => Promise<any>
export const splitShift: SplitShiftFunc = (
    shiftInstance,
    dateRangeStart,
    dateRangeEnd,
    shiftSplits,
    mutate,
    v4ScheduleFlag
) => {
    if (v4ScheduleFlag) {
        return splitShiftNew(
            shiftInstance,
            dateRangeStart,
            dateRangeEnd,
            shiftSplits,
            mutate
        )
    } else {
        const startDate = format(dateRangeStart!, 'yyyy-MM-dd')
        const endDate = format(dateRangeEnd!, 'yyyy-MM-dd')

        return mutate(
            `/schedule?start_date=${startDate}&end_date=${endDate}`,
            async (schedule?: ShiftSchedule) => {
                const updatedSchedule =
                    await ScheduleService.splitShiftApiV3ScheduleInstanceShiftIdTargetDateIndexPut(
                        shiftInstance.shift_id,
                        shiftInstance.date,
                        shiftInstance.index ?? 0,
                        startDate,
                        endDate,
                        shiftSplits,
                        true // dynamically calculate if we need actual times.
                    )
                const updatedScheduleIds = updatedSchedule.shift_schedule.map(
                    (shiftInstance) => shiftInstance.id
                )
                if (!schedule) {
                    return {
                        shift_schedule: [],
                    }
                }
                const filteredSchedule = schedule.shift_schedule.filter(
                    (shiftInstance: ScheduleShiftInstance) =>
                        !updatedScheduleIds.includes(shiftInstance.id)
                )
                return {
                    shift_schedule: [
                        ...updatedSchedule.shift_schedule,
                        ...filteredSchedule,
                    ],
                    call_offs: [],
                }
            }
        ).then(() => refreshShiftInstanceDetails(mutate, shiftInstance.id))
    }
}
