import React, { createContext, useMemo, useState } from 'react'
import { Region } from '../../../generated'
import { useCurrentUserRegions } from '../../../services/region'

export const RegionContext = createContext(null)

type RegionContextWrapperProps = React.PropsWithChildren<{
    setHeaderVal: (k: string, v: string | undefined) => void
}>

const getRegionHeader = (
    selectedRegionIds: string[],
    regions: Array<unknown>
) => {
    const allOrNoRegions =
        selectedRegionIds.length == 0 ||
        selectedRegionIds.length == regions.length
    if (allOrNoRegions) {
        return undefined
    }
    return selectedRegionIds.join(';')
}

export const RegionContextWrapper = ({
    children,
    setHeaderVal,
}: RegionContextWrapperProps) => {
    const { regions } = useCurrentUserRegions()
    const [selectedRegionIds, setSelectedRegionIds] = useState<string[]>([])
    const selectedRegions = useMemo<Region[]>(() => {
        return regions.filter((value) => selectedRegionIds.includes(value.id))
    }, [selectedRegionIds, regions])

    return (
        <RegionContext.Provider
            // @ts-ignore - initially null but always set
            value={{
                selectedRegions,
                selectedRegionIds,
                setSelectedRegionIds: (ids: string[]) => {
                    setSelectedRegionIds(ids)
                    setHeaderVal(
                        'active-regions',
                        getRegionHeader(ids, regions)
                    )
                },
            }}
        >
            {children}
        </RegionContext.Provider>
    )
}
