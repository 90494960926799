/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BelfryAPIEmployeeTaxParameterResponse } from '../models/BelfryAPIEmployeeTaxParameterResponse';
import type { BelfryEmployeeOrContractorDefaultNetPaySplit } from '../models/BelfryEmployeeOrContractorDefaultNetPaySplit';
import type { Body_create_or_update_payroll_contractors_with_shift_based_earning_api_v2_payrolls__payroll_id__contractors_shift_earning_post } from '../models/Body_create_or_update_payroll_contractors_with_shift_based_earning_api_v2_payrolls__payroll_id__contractors_shift_earning_post';
import type { Body_create_or_update_payroll_employees_with_shift_based_earning_api_v2_payrolls__payroll_id__employees_shift_earning_post } from '../models/Body_create_or_update_payroll_employees_with_shift_based_earning_api_v2_payrolls__payroll_id__employees_shift_earning_post';
import type { CheckHqCompanyStatusResponse } from '../models/CheckHqCompanyStatusResponse';
import type { CheckHqWorkerResponse } from '../models/CheckHqWorkerResponse';
import type { CHQEarningCode } from '../models/CHQEarningCode';
import type { CHQEarningRate } from '../models/CHQEarningRate';
import type { CHQMinimalPayrollResults } from '../models/CHQMinimalPayrollResults';
import type { CHQOffCycleOptions } from '../models/CHQOffCycleOptions';
import type { ContractorPayroll } from '../models/ContractorPayroll';
import type { ContractorPayrollCreateOrUpdate } from '../models/ContractorPayrollCreateOrUpdate';
import type { ContractorPayrollEarningUpdate } from '../models/ContractorPayrollEarningUpdate';
import type { ContractorPayrollMinimal } from '../models/ContractorPayrollMinimal';
import type { ContractorPayrollReimbursementUpdate } from '../models/ContractorPayrollReimbursementUpdate';
import type { ContractorPayrollUpdate } from '../models/ContractorPayrollUpdate';
import type { EmployeeOrContractorPayrollOfficer } from '../models/EmployeeOrContractorPayrollOfficer';
import type { EmployeePayroll } from '../models/EmployeePayroll';
import type { EmployeePayrollBenefit } from '../models/EmployeePayrollBenefit';
import type { EmployeePayrollCreateOrUpdate } from '../models/EmployeePayrollCreateOrUpdate';
import type { EmployeePayrollEarningUpdate } from '../models/EmployeePayrollEarningUpdate';
import type { EmployeePayrollMinimal } from '../models/EmployeePayrollMinimal';
import type { EmployeePayrollPostTaxDeduction } from '../models/EmployeePayrollPostTaxDeduction';
import type { EmployeePayrollReimbursementUpdate } from '../models/EmployeePayrollReimbursementUpdate';
import type { EmployeePayrollUpdate } from '../models/EmployeePayrollUpdate';
import type { FirmEarningCode } from '../models/FirmEarningCode';
import type { FirmEarningCodeFull } from '../models/FirmEarningCodeFull';
import type { FullPayroll } from '../models/FullPayroll';
import type { NonCustomerWorkplace } from '../models/NonCustomerWorkplace';
import type { NonCustomerWorkplaceCreate } from '../models/NonCustomerWorkplaceCreate';
import type { Page_Payroll_ } from '../models/Page_Payroll_';
import type { Payday } from '../models/Payday';
import type { Payroll } from '../models/Payroll';
import type { PayrollCashRequirementSummary } from '../models/PayrollCashRequirementSummary';
import type { PayrollCreate } from '../models/PayrollCreate';
import type { PayrollCustomReportPossibleColumns } from '../models/PayrollCustomReportPossibleColumns';
import type { PayrollPatch } from '../models/PayrollPatch';
import type { PayrollShiftEarning } from '../models/PayrollShiftEarning';
import type { PayrollShiftEarningUpdate } from '../models/PayrollShiftEarningUpdate';
import type { PayrollTax } from '../models/PayrollTax';
import type { PayrollWorkplace } from '../models/PayrollWorkplace';
import type { ResultResponse } from '../models/ResultResponse';
import type { ResultResponsePeriod } from '../models/ResultResponsePeriod';
import type { ResultResponseString } from '../models/ResultResponseString';
import type { UrlResponse } from '../models/UrlResponse';
import type { WirePaymentResponse } from '../models/WirePaymentResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PayrollService {

    /**
     * Payroll Initial Company Setup
     * @param title
     * @param email
     * @param firstName
     * @param middleName
     * @param lastName
     * @param startDate
     * @param firstPeriodEndDate
     * @returns string Successful Response
     * @throws ApiError
     */
    public static payrollInitialCompanySetupApiV1PayrollInitialCompanySetupPost(
        title: string,
        email: string,
        firstName: string,
        middleName: string,
        lastName: string,
        startDate: string,
        firstPeriodEndDate: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/payroll/initial_company_setup',
            query: {
                'title': title,
                'email': email,
                'first_name': firstName,
                'middle_name': middleName,
                'last_name': lastName,
                'start_date': startDate,
                'first_period_end_date': firstPeriodEndDate,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Company Onboard Link
     * @returns UrlResponse Successful Response
     * @throws ApiError
     */
    public static companyOnboardLinkApiV1PayrollCompanyOnboardLinkGet(): CancelablePromise<UrlResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/payroll/company/onboard_link',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Company Status
     * @returns CheckHqCompanyStatusResponse Successful Response
     * @throws ApiError
     */
    public static companyStatusApiV1PayrollCompanyStatusGet(): CancelablePromise<CheckHqCompanyStatusResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/payroll/company/status',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Firm Payrolls Paginated
     * @param cursor
     * @returns CHQMinimalPayrollResults Successful Response
     * @throws ApiError
     */
    public static getFirmPayrollsPaginatedApiV1PayrollPaginatedListGet(
        cursor?: (string | null),
    ): CancelablePromise<CHQMinimalPayrollResults> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/payroll/paginated_list',
            query: {
                'cursor': cursor,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Payroll Edit Link
     * @param payrollId
     * @returns string Successful Response
     * @throws ApiError
     */
    public static getPayrollEditLinkApiV1PayrollEditLinkPost(
        payrollId: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/payroll/edit_link',
            query: {
                'payroll_id': payrollId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Payroll Draft
     * @param payrollId
     * @returns string Successful Response
     * @throws ApiError
     */
    public static deletePayrollDraftApiV1PayrollDeleteDraftDelete(
        payrollId: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/payroll/delete_draft',
            query: {
                'payroll_id': payrollId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Non Customer Workplaces
     * @returns NonCustomerWorkplace Successful Response
     * @throws ApiError
     */
    public static getNonCustomerWorkplacesApiV1PayrollNonCustomerWorkplacesGet(): CancelablePromise<Array<NonCustomerWorkplace>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/payroll/non_customer_workplaces',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Non Customer Workplace
     * @param requestBody
     * @returns NonCustomerWorkplace Successful Response
     * @throws ApiError
     */
    public static createNonCustomerWorkplaceApiV1PayrollNonCustomerWorkplacesPost(
        requestBody: NonCustomerWorkplaceCreate,
    ): CancelablePromise<Array<NonCustomerWorkplace>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/payroll/non_customer_workplaces',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Payroll Company Tax Docs Link
     * @returns UrlResponse Successful Response
     * @throws ApiError
     */
    public static payrollCompanyTaxDocsLinkApiV1PayrollCompanyTaxDocsGet(): CancelablePromise<UrlResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/payroll/company/tax_docs',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Payroll Company Authorization Docs Link
     * @returns UrlResponse Successful Response
     * @throws ApiError
     */
    public static payrollCompanyAuthorizationDocsLinkApiV1PayrollCompanyAuthorizationDocsGet(): CancelablePromise<UrlResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/payroll/company/authorization_docs',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Employee Or Contractor Net Pay Splits
     * @param officerId
     * @returns BelfryEmployeeOrContractorDefaultNetPaySplit Successful Response
     * @throws ApiError
     */
    public static getEmployeeOrContractorNetPaySplitsApiV1PayrollWorkerOfficerIdNetPaySplitsGet(
        officerId: string,
    ): CancelablePromise<BelfryEmployeeOrContractorDefaultNetPaySplit> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/payroll/worker/{officer_id}/net_pay_splits',
            path: {
                'officer_id': officerId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Payroll Company Payroll Summary
     * @param start
     * @param end
     * @returns any Successful Response
     * @throws ApiError
     */
    public static payrollCompanyPayrollSummaryApiV1PayrollCompanyPayrollSummaryGet(
        start?: (string | null),
        end?: (string | null),
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/payroll/company/payroll_summary',
            query: {
                'start': start,
                'end': end,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Company Payroll Journal Csv
     * @param payrollId
     * @param groupByWorkplace
     * @returns any Successful Response
     * @throws ApiError
     */
    public static companyPayrollJournalCsvApiV1PayrollCompanyPayrollJournalCsvGet(
        payrollId: string,
        groupByWorkplace: boolean = false,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/payroll/company/payroll_journal_csv',
            query: {
                'group_by_workplace': groupByWorkplace,
                'payroll_id': payrollId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Company Payroll Preview Csv
     * @param payrollId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static companyPayrollPreviewCsvApiV1PayrollCompanyPayrollPreviewCsvGet(
        payrollId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/payroll/company/payroll_preview_csv',
            query: {
                'payroll_id': payrollId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Company Payroll Paper Check Report Csv
     * @param payrollId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static companyPayrollPaperCheckReportCsvApiV1PayrollCompanyPayrollPaperCheckReportCsvGet(
        payrollId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/payroll/company/payroll_paper_check_report_csv',
            query: {
                'payroll_id': payrollId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Blank Off Cycle Payroll
     * @param startDate
     * @param endDate
     * @param payday
     * @param requestBody
     * @returns ResultResponseString Successful Response
     * @throws ApiError
     */
    public static createBlankOffCyclePayrollApiV1PayrollOffCyclePayrollPost(
        startDate: string,
        endDate: string,
        payday: string,
        requestBody?: (CHQOffCycleOptions | null),
    ): CancelablePromise<ResultResponseString> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/payroll/off_cycle_payroll',
            query: {
                'start_date': startDate,
                'end_date': endDate,
                'payday': payday,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Payschedule
     * @param startDate
     * @param endDate
     * @param payGroupId
     * @returns ResultResponsePeriod Successful Response
     * @throws ApiError
     */
    public static getPayscheduleApiV1PayrollPayscheduleGet(
        startDate: string,
        endDate: string,
        payGroupId?: (string | null),
    ): CancelablePromise<ResultResponsePeriod> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/payroll/payschedule',
            query: {
                'start_date': startDate,
                'end_date': endDate,
                'pay_group_id': payGroupId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Upcoming Payschedules
     * @param payGroupId
     * @returns Payday Successful Response
     * @throws ApiError
     */
    public static getUpcomingPayschedulesApiV1PayrollUpcomingPayschedulesGet(
        payGroupId?: (string | null),
    ): CancelablePromise<Array<Payday>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/payroll/upcoming_payschedules',
            query: {
                'pay_group_id': payGroupId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Active Earning Code List
     * @returns CHQEarningCode Successful Response
     * @throws ApiError
     */
    public static getActiveEarningCodeListApiV1PayrollEarningCodeGet(): CancelablePromise<Array<CHQEarningCode>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/payroll/earning_code',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Earning Code
     * @param requestBody
     * @returns CHQEarningCode Successful Response
     * @throws ApiError
     */
    public static createEarningCodeApiV1PayrollEarningCodePost(
        requestBody: CHQEarningCode,
    ): CancelablePromise<Array<CHQEarningCode>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/payroll/earning_code',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Officer Status
     * @param id
     * @returns CheckHqWorkerResponse Successful Response
     * @throws ApiError
     */
    public static officerStatusApiV1PayrollOfficerIdStatusGet(
        id: string,
    ): CancelablePromise<CheckHqWorkerResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/payroll/officer/{id}/status',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Officer Onboard Link
     * @param id
     * @param noSendSms
     * @returns string Successful Response
     * @throws ApiError
     */
    public static officerOnboardLinkApiV1PayrollOfficerIdOnboardLinkPost(
        id: string,
        noSendSms?: (boolean | null),
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/payroll/officer/{id}/onboard_link',
            path: {
                'id': id,
            },
            query: {
                'noSendSMS': noSendSms,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Payroll Admin Officer Tax Docs Link
     * @param officerId
     * @returns UrlResponse Successful Response
     * @throws ApiError
     */
    public static payrollAdminOfficerTaxDocsLinkApiV1PayrollAdminOfficerTaxDocsGet(
        officerId: string,
    ): CancelablePromise<UrlResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/payroll/admin/officer/tax_docs',
            query: {
                'officer_id': officerId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Payroll Admin Officer Paystubs Link
     * @param officerId
     * @returns UrlResponse Successful Response
     * @throws ApiError
     */
    public static payrollAdminOfficerPaystubsLinkApiV1PayrollAdminOfficerPaystubsGet(
        officerId: string,
    ): CancelablePromise<UrlResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/payroll/admin/officer/paystubs',
            query: {
                'officer_id': officerId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Payroll Admin Officer Post Tax Deductions Link
     * @param officerId
     * @returns UrlResponse Successful Response
     * @throws ApiError
     */
    public static payrollAdminOfficerPostTaxDeductionsLinkApiV1PayrollAdminOfficerPostTaxDeductionsGet(
        officerId: string,
    ): CancelablePromise<UrlResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/payroll/admin/officer/post_tax_deductions',
            query: {
                'officer_id': officerId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Payroll Admin Officer Benefits Link
     * @param officerId
     * @returns UrlResponse Successful Response
     * @throws ApiError
     */
    public static payrollAdminOfficerBenefitsLinkApiV1PayrollAdminOfficerBenefitsGet(
        officerId: string,
    ): CancelablePromise<UrlResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/payroll/admin/officer/benefits',
            query: {
                'officer_id': officerId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Payroll Admin Employee Payment Setup Link
     * @param officerId
     * @returns UrlResponse Successful Response
     * @throws ApiError
     */
    public static payrollAdminEmployeePaymentSetupLinkApiV1PayrollAdminEmployeePaymentSetupGet(
        officerId: string,
    ): CancelablePromise<UrlResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/payroll/admin/employee/payment_setup',
            query: {
                'officer_id': officerId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Payroll Employee Tax Parameters
     * @param officerId
     * @returns BelfryAPIEmployeeTaxParameterResponse Successful Response
     * @throws ApiError
     */
    public static payrollEmployeeTaxParametersApiV1PayrollAdminEmployeeTaxParametersGet(
        officerId: string,
    ): CancelablePromise<BelfryAPIEmployeeTaxParameterResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/payroll/admin/employee/tax_parameters',
            query: {
                'officer_id': officerId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Payroll Officer Tax Docs Link
     * @returns UrlResponse Successful Response
     * @throws ApiError
     */
    public static payrollOfficerTaxDocsLinkApiV1PayrollOfficerTaxDocsPost(): CancelablePromise<UrlResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/payroll/officer/tax_docs',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Payroll Employee Paystubs Link
     * @returns UrlResponse Successful Response
     * @throws ApiError
     */
    public static payrollEmployeePaystubsLinkApiV1PayrollEmployeePaystubsPost(): CancelablePromise<UrlResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/payroll/employee/paystubs',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Payroll Officer Onboard Link
     * @returns UrlResponse Successful Response
     * @throws ApiError
     */
    public static payrollOfficerOnboardLinkApiV1PayrollOfficerOnboardGet(): CancelablePromise<UrlResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/payroll/officer/onboard',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Payroll Officer Tax Docs Email Link
     * @returns ResultResponse Successful Response
     * @throws ApiError
     */
    public static payrollOfficerTaxDocsEmailLinkApiV1PayrollOfficerEmailTaxDocsPost(): CancelablePromise<ResultResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/payroll/officer_email/tax_docs',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Payroll Employee Paystubs Email Link
     * @returns ResultResponse Successful Response
     * @throws ApiError
     */
    public static payrollEmployeePaystubsEmailLinkApiV1PayrollEmployeeEmailPaystubsPost(): CancelablePromise<ResultResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/payroll/employee_email/paystubs',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Payroll Officer Onboard Email Link
     * @returns ResultResponse Successful Response
     * @throws ApiError
     */
    public static payrollOfficerOnboardEmailLinkApiV1PayrollOfficerEmailOnboardPost(): CancelablePromise<ResultResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/payroll/officer_email/onboard',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Send Bulk Officer Onboard Link
     * @param requestBody
     * @returns ResultResponse Successful Response
     * @throws ApiError
     */
    public static sendBulkOfficerOnboardLinkApiV1PayrollOfficersBulkOnboardLinkPost(
        requestBody: Array<string>,
    ): CancelablePromise<ResultResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/payroll/officers_bulk/onboard_link',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Download Payroll Checks
     * @param payrollId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static downloadPayrollChecksApiV1PayrollPayrollIdChecksGet(
        payrollId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/payroll/{payroll_id}/checks',
            path: {
                'payroll_id': payrollId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Payroll Company Simplyinsured Link
     * @returns UrlResponse Successful Response
     * @throws ApiError
     */
    public static payrollCompanySimplyinsuredLinkApiV1PayrollCompanySimplyinsuredGet(): CancelablePromise<UrlResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/payroll/company/simplyinsured',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Payroll Company Guideline401K Link
     * @returns UrlResponse Successful Response
     * @throws ApiError
     */
    public static payrollCompanyGuideline401KLinkApiV1PayrollCompanyGuideline401KGet(): CancelablePromise<UrlResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/payroll/company/guideline401k',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Payroll Company W2 Preview
     * @returns any Successful Response
     * @throws ApiError
     */
    public static payrollCompanyW2PreviewApiV1PayrollCompanyW2PreviewGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/payroll/company/w2_preview',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Company Payroll Cash Req Report Csv
     * @param payrollId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static companyPayrollCashReqReportCsvApiV1PayrollCompanyCashReqReportCsvGet(
        payrollId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/payroll/company/cash_req_report_csv',
            query: {
                'payroll_id': payrollId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Export Pdf Workers Comp Report
     * Return the PDF content of workers comp report data.
     * @param startDate
     * @param endDate
     * @param isSummary
     * @returns any Successful Response
     * @throws ApiError
     */
    public static exportPdfWorkersCompReportApiV1PayrollCompanyWorkersCompReportPdfStartDateEndDateIsSummaryIsSummaryGet(
        startDate: string,
        endDate: string,
        isSummary: boolean,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/payroll/company/workers_comp_report/pdf/{start_date}/{end_date}/is_summary={is_summary}',
            path: {
                'start_date': startDate,
                'end_date': endDate,
                'is_summary': isSummary,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Export Csv Workers Comp Report Itemized
     * Return the CSV content of workers comp report data.
     *
     * If is_summary is True, the CSV is summarized with just total wages and total hours per WC code.
     * @param startDate
     * @param endDate
     * @param isSummary
     * @returns any Successful Response
     * @throws ApiError
     */
    public static exportCsvWorkersCompReportItemizedApiV1PayrollCompanyWorkersCompReportCsvStartDateEndDateIsSummaryIsSummaryGet(
        startDate: string,
        endDate: string,
        isSummary: boolean,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/payroll/company/workers_comp_report/csv/{start_date}/{end_date}/is_summary={is_summary}',
            path: {
                'start_date': startDate,
                'end_date': endDate,
                'is_summary': isSummary,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Wire Payment
     * There is an assumption in this endpoint that there is only one wire payment per payroll.
     *
     * If there are multiple wire payments, we will return an error and have them contact support.
     * @param payrollId
     * @returns WirePaymentResponse Successful Response
     * @throws ApiError
     */
    public static getWirePaymentApiV1PayrollPayrollIdWirePaymentGet(
        payrollId: string,
    ): CancelablePromise<WirePaymentResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/payroll/{payroll_id}/wire_payment',
            path: {
                'payroll_id': payrollId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Company Checklist Link
     * @returns UrlResponse Successful Response
     * @throws ApiError
     */
    public static companyChecklistLinkApiV1PayrollCompanyChecklistLinkGet(): CancelablePromise<UrlResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/payroll/company/checklist_link',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Company Tax Setup Link
     * @returns UrlResponse Successful Response
     * @throws ApiError
     */
    public static companyTaxSetupLinkApiV1PayrollCompanyTaxSetupLinkGet(): CancelablePromise<UrlResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/payroll/company/tax_setup_link',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Custom Report Possible Columns
     * @returns PayrollCustomReportPossibleColumns Successful Response
     * @throws ApiError
     */
    public static customReportPossibleColumnsApiV1PayrollCustomReportPossibleColumnsGet(): CancelablePromise<PayrollCustomReportPossibleColumns> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/payroll/custom_report/possible_columns',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Custom Report Download
     * @param reportType
     * @param includeContractors
     * @param paydayAfter
     * @param paydayBefore
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static customReportDownloadApiV1PayrollCustomReportDownloadPost(
        reportType: 'by_earning' | 'by_payrun',
        includeContractors: boolean,
        paydayAfter: string,
        paydayBefore: string,
        requestBody: Array<('Worker ID' | 'First Name' | 'Last Name' | 'Middle Name' | 'Date of Birth' | 'Start Date' | 'Residence State' | 'Residence City' | 'Active' | 'Payroll ID' | 'Gross Total' | 'Payday' | 'Payroll Type' | 'Earning Type' | 'Earning Code ID' | 'Earning Code Name' | 'Earning Code Type' | 'Workplace ID' | 'Workplace State' | 'Workplace City' | 'Workplace Line1' | 'Worker Type' | 'Worker ID' | 'First Name' | 'Last Name' | 'Middle Name' | 'Date of Birth' | 'Start Date' | 'Residence State' | 'Residence City' | 'Active' | 'Payroll ID' | 'Net Pay' | 'Gross Total' | 'Company Tax Amount' | 'Employee Tax Amount' | 'Company Benefit Contribution Amount' | 'Employee Benefit Contribution Amount' | 'Post Tax Deductions Amount' | 'Reimbursements Amount' | 'Payday' | 'Payroll Type' | 'Worker Type')>,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/payroll/custom_report/download',
            query: {
                'report_type': reportType,
                'include_contractors': includeContractors,
                'payday_after': paydayAfter,
                'payday_before': paydayBefore,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * W2 1099 Delivery Prefs
     * @param includeTerminated
     * @returns any Successful Response
     * @throws ApiError
     */
    public static w21099DeliveryPrefsApiV1PayrollW21099DeliveryPrefsGet(
        includeTerminated: boolean,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/payroll/w2_1099_delivery_prefs',
            query: {
                'include_terminated': includeTerminated,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Blank Off Cycle Payroll
     * @param payrollMode
     * @param requestBody
     * @returns Payroll Successful Response
     * @throws ApiError
     */
    public static createBlankOffCyclePayrollApiV2PayrollsOffCyclePost(
        payrollMode: 'REGULAR' | 'BLANK-OFF-CYCLE-REGULAR' | 'SIDE-BY-SIDE' | 'SHADOW' | 'BLANK-OFF-CYCLE-SHADOW' | 'BLANK-OFF-CYCLE-SIDE-BY-SIDE',
        requestBody: PayrollCreate,
    ): CancelablePromise<Payroll> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v2/payrolls/off_cycle',
            query: {
                'payroll_mode': payrollMode,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Calculate Payroll Details
     * @param payrollId
     * @returns FullPayroll Successful Response
     * @throws ApiError
     */
    public static calculatePayrollDetailsApiV2PayrollsPayrollIdFullPost(
        payrollId: string,
    ): CancelablePromise<FullPayroll> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v2/payrolls/{payroll_id}/full',
            path: {
                'payroll_id': payrollId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Full Payroll Details
     * @param payrollId
     * @returns FullPayroll Successful Response
     * @throws ApiError
     */
    public static getFullPayrollDetailsApiV2PayrollsPayrollIdFullGet(
        payrollId: string,
    ): CancelablePromise<FullPayroll> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v2/payrolls/{payroll_id}/full',
            path: {
                'payroll_id': payrollId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Remove Payroll
     * @param payrollId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static removePayrollApiV2PayrollsPayrollIdDelete(
        payrollId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v2/payrolls/{payroll_id}',
            path: {
                'payroll_id': payrollId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Patch Update Payroll
     * @param payrollId
     * @param requestBody
     * @returns Payroll Successful Response
     * @throws ApiError
     */
    public static patchUpdatePayrollApiV2PayrollsPayrollIdPatch(
        payrollId: string,
        requestBody: PayrollPatch,
    ): CancelablePromise<Payroll> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v2/payrolls/{payroll_id}',
            path: {
                'payroll_id': payrollId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Save Payroll Draft
     * @param payrollId
     * @returns Payroll Successful Response
     * @throws ApiError
     */
    public static savePayrollDraftApiV2PayrollsPayrollIdPut(
        payrollId: string,
    ): CancelablePromise<Payroll> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v2/payrolls/{payroll_id}',
            path: {
                'payroll_id': payrollId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Payroll
     * @param payrollId
     * @returns Payroll Successful Response
     * @throws ApiError
     */
    public static getPayrollApiV2PayrollsPayrollIdGet(
        payrollId: string,
    ): CancelablePromise<Payroll> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v2/payrolls/{payroll_id}',
            path: {
                'payroll_id': payrollId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * List All Payrolls
     * @param page Page number
     * @param size Page size
     * @returns Page_Payroll_ Successful Response
     * @throws ApiError
     */
    public static listAllPayrollsApiV2PayrollsGet(
        page: number = 1,
        size: number = 50,
    ): CancelablePromise<Page_Payroll_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v2/payrolls',
            query: {
                'page': page,
                'size': size,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Reopen Payroll
     * @param payrollId
     * @returns Payroll Successful Response
     * @throws ApiError
     */
    public static reopenPayrollApiV2PayrollsPayrollIdReopenPost(
        payrollId: string,
    ): CancelablePromise<Payroll> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v2/payrolls/{payroll_id}/reopen',
            path: {
                'payroll_id': payrollId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Submit Payroll
     * @param payrollId
     * @returns Payroll Successful Response
     * @throws ApiError
     */
    public static submitPayrollApiV2PayrollsPayrollIdSubmitPost(
        payrollId: string,
    ): CancelablePromise<Payroll> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v2/payrolls/{payroll_id}/submit',
            path: {
                'payroll_id': payrollId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Payroll Employees
     * @param payrollId
     * @returns EmployeePayrollMinimal Successful Response
     * @throws ApiError
     */
    public static getPayrollEmployeesApiV2PayrollsPayrollIdEmployeesGet(
        payrollId: string,
    ): CancelablePromise<Array<EmployeePayrollMinimal>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v2/payrolls/{payroll_id}/employees',
            path: {
                'payroll_id': payrollId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Or Update Payroll Employees
     * @param payrollId
     * @param requestBody
     * @returns EmployeePayrollMinimal Successful Response
     * @throws ApiError
     */
    public static createOrUpdatePayrollEmployeesApiV2PayrollsPayrollIdEmployeesPost(
        payrollId: string,
        requestBody: EmployeePayrollCreateOrUpdate,
    ): CancelablePromise<Array<EmployeePayrollMinimal>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v2/payrolls/{payroll_id}/employees',
            path: {
                'payroll_id': payrollId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Payroll Employee
     * @param payrollId
     * @param employeePayrollId
     * @param requestBody
     * @returns EmployeePayrollMinimal Successful Response
     * @throws ApiError
     */
    public static updatePayrollEmployeeApiV2PayrollsPayrollIdEmployeesEmployeePayrollIdPut(
        payrollId: string,
        employeePayrollId: string,
        requestBody: EmployeePayrollUpdate,
    ): CancelablePromise<EmployeePayrollMinimal> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v2/payrolls/{payroll_id}/employees/{employee_payroll_id}',
            path: {
                'payroll_id': payrollId,
                'employee_payroll_id': employeePayrollId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Soft Delete Payroll Employee
     * @param payrollId
     * @param employeePayrollId
     * @returns EmployeePayrollMinimal Successful Response
     * @throws ApiError
     */
    public static softDeletePayrollEmployeeApiV2PayrollsPayrollIdEmployeesEmployeePayrollIdDelete(
        payrollId: string,
        employeePayrollId: string,
    ): CancelablePromise<Array<EmployeePayrollMinimal>> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v2/payrolls/{payroll_id}/employees/{employee_payroll_id}',
            path: {
                'payroll_id': payrollId,
                'employee_payroll_id': employeePayrollId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Payroll Employee Earning
     * @param id
     * @param payrollId
     * @param employeePayrollId
     * @param requestBody
     * @returns EmployeePayroll Successful Response
     * @throws ApiError
     */
    public static updatePayrollEmployeeEarningApiV2PayrollsPayrollIdEmployeesEmployeePayrollIdEarningsIdPost(
        id: string,
        payrollId: string,
        employeePayrollId: string,
        requestBody: EmployeePayrollEarningUpdate,
    ): CancelablePromise<EmployeePayroll> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v2/payrolls/{payroll_id}/employees/{employee_payroll_id}/earnings/{id}',
            path: {
                'id': id,
                'payroll_id': payrollId,
                'employee_payroll_id': employeePayrollId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Payroll Employee Reimbursement
     * @param id
     * @param payrollId
     * @param employeePayrollId
     * @param requestBody
     * @returns EmployeePayroll Successful Response
     * @throws ApiError
     */
    public static updatePayrollEmployeeReimbursementApiV2PayrollsPayrollIdEmployeesEmployeePayrollIdReimbursementsIdPost(
        id: string,
        payrollId: string,
        employeePayrollId: string,
        requestBody: EmployeePayrollReimbursementUpdate,
    ): CancelablePromise<EmployeePayroll> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v2/payrolls/{payroll_id}/employees/{employee_payroll_id}/reimbursements/{id}',
            path: {
                'id': id,
                'payroll_id': payrollId,
                'employee_payroll_id': employeePayrollId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Employees Excluded From Payroll
     * @param payrollId
     * @returns EmployeeOrContractorPayrollOfficer Successful Response
     * @throws ApiError
     */
    public static getEmployeesExcludedFromPayrollApiV2PayrollsPayrollIdEmployeesExcludedGet(
        payrollId: string,
    ): CancelablePromise<Array<EmployeeOrContractorPayrollOfficer>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v2/payrolls/{payroll_id}/employees/excluded',
            path: {
                'payroll_id': payrollId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Payroll Contractors
     * @param payrollId
     * @returns ContractorPayrollMinimal Successful Response
     * @throws ApiError
     */
    public static getPayrollContractorsApiV2PayrollsPayrollIdContractorsGet(
        payrollId: string,
    ): CancelablePromise<Array<ContractorPayrollMinimal>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v2/payrolls/{payroll_id}/contractors',
            path: {
                'payroll_id': payrollId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Or Update Payroll Contractors
     * @param payrollId
     * @param requestBody
     * @returns ContractorPayrollMinimal Successful Response
     * @throws ApiError
     */
    public static createOrUpdatePayrollContractorsApiV2PayrollsPayrollIdContractorsPost(
        payrollId: string,
        requestBody: ContractorPayrollCreateOrUpdate,
    ): CancelablePromise<Array<ContractorPayrollMinimal>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v2/payrolls/{payroll_id}/contractors',
            path: {
                'payroll_id': payrollId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Payroll Contractor
     * @param payrollId
     * @param contractorPayrollId
     * @param requestBody
     * @returns ContractorPayrollMinimal Successful Response
     * @throws ApiError
     */
    public static updatePayrollContractorApiV2PayrollsPayrollIdContractorsContractorPayrollIdPut(
        payrollId: string,
        contractorPayrollId: string,
        requestBody: ContractorPayrollUpdate,
    ): CancelablePromise<ContractorPayrollMinimal> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v2/payrolls/{payroll_id}/contractors/{contractor_payroll_id}',
            path: {
                'payroll_id': payrollId,
                'contractor_payroll_id': contractorPayrollId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Soft Delete Payroll Contractor
     * @param payrollId
     * @param contractorPayrollId
     * @returns ContractorPayrollMinimal Successful Response
     * @throws ApiError
     */
    public static softDeletePayrollContractorApiV2PayrollsPayrollIdContractorsContractorPayrollIdDelete(
        payrollId: string,
        contractorPayrollId: string,
    ): CancelablePromise<Array<ContractorPayrollMinimal>> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v2/payrolls/{payroll_id}/contractors/{contractor_payroll_id}',
            path: {
                'payroll_id': payrollId,
                'contractor_payroll_id': contractorPayrollId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Payroll Contractor Earning
     * @param id
     * @param payrollId
     * @param contractorPayrollId
     * @param requestBody
     * @returns ContractorPayroll Successful Response
     * @throws ApiError
     */
    public static updatePayrollContractorEarningApiV2PayrollsPayrollIdContractorsContractorPayrollIdEarningsIdPost(
        id: string,
        payrollId: string,
        contractorPayrollId: string,
        requestBody: ContractorPayrollEarningUpdate,
    ): CancelablePromise<ContractorPayroll> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v2/payrolls/{payroll_id}/contractors/{contractor_payroll_id}/earnings/{id}',
            path: {
                'id': id,
                'payroll_id': payrollId,
                'contractor_payroll_id': contractorPayrollId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Payroll Contractor Reimbursement
     * @param id
     * @param payrollId
     * @param contractorPayrollId
     * @param requestBody
     * @returns ContractorPayroll Successful Response
     * @throws ApiError
     */
    public static updatePayrollContractorReimbursementApiV2PayrollsPayrollIdContractorsContractorPayrollIdReimbursementsIdPost(
        id: string,
        payrollId: string,
        contractorPayrollId: string,
        requestBody: ContractorPayrollReimbursementUpdate,
    ): CancelablePromise<ContractorPayroll> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v2/payrolls/{payroll_id}/contractors/{contractor_payroll_id}/reimbursements/{id}',
            path: {
                'id': id,
                'payroll_id': payrollId,
                'contractor_payroll_id': contractorPayrollId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Contractors Excluded From Payroll
     * @param payrollId
     * @returns EmployeeOrContractorPayrollOfficer Successful Response
     * @throws ApiError
     */
    public static getContractorsExcludedFromPayrollApiV2PayrollsPayrollIdContractorsExcludedGet(
        payrollId: string,
    ): CancelablePromise<Array<EmployeeOrContractorPayrollOfficer>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v2/payrolls/{payroll_id}/contractors/excluded',
            path: {
                'payroll_id': payrollId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Contractor Payroll By Id
     * @param contractorPayrollId
     * @returns ContractorPayroll Successful Response
     * @throws ApiError
     */
    public static getContractorPayrollByIdApiV2PayrollsContractorsContractorPayrollIdGet(
        contractorPayrollId: string,
    ): CancelablePromise<ContractorPayroll> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v2/payrolls/contractors/{contractor_payroll_id}',
            path: {
                'contractor_payroll_id': contractorPayrollId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Employee Payroll By Id
     * @param employeePayrollId
     * @returns EmployeePayroll Successful Response
     * @throws ApiError
     */
    public static getEmployeePayrollByIdApiV2PayrollsEmployeesEmployeePayrollIdGet(
        employeePayrollId: string,
    ): CancelablePromise<EmployeePayroll> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v2/payrolls/employees/{employee_payroll_id}',
            path: {
                'employee_payroll_id': employeePayrollId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Payroll Post Tax Deductions
     * @param payrollId
     * @returns EmployeePayrollPostTaxDeduction Successful Response
     * @throws ApiError
     */
    public static getPayrollPostTaxDeductionsApiV2PayrollsPayrollIdPosttaxdeductionsGet(
        payrollId: string,
    ): CancelablePromise<Array<EmployeePayrollPostTaxDeduction>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v2/payrolls/{payroll_id}/posttaxdeductions',
            path: {
                'payroll_id': payrollId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Payroll Benefits
     * @param payrollId
     * @returns EmployeePayrollBenefit Successful Response
     * @throws ApiError
     */
    public static getPayrollBenefitsApiV2PayrollsPayrollIdBenefitsGet(
        payrollId: string,
    ): CancelablePromise<Array<EmployeePayrollBenefit>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v2/payrolls/{payroll_id}/benefits',
            path: {
                'payroll_id': payrollId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Payroll Taxes
     * @param payrollId
     * @returns PayrollTax Successful Response
     * @throws ApiError
     */
    public static getPayrollTaxesApiV2PayrollsPayrollIdTaxesGet(
        payrollId: string,
    ): CancelablePromise<Array<PayrollTax>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v2/payrolls/{payroll_id}/taxes',
            path: {
                'payroll_id': payrollId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Employee Earning Rates
     * @param officerId
     * @returns CHQEarningRate Successful Response
     * @throws ApiError
     */
    public static getEmployeeEarningRatesApiV2PayrollEmployeeOfficerIdEarningratesGet(
        officerId: string,
    ): CancelablePromise<Array<CHQEarningRate>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v2/payroll/employee/{officer_id}/earningrates',
            path: {
                'officer_id': officerId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Earning Codes
     * @returns FirmEarningCodeFull Successful Response
     * @throws ApiError
     */
    public static getEarningCodesApiV2PayrollEarningcodesGet(): CancelablePromise<Array<FirmEarningCodeFull>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v2/payroll/earningcodes',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Contractor Earning Codes
     * @returns FirmEarningCode Successful Response
     * @throws ApiError
     */
    public static getContractorEarningCodesApiV2PayrollContractorearningcodesGet(): CancelablePromise<Array<FirmEarningCode>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v2/payroll/contractorearningcodes',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Workplaces
     * @returns PayrollWorkplace Successful Response
     * @throws ApiError
     */
    public static getWorkplacesApiV2PayrollWorkplacesGet(): CancelablePromise<Array<PayrollWorkplace>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v2/payroll/workplaces',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Unique Payrolls
     * @param requestBody
     * @returns string Successful Response
     * @throws ApiError
     */
    public static getUniquePayrollsApiV2PayrollShiftinstanceMappingsPost(
        requestBody: Record<string, (boolean | null)>,
    ): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v2/payroll/shiftinstance/mappings',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Cash Requirement Summary
     * @param payrollId
     * @returns PayrollCashRequirementSummary Successful Response
     * @throws ApiError
     */
    public static getCashRequirementSummaryApiV2PayrollsPayrollIdCashrequirementSummaryGet(
        payrollId: string,
    ): CancelablePromise<PayrollCashRequirementSummary> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v2/payrolls/{payroll_id}/cashrequirement/summary',
            path: {
                'payroll_id': payrollId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Contractor Payroll Shift Earning
     * @param payrollId
     * @param contractorPayrollId
     * @param shiftInstanceId
     * @returns PayrollShiftEarning Successful Response
     * @throws ApiError
     */
    public static getContractorPayrollShiftEarningApiV2PayrollsPayrollIdContractorsContractorPayrollIdShiftShiftInstanceIdGet(
        payrollId: string,
        contractorPayrollId: string,
        shiftInstanceId: string,
    ): CancelablePromise<PayrollShiftEarning> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v2/payrolls/{payroll_id}/contractors/{contractor_payroll_id}/shift/{shift_instance_id}',
            path: {
                'payroll_id': payrollId,
                'contractor_payroll_id': contractorPayrollId,
                'shift_instance_id': shiftInstanceId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Contractor Payroll Shift Earning
     * @param payrollId
     * @param contractorPayrollId
     * @param shiftInstanceId
     * @param requestBody
     * @returns ContractorPayroll Successful Response
     * @throws ApiError
     */
    public static updateContractorPayrollShiftEarningApiV2PayrollsPayrollIdContractorsContractorPayrollIdShiftShiftInstanceIdPost(
        payrollId: string,
        contractorPayrollId: string,
        shiftInstanceId: string,
        requestBody: PayrollShiftEarningUpdate,
    ): CancelablePromise<ContractorPayroll> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v2/payrolls/{payroll_id}/contractors/{contractor_payroll_id}/shift/{shift_instance_id}',
            path: {
                'payroll_id': payrollId,
                'contractor_payroll_id': contractorPayrollId,
                'shift_instance_id': shiftInstanceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Employee Payroll Shift Earning
     * @param payrollId
     * @param employeePayrollId
     * @param shiftInstanceId
     * @returns PayrollShiftEarning Successful Response
     * @throws ApiError
     */
    public static getEmployeePayrollShiftEarningApiV2PayrollsPayrollIdEmployeesEmployeePayrollIdShiftShiftInstanceIdGet(
        payrollId: string,
        employeePayrollId: string,
        shiftInstanceId: string,
    ): CancelablePromise<PayrollShiftEarning> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v2/payrolls/{payroll_id}/employees/{employee_payroll_id}/shift/{shift_instance_id}',
            path: {
                'payroll_id': payrollId,
                'employee_payroll_id': employeePayrollId,
                'shift_instance_id': shiftInstanceId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Employee Payroll Shift Earning
     * @param payrollId
     * @param employeePayrollId
     * @param shiftInstanceId
     * @param requestBody
     * @returns EmployeePayroll Successful Response
     * @throws ApiError
     */
    public static updateEmployeePayrollShiftEarningApiV2PayrollsPayrollIdEmployeesEmployeePayrollIdShiftShiftInstanceIdPost(
        payrollId: string,
        employeePayrollId: string,
        shiftInstanceId: string,
        requestBody: PayrollShiftEarningUpdate,
    ): CancelablePromise<EmployeePayroll> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v2/payrolls/{payroll_id}/employees/{employee_payroll_id}/shift/{shift_instance_id}',
            path: {
                'payroll_id': payrollId,
                'employee_payroll_id': employeePayrollId,
                'shift_instance_id': shiftInstanceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Or Update Payroll Contractors With Shift Based Earning
     * @param payrollId
     * @param requestBody
     * @returns ContractorPayrollMinimal Successful Response
     * @throws ApiError
     */
    public static createOrUpdatePayrollContractorsWithShiftBasedEarningApiV2PayrollsPayrollIdContractorsShiftEarningPost(
        payrollId: string,
        requestBody: Body_create_or_update_payroll_contractors_with_shift_based_earning_api_v2_payrolls__payroll_id__contractors_shift_earning_post,
    ): CancelablePromise<Array<ContractorPayrollMinimal>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v2/payrolls/{payroll_id}/contractors/shift/earning',
            path: {
                'payroll_id': payrollId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Or Update Payroll Employees With Shift Based Earning
     * @param payrollId
     * @param requestBody
     * @returns EmployeePayrollMinimal Successful Response
     * @throws ApiError
     */
    public static createOrUpdatePayrollEmployeesWithShiftBasedEarningApiV2PayrollsPayrollIdEmployeesShiftEarningPost(
        payrollId: string,
        requestBody: Body_create_or_update_payroll_employees_with_shift_based_earning_api_v2_payrolls__payroll_id__employees_shift_earning_post,
    ): CancelablePromise<Array<EmployeePayrollMinimal>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v2/payrolls/{payroll_id}/employees/shift/earning',
            path: {
                'payroll_id': payrollId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Generate Employee Paystub Pdf
     * Generate and return a PDF for the employee paystub.
     * @param payrollId
     * @param employeePayrollId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static generateEmployeePaystubPdfApiV2PayrollsPayrollIdEmployeesEmployeePayrollIdPaystubGet(
        payrollId: string,
        employeePayrollId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v2/payrolls/{payroll_id}/employees/{employee_payroll_id}/paystub',
            path: {
                'payroll_id': payrollId,
                'employee_payroll_id': employeePayrollId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Generate Contractor Paystub Pdf
     * Generate and return a PDF for the contractor paystub.
     * @param payrollId
     * @param contractorPayrollId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static generateContractorPaystubPdfApiV2PayrollsPayrollIdContractorsContractorPayrollIdPaystubGet(
        payrollId: string,
        contractorPayrollId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v2/payrolls/{payroll_id}/contractors/{contractor_payroll_id}/paystub',
            path: {
                'payroll_id': payrollId,
                'contractor_payroll_id': contractorPayrollId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
