/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Payroll = {
    processor_id?: string;
    created_at: string;
    updated_at: string;
    computed_at: string;
    saved_at: string;
    period_start: string;
    period_end: string;
    payday: string;
    approval_deadline?: string;
    approved_at?: string;
    status: Payroll.status;
    processor_status?: Payroll.processor_status;
    type: Payroll.type;
    pay_frequency?: Payroll.pay_frequency;
    funding_payment_method?: Payroll.funding_payment_method;
    processing_period?: Payroll.processing_period;
    off_cycle_options?: (string | number | boolean);
    managed?: boolean;
    payroll_metadata?: any;
    is_void?: boolean;
    pay_group_id?: string;
    total_employee_gross_cents?: number;
    total_employee_reimbursements_cents?: number;
    total_employee_taxes_cents?: number;
    total_employee_benefits_cents?: number;
    total_post_tax_deductions_cents?: number;
    total_employee_net_cents?: number;
    total_contractor_gross_cents?: number;
    total_contractor_reimbursements_cents?: number;
    total_contractor_net_cents?: number;
    total_company_taxes_cents?: number;
    total_company_benefits_cents?: number;
    total_liability_cents?: number;
    total_cash_requirement_cents?: number;
    id: string;
    firm_id: string;
};

export namespace Payroll {

    export enum status {
        INITIATED = 'initiated',
        DRAFT = 'draft',
        SUBMITTED = 'submitted',
        PROCESSING = 'processing',
        FAILED = 'failed',
        PARTIALLY_PAID = 'partially_paid',
        PAID = 'paid',
    }

    export enum processor_status {
        DRAFT = 'draft',
        PENDING = 'pending',
        PROCESSING = 'processing',
        FAILED = 'failed',
        PARTIALLY_PAID = 'partially_paid',
        PAID = 'paid',
    }

    export enum type {
        REGULAR = 'regular',
        OFF_CYCLE = 'off_cycle',
        AMENDMENT = 'amendment',
        THIRD_PARTY_SICK_PAY = 'third_party_sick_pay',
    }

    export enum pay_frequency {
        WEEKLY = 'weekly',
        BIWEEKLY = 'biweekly',
        SEMIMONTHLY = 'semimonthly',
        MONTHLY = 'monthly',
        QUARTERLY = 'quarterly',
        ANNUALLY = 'annually',
    }

    export enum funding_payment_method {
        ACH = 'ach',
        WIRE = 'wire',
    }

    export enum processing_period {
        FOUR_DAY = 'four_day',
        THREE_DAY = 'three_day',
        TWO_DAY = 'two_day',
        ONE_DAY = 'one_day',
    }


}

