import React from 'react'
import { Box, Button, Skeleton } from '@mui/material'
import NextLink from 'next/link'
import SettingsIcon from '@mui/icons-material/Settings'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import { DetailTabs } from '../DetailTabs'
import { useLoadPayGroups } from '../../services/pay_group'
import { useUserIsFinancePrivileged } from 'src/utils/auth'
import { useRouter } from 'next/router'
import { UpcomingPayrollScheduleModal } from '../Timekeeping/UpcomingPayrollScheduleModal'
type TimekeepingLayoutProps = {
    children: React.ReactNode
}

export const TabbedTimekeepingLayout = ({
    children,
}: TimekeepingLayoutProps) => {
    const router = useRouter()
    const shouldShowUpcomingPayscheduleModal = !!router.query.show_payschedule

    const { payGroups, isLoading } = useLoadPayGroups()
    const isUserFinancePriv = useUserIsFinancePrivileged()

    const payGroupTabs = payGroups.map((payGroup) => {
        return {
            name: `${payGroup.name} Pay Group`,
            link: `/timekeeping/period/${payGroup.id}`,
            linkRoot: `/timekeeping/period/${payGroup.id}`,
        }
    })

    const defaultPayGroupLabel =
        payGroups.length === 0 ? 'Pay Periods' : 'Default Pay Group'

    const tabData = [
        {
            name: 'Daily',
            link: '/timekeeping/day',
            linkRoot: '/timekeeping/day',
        },
    ]

    if (isUserFinancePriv) {
        tabData.push(
            {
                name: defaultPayGroupLabel,
                link: '/timekeeping/period/default',
                linkRoot: '/timekeeping/period/default',
            },
            ...payGroupTabs
        )
    }

    if (isLoading) {
        return <Skeleton />
    }

    return (
        <Box display="flex" flexDirection="column" height="75%">
            {/* calc to deal with sidebar width and prevent horizontal scroll */}
            <Box maxWidth="calc(100vw - 110px)">
                <DetailTabs
                    data={tabData}
                    maxVisibleTabs={3}
                    extraTabEnd={
                        <>
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    if (!router.query.id) {
                                        // if we're on the "day" view, default to the default paygroup
                                        router.push(
                                            '/timekeeping/period/default?show_payschedule=true'
                                        )
                                    } else {
                                        router.push({
                                            query: {
                                                ...router.query,
                                                show_payschedule: true,
                                            },
                                        })
                                    }
                                }}
                                startIcon={<CalendarMonthIcon />}
                                sx={{ marginLeft: 1 }}
                            >
                                Pay Schedule
                            </Button>
                            <NextLink
                                href="/settings/timekeeping"
                                passHref
                                legacyBehavior
                            >
                                <Button
                                    variant="outlined"
                                    startIcon={<SettingsIcon />}
                                    sx={{ marginLeft: 1 }}
                                >
                                    Settings
                                </Button>
                            </NextLink>
                        </>
                    }
                />
            </Box>
            <main>{children}</main>
            {shouldShowUpcomingPayscheduleModal && (
                <UpcomingPayrollScheduleModal
                    handleClose={() => {
                        const { show_payschedule, ...query } = router.query
                        router.push({ query })
                    }}
                />
            )}
        </Box>
    )
}
