/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BelfryAPIEmployeeActionCreate } from '../models/BelfryAPIEmployeeActionCreate';
import type { EmployeeActionEEO1 } from '../models/EmployeeActionEEO1';
import type { EmployeeActionEEO1Update } from '../models/EmployeeActionEEO1Update';
import type { EmployeeActionForDocumentSign } from '../models/EmployeeActionForDocumentSign';
import type { EmployeeActionForEmployeePortal } from '../models/EmployeeActionForEmployeePortal';
import type { EmployeeActionUpdate } from '../models/EmployeeActionUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class EmployeeActionService {

    /**
     * Get Action
     * Get employee action with ID
     * @param actionId
     * @returns EmployeeActionForEmployeePortal Successful Response
     * @throws ApiError
     */
    public static getActionApiV1EmployeeActionActionIdGet(
        actionId: string,
    ): CancelablePromise<EmployeeActionForEmployeePortal> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/employee_action/{action_id}',
            path: {
                'action_id': actionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Action
     * Update employee action
     * @param actionId
     * @param requestBody
     * @returns EmployeeActionForEmployeePortal Successful Response
     * @throws ApiError
     */
    public static updateActionApiV1EmployeeActionActionIdPut(
        actionId: string,
        requestBody: EmployeeActionUpdate,
    ): CancelablePromise<EmployeeActionForEmployeePortal> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/employee_action/{action_id}',
            path: {
                'action_id': actionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Eeo1 Action
     * Get employee action with ID
     * @param actionId
     * @returns EmployeeActionEEO1 Successful Response
     * @throws ApiError
     */
    public static getEeo1ActionApiV1EmployeeActionActionIdEeo1Get(
        actionId: string,
    ): CancelablePromise<EmployeeActionEEO1> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/employee_action/{action_id}/eeo1',
            path: {
                'action_id': actionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Eeo1 Action
     * Update employee action for EEO1
     * @param actionId
     * @param requestBody
     * @returns EmployeeActionEEO1 Successful Response
     * @throws ApiError
     */
    public static updateEeo1ActionApiV1EmployeeActionActionIdEeo1Put(
        actionId: string,
        requestBody: EmployeeActionEEO1Update,
    ): CancelablePromise<EmployeeActionEEO1> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/employee_action/{action_id}/eeo1',
            path: {
                'action_id': actionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Employee Action
     * Create employee actions
     * @param id
     * @param requestBody
     * @returns string Successful Response
     * @throws ApiError
     */
    public static createEmployeeActionApiV1EmployeeActionWorkerIdTaskPost(
        id: string,
        requestBody: BelfryAPIEmployeeActionCreate,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/employee_action/worker/{id}/task',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Document Sign Action
     * Get employee action for employee to document sign
     * @param actionId
     * @returns EmployeeActionForDocumentSign Successful Response
     * @throws ApiError
     */
    public static getDocumentSignActionApiV1EmployeeActionActionIdDocumentEsignGet(
        actionId: string,
    ): CancelablePromise<EmployeeActionForDocumentSign> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/employee_action/{action_id}/document_esign',
            path: {
                'action_id': actionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Document Sign Action For Employer
     * Get employee action for employer to document sign
     * @param actionId
     * @returns EmployeeActionForDocumentSign Successful Response
     * @throws ApiError
     */
    public static getDocumentSignActionForEmployerApiV1EmployeeActionActionIdDocumentEsignEmployerGet(
        actionId: string,
    ): CancelablePromise<EmployeeActionForDocumentSign> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/employee_action/{action_id}/document_esign/employer',
            path: {
                'action_id': actionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
