export function groupBy(objectArray: any[], property: string) {
    return objectArray.reduce((acc, obj) => {
        const key = obj[property]
        acc[key] ??= []
        acc[key].push(obj)
        return acc
    }, {})
}
export function groupByIdFunc<T = any>(
    objectArray: T[],
    idFunc: (arg0: T) => string | undefined
): Record<string, T[]> {
    return objectArray.reduce((acc, obj) => {
        const key = idFunc(obj)
        if (!key) {
            return acc
        }
        acc[key] ??= []
        acc[key].push(obj)
        return acc
    }, {} as Record<string, T[]>)
}

export function sumArrayOfObjectsUntyped(array: any[]) {
    const reducer = (acc: any, curr: any) => {
        if (!curr) {
            return acc
        }
        for (const k of Object.keys(curr)) {
            acc[k] = acc[k] ? acc[k] + curr[k] : curr[k]
        }
        return acc
    }
    return array.reduce(reducer, {})
}

export function atIndex(array: any[] | undefined, index: number) {
    if (array == undefined) {
        return undefined
    }
    const positiveIndex = index >= 0 ? index : array.length + index
    return positiveIndex >= 0 && positiveIndex < array.length
        ? array[positiveIndex]
        : undefined
}

export function arrayEquals<T>(
    array1: T[],
    array2: T[],
    comparator: (arg0: T, arg1: T) => boolean = (a, b) => a === b
): boolean {
    const matchedIndexes = new Set<number>()
    if (array1.length !== array2.length) {
        return false
    }

    return array1.every((entry1) => {
        const match = array2.findIndex(
            (entry2, index) =>
                !matchedIndexes.has(index) && comparator(entry1, entry2)
        )
        if (match === -1) {
            return false
        }

        matchedIndexes.add(match)
        return true
    })
}

export function nonNullable<T>(value: T): value is NonNullable<T> {
    return Boolean(value)
}

export function coerceToArray<T>(value: T[] | null | undefined): T[] {
    if (Array.isArray(value)) {
        return value
    }

    return []
}

type ValueOf<T> = T[keyof T]
type Entries<T> = [keyof T, ValueOf<T>][]
export function objectEntries<T extends object>(obj: T): Entries<T> {
    return Object.entries(obj) as Entries<T>
}

export function chunkArray<T>(array: T[], size: number) {
    return array.reduce((acc, _, i) => {
        if (i % size === 0) {
            acc.push(array.slice(i, i + size))
        }
        return acc
    }, [] as T[][])
}

export function numericalFill(
    length: number,
    start: number,
    skip: number = 1
): number[] {
    if (length <= 0) {
        return []
    }
    return Array.from({ length }, (_, i) => start + i * skip)
}
