/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AnvilSigner } from '../models/AnvilSigner';
import type { AnvilSignerUpdate } from '../models/AnvilSignerUpdate';
import type { AnvilWebhookEvent } from '../models/AnvilWebhookEvent';
import type { EmployeeTasksPayload } from '../models/EmployeeTasksPayload';
import type { EmployerOnboardingTasks } from '../models/EmployerOnboardingTasks';
import type { OnboardingAction } from '../models/OnboardingAction';
import type { OnboardingActionEEO1 } from '../models/OnboardingActionEEO1';
import type { OnboardingActionEEO1Update } from '../models/OnboardingActionEEO1Update';
import type { OnboardingActionForDocumentSign } from '../models/OnboardingActionForDocumentSign';
import type { OnboardingActionUpdate } from '../models/OnboardingActionUpdate';
import type { OnboardingAvailabilityAction } from '../models/OnboardingAvailabilityAction';
import type { OnboardingAvailabilityActionUpdate } from '../models/OnboardingAvailabilityActionUpdate';
import type { OnboardingDocument } from '../models/OnboardingDocument';
import type { OnboardingDocumentCreate } from '../models/OnboardingDocumentCreate';
import type { OnboardingDocumentUpdate } from '../models/OnboardingDocumentUpdate';
import type { OnboardingDocumentWithDocumentPDFBuilderURL } from '../models/OnboardingDocumentWithDocumentPDFBuilderURL';
import type { OnboardingI9ActionWithAnvilData } from '../models/OnboardingI9ActionWithAnvilData';
import type { OnboardingProfileAction } from '../models/OnboardingProfileAction';
import type { OnboardingProfileActionUpdate } from '../models/OnboardingProfileActionUpdate';
import type { OnboardingStatus } from '../models/OnboardingStatus';
import type { OnboardingStatusTaskListUpdate } from '../models/OnboardingStatusTaskListUpdate';
import type { OnboardingTemplate } from '../models/OnboardingTemplate';
import type { OnboardingTemplateCreate } from '../models/OnboardingTemplateCreate';
import type { OnboardingTemplateUpdate } from '../models/OnboardingTemplateUpdate';
import type { OnboardingWOTCAction } from '../models/OnboardingWOTCAction';
import type { WOTCWaltonWebhookEvent } from '../models/WOTCWaltonWebhookEvent';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OnboardingService {

    /**
     * Anvil Webhook
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static anvilWebhookApiV1OnboardingAnvilPost(
        requestBody: AnvilWebhookEvent,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/onboarding/anvil',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get I9 Action
     * Get I9 Onboarding Action
     * @param actionId
     * @returns OnboardingI9ActionWithAnvilData Successful Response
     * @throws ApiError
     */
    public static getI9ActionApiV1OnboardingI9ActionIdGet(
        actionId: string,
    ): CancelablePromise<OnboardingI9ActionWithAnvilData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/onboarding/i9/{action_id}',
            path: {
                'action_id': actionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get I9 File
     * @param actionId
     * @param fileId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getI9FileApiV1OnboardingI9ActionIdFileFileIdGet(
        actionId: string,
        fileId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/onboarding/i9/{action_id}/file/{file_id}',
            path: {
                'action_id': actionId,
                'file_id': fileId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Availability Action
     * Get I9 Availability Action
     * @param actionId
     * @returns OnboardingAvailabilityAction Successful Response
     * @throws ApiError
     */
    public static getAvailabilityActionApiV1OnboardingAvailabilityActionIdGet(
        actionId: string,
    ): CancelablePromise<OnboardingAvailabilityAction> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/onboarding/availability/{action_id}',
            path: {
                'action_id': actionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Availability Action
     * @param actionId
     * @param requestBody
     * @returns OnboardingAvailabilityAction Successful Response
     * @throws ApiError
     */
    public static updateAvailabilityActionApiV1OnboardingAvailabilityActionIdPut(
        actionId: string,
        requestBody: OnboardingAvailabilityActionUpdate,
    ): CancelablePromise<OnboardingAvailabilityAction> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/onboarding/availability/{action_id}',
            path: {
                'action_id': actionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Template
     * Get onboarding template with ID template_id
     * @param templateId
     * @returns OnboardingTemplate Successful Response
     * @throws ApiError
     */
    public static getTemplateApiV1OnboardingTemplateTemplateIdGet(
        templateId: string,
    ): CancelablePromise<OnboardingTemplate> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/onboarding/template/{template_id}',
            path: {
                'template_id': templateId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Template
     * Update onboarding template
     * @param templateId
     * @param requestBody
     * @returns OnboardingTemplate Successful Response
     * @throws ApiError
     */
    public static updateTemplateApiV1OnboardingTemplateTemplateIdPut(
        templateId: string,
        requestBody: OnboardingTemplateUpdate,
    ): CancelablePromise<OnboardingTemplate> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/onboarding/template/{template_id}',
            path: {
                'template_id': templateId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Template
     * Delete onboarding template
     * @param templateId
     * @returns OnboardingTemplate Successful Response
     * @throws ApiError
     */
    public static deleteTemplateApiV1OnboardingTemplateTemplateIdDelete(
        templateId: string,
    ): CancelablePromise<OnboardingTemplate> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/onboarding/template/{template_id}',
            path: {
                'template_id': templateId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Templates
     * Get onboarding templates for firm with id firm_id
     * @returns OnboardingTemplate Successful Response
     * @throws ApiError
     */
    public static getTemplatesApiV1OnboardingTemplatesGet(): CancelablePromise<Array<OnboardingTemplate>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/onboarding/templates',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Template
     * Create onboarding template
     * @param requestBody
     * @returns OnboardingTemplate Successful Response
     * @throws ApiError
     */
    public static createTemplateApiV1OnboardingTemplatePost(
        requestBody: OnboardingTemplateCreate,
    ): CancelablePromise<OnboardingTemplate> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/onboarding/template',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Upload Document
     * Upload onboarding document
     * @param requestBody
     * @returns OnboardingDocument Successful Response
     * @throws ApiError
     */
    public static uploadDocumentApiV1OnboardingDocumentPost(
        requestBody: OnboardingDocumentCreate,
    ): CancelablePromise<OnboardingDocument> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/onboarding/document',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Document
     * Update onboarding document
     * @param documentId
     * @param requestBody
     * @returns OnboardingDocument Successful Response
     * @throws ApiError
     */
    public static updateDocumentApiV1OnboardingDocumentDocumentIdPut(
        documentId: string,
        requestBody: OnboardingDocumentUpdate,
    ): CancelablePromise<OnboardingDocument> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/onboarding/document/{document_id}',
            path: {
                'document_id': documentId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Document
     * Delete onboarding document
     * @param documentId
     * @returns OnboardingDocument Successful Response
     * @throws ApiError
     */
    public static deleteDocumentApiV1OnboardingDocumentDocumentIdDelete(
        documentId: string,
    ): CancelablePromise<OnboardingDocument> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/onboarding/document/{document_id}',
            path: {
                'document_id': documentId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Document
     * Get onboarding document
     * @param documentId
     * @returns OnboardingDocument Successful Response
     * @throws ApiError
     */
    public static getDocumentApiV1OnboardingDocumentDocumentIdGet(
        documentId: string,
    ): CancelablePromise<OnboardingDocument> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/onboarding/document/{document_id}',
            path: {
                'document_id': documentId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Remove Document From Template
     * Remove onboarding document from template
     * @param templateId
     * @param documentId
     * @returns OnboardingDocument Successful Response
     * @throws ApiError
     */
    public static removeDocumentFromTemplateApiV1OnboardingTemplateTemplateIdDocumentDocumentIdDelete(
        templateId: string,
        documentId: string,
    ): CancelablePromise<OnboardingDocument> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/onboarding/template/{template_id}/document/{document_id}',
            path: {
                'template_id': templateId,
                'document_id': documentId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Documents
     * Get onboarding documents
     * @returns OnboardingDocument Successful Response
     * @throws ApiError
     */
    public static getDocumentsApiV1OnboardingDocumentsGet(): CancelablePromise<Array<OnboardingDocument>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/onboarding/documents',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Status List
     * Get all onboarding status for firm
     * @returns OnboardingStatus Successful Response
     * @throws ApiError
     */
    public static getStatusListApiV1OnboardingStatusGet(): CancelablePromise<Array<OnboardingStatus>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/onboarding/status',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Status
     * Get onboarding status with ID status_id
     * @param statusId
     * @returns OnboardingStatus Successful Response
     * @throws ApiError
     */
    public static getStatusApiV1OnboardingStatusStatusIdGet(
        statusId: string,
    ): CancelablePromise<OnboardingStatus> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/onboarding/status/{status_id}',
            path: {
                'status_id': statusId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Status Task List
     * Add/remove tasks to an existing onboarding flow
     * @param statusId
     * @param requestBody
     * @returns OnboardingStatus Successful Response
     * @throws ApiError
     */
    public static updateStatusTaskListApiV1OnboardingStatusStatusIdTasksPatch(
        statusId: string,
        requestBody: OnboardingStatusTaskListUpdate,
    ): CancelablePromise<OnboardingStatus> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/onboarding/status/{status_id}/tasks',
            path: {
                'status_id': statusId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Action
     * Get onboarding status with ID status_id
     * @param actionId
     * @returns OnboardingAction Successful Response
     * @throws ApiError
     */
    public static getActionApiV1OnboardingActionActionIdGet(
        actionId: string,
    ): CancelablePromise<OnboardingAction> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/onboarding/action/{action_id}',
            path: {
                'action_id': actionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Action
     * Update onboarding action
     * @param actionId
     * @param requestBody
     * @returns OnboardingAction Successful Response
     * @throws ApiError
     */
    public static updateActionApiV1OnboardingActionActionIdPut(
        actionId: string,
        requestBody: OnboardingActionUpdate,
    ): CancelablePromise<OnboardingAction> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/onboarding/action/{action_id}',
            path: {
                'action_id': actionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Complete Profile Action
     * Get onboarding status with ID status_id
     * @returns OnboardingProfileAction Successful Response
     * @throws ApiError
     */
    public static getCompleteProfileActionApiV1OnboardingProfileGet(): CancelablePromise<OnboardingProfileAction> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/onboarding/profile',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Complete Profile Action
     * @param requestBody
     * @returns OnboardingProfileAction Successful Response
     * @throws ApiError
     */
    public static updateCompleteProfileActionApiV1OnboardingProfilePut(
        requestBody: OnboardingProfileActionUpdate,
    ): CancelablePromise<OnboardingProfileAction> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/onboarding/profile',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Wotc Action
     * @returns OnboardingWOTCAction Successful Response
     * @throws ApiError
     */
    public static getWotcActionApiV1OnboardingWotcGet(): CancelablePromise<OnboardingWOTCAction> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/onboarding/wotc',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Wotc Webhook
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static wotcWebhookApiV1OnboardingWotcWebhookPost(
        requestBody: WOTCWaltonWebhookEvent,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/onboarding/wotc_webhook',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Employer Actions
     * Get Onboarding Actions and Submission Reviews requiring employer action
     * @param excludeCompleted
     * @returns EmployerOnboardingTasks Successful Response
     * @throws ApiError
     */
    public static getEmployerActionsApiV1OnboardingActionsEmployerGet(
        excludeCompleted: boolean = false,
    ): CancelablePromise<EmployerOnboardingTasks> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/onboarding/actions/employer',
            query: {
                'exclude_completed': excludeCompleted,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Approve Employee Onboarding
     * Allow admin to approve a submitted employee onboarding
     * @param statusId
     * @returns string Successful Response
     * @throws ApiError
     */
    public static approveEmployeeOnboardingApiV1OnboardingStatusStatusIdApprovePost(
        statusId: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/onboarding/status/{status_id}/approve',
            path: {
                'status_id': statusId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Send Onboarding Invite
     * Resend onboarding
     * @param employeeId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static sendOnboardingInviteApiV1OnboardingSendEmployeeInvitePost(
        employeeId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/onboarding/send-employee-invite',
            query: {
                'employee_id': employeeId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Send Onboarding Reminder
     * Resend onboarding
     * @param employeeId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static sendOnboardingReminderApiV1OnboardingSendEmployeeReminderPost(
        employeeId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/onboarding/send-employee-reminder',
            query: {
                'employee_id': employeeId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Eeo1 Action
     * Get onboarding EEO1 action with ID
     * @param actionId
     * @returns OnboardingActionEEO1 Successful Response
     * @throws ApiError
     */
    public static getEeo1ActionApiV1OnoardingEeo1ActionIdGet(
        actionId: string,
    ): CancelablePromise<OnboardingActionEEO1> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/onoarding/eeo1/{action_id}',
            path: {
                'action_id': actionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Eeo1 Action
     * Update employee action for EEO1
     * @param actionId
     * @param requestBody
     * @returns OnboardingActionEEO1 Successful Response
     * @throws ApiError
     */
    public static updateEeo1ActionApiV1OnboardingEeo1ActionIdPut(
        actionId: string,
        requestBody: OnboardingActionEEO1Update,
    ): CancelablePromise<OnboardingActionEEO1> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/onboarding/eeo1/{action_id}',
            path: {
                'action_id': actionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Reset I9
     * Reset I9 fields in OnboardingAction
     * @param actionId
     * @returns string Successful Response
     * @throws ApiError
     */
    public static resetI9ApiV1OnboardingI9ActionIdResetPost(
        actionId: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/onboarding/i9/{action_id}/reset',
            path: {
                'action_id': actionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit I9 Signer
     * @param actionId
     * @param requestBody
     * @returns AnvilSigner Successful Response
     * @throws ApiError
     */
    public static editI9SignerApiV1OnboardingI9ActionIdSignerSignerEidPost(
        actionId: string,
        requestBody: AnvilSignerUpdate,
    ): CancelablePromise<AnvilSigner> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/onboarding/i9/{action_id}/signer/{signer_eid}',
            path: {
                'action_id': actionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Notify I9 Signer
     * @param actionId
     * @param signerEid
     * @returns AnvilSigner Successful Response
     * @throws ApiError
     */
    public static notifyI9SignerApiV1OnboardingI9ActionIdSignerSignerEidNotifyPost(
        actionId: string,
        signerEid: string,
    ): CancelablePromise<AnvilSigner> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/onboarding/i9/{action_id}/signer/{signer_eid}/notify',
            path: {
                'action_id': actionId,
                'signer_eid': signerEid,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Document For Esign Template
     * @param documentId
     * @returns OnboardingDocumentWithDocumentPDFBuilderURL Successful Response
     * @throws ApiError
     */
    public static getDocumentForEsignTemplateApiV1OnboardingDocumentDocumentIdEsignTemplateGet(
        documentId: string,
    ): CancelablePromise<OnboardingDocumentWithDocumentPDFBuilderURL> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/onboarding/document/{document_id}/esign_template',
            path: {
                'document_id': documentId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Document Esign Template
     * @param documentId
     * @returns string Successful Response
     * @throws ApiError
     */
    public static createDocumentEsignTemplateApiV1OnboardingDocumentDocumentIdEsignTemplatePost(
        documentId: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/onboarding/document/{document_id}/esign_template',
            path: {
                'document_id': documentId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Document Sign Action
     * Get onboarding action for employee to document sign
     * @param actionId
     * @returns OnboardingActionForDocumentSign Successful Response
     * @throws ApiError
     */
    public static getDocumentSignActionApiV1OnboardingDocumentEsignActionIdGet(
        actionId: string,
    ): CancelablePromise<OnboardingActionForDocumentSign> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/onboarding/document_esign/{action_id}',
            path: {
                'action_id': actionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Document Sign Action For Employer
     * Get onboarding action for employer to document sign
     * @param actionId
     * @returns OnboardingActionForDocumentSign Successful Response
     * @throws ApiError
     */
    public static getDocumentSignActionForEmployerApiV1OnboardingDocumentEsignActionIdEmployerGet(
        actionId: string,
    ): CancelablePromise<OnboardingActionForDocumentSign> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/onboarding/document_esign/{action_id}/employer',
            path: {
                'action_id': actionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Esign Document Download
     * @param signInstanceId
     * @returns string Successful Response
     * @throws ApiError
     */
    public static getEsignDocumentDownloadApiV1OnboardingDocumentEsignSignInstanceSignInstanceIdDownloadGet(
        signInstanceId: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/onboarding/document_esign/sign_instance/{sign_instance_id}/download',
            path: {
                'sign_instance_id': signInstanceId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Onboarding And Employee Actions For Task View
     * Return all OnboardingAction and EmployeeActions for worker
     * @param officerId
     * @returns EmployeeTasksPayload Successful Response
     * @throws ApiError
     */
    public static getOnboardingAndEmployeeActionsForTaskViewApiV1OnboardingWorkerOfficerIdActionsGet(
        officerId: string,
    ): CancelablePromise<EmployeeTasksPayload> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/onboarding/worker/{officer_id}/actions',
            path: {
                'officer_id': officerId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
